import React, { useEffect, useState } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Grid, Tabs, Tab } from '@mui/material';
import { Typography } from '@mui/material';
import { IAddress } from '../Types/user';
import { IProfile } from '@magistrmartin/eshop-frontend-shared';
import { Order } from '../Types/order';
import { useLayout } from '../Layout/LayoutContext';
import { ordersService, profilesService } from '../Utils/ApiService';
import { reducers } from '../Utils/Reducers';
import { round, separateThousands } from '../Utils/Utils';
import { domains } from '../Utils/Mutations';

interface IRowProps {
  isRegistered: boolean;
  address?: IAddress;
  deliveryAddress?: IAddress;
  companyId?: string;
  email?: string;
  vat?: string;
  age?: number;
  mass?: number;
  birthday?: Date;
  nameday?: Date;
  orders: Order[];
}

function UsersTableRow({
  address,
  deliveryAddress,
  companyId,
  email,
  vat,
  age,
  mass,
  birthday,
  nameday,
  orders,
  isRegistered,
}: IRowProps) {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <>
      <TableRow style={{ cursor: 'pointer' }} onClick={() => setIsOpened(!isOpened)}>
        <TableCell>{email}</TableCell>
        <TableCell>{orders.length}</TableCell>
        <TableCell>{separateThousands(orders.map((o) => o.totalPrice || 0).reduce(reducers.sum, 0))} Kč</TableCell>
        <TableCell>
          {orders.length > 0
            ? separateThousands(round(orders.map((o) => o.totalPrice || 0).reduce(reducers.sum, 0) / orders.length, 2))
            : 0}{' '}
          Kč
        </TableCell>
      </TableRow>
      {isOpened && (
        <TableRow>
          <TableCell colSpan={5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Typography variant="h6">Fakturační údaje</Typography>
                <br />
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <b>Jméno:</b>
                      </td>
                      <td>{address?.name}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Příjmení:</b>
                      </td>
                      <td>{address?.surname}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Ulice:</b>
                      </td>
                      <td>{address?.street}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Číslo popisné:</b>&nbsp;&nbsp;&nbsp;&nbsp;
                      </td>
                      <td>{address?.houseNum}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Město:</b>
                      </td>
                      <td>{address?.city}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>PSČ:</b>
                      </td>
                      <td>{address?.zip}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Firma:</b>
                      </td>
                      <td>{address?.company}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>IČO:</b>
                      </td>
                      <td>{companyId}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>DIČ:</b>
                      </td>
                      <td>{vat}</td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h6">Dodací údaje</Typography>
                <br />
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <b>Telefon:</b>
                      </td>
                      <td>{address?.phone}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Jméno:</b>
                      </td>
                      <td>{deliveryAddress?.name}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Příjmení:</b>
                      </td>
                      <td>{deliveryAddress?.surname}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Ulice:</b>
                      </td>
                      <td>{deliveryAddress?.street}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Číslo popisné:</b>
                      </td>
                      <td>{deliveryAddress?.houseNum}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Město:</b>
                      </td>
                      <td>{deliveryAddress?.city}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>PSČ:</b>
                      </td>
                      <td>{deliveryAddress?.zip}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Firma:</b>
                      </td>
                      <td>{deliveryAddress?.company}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Telefon pro kurýra:</b>&nbsp;&nbsp;&nbsp;&nbsp;
                      </td>
                      <td>{deliveryAddress?.phone}</td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              {isRegistered && (
                <Grid item xs={12} md={4}>
                  <Typography variant="h6">Data</Typography>
                  <br />
                  <table>
                    <tbody>
                      <tr>
                        <td>Svátek</td>
                        <td>{nameday}</td>
                      </tr>
                      <tr>
                        <td>Narozeniny</td>
                        <td>{birthday}</td>
                      </tr>
                      <tr>
                        <td>Hmotnost</td>
                        <td>{mass}</td>
                      </tr>
                      <tr>
                        <td>Věk</td>
                        <td>{age}</td>
                      </tr>
                    </tbody>
                  </table>
                </Grid>
              )}
            </Grid>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

export default function Users() {
  const [users, setUsers] = useState<IProfile[]>([]);
  const [orders, setOrders] = useState<Order[]>([]);
  const [domainTab, setDomainTab] = useState(0);

  const layout = useLayout();

  useEffect(
    () => {
      profilesService.get(
        '/getAll',
        {},
        {
          success: setUsers,
          error: () => layout.error('Při načítání registrovaných uživatelů došlo k chybě.'),
        }
      );
      ordersService.get(
        '/all',
        {},
        {
          success: setOrders,
          error: () => layout.error('Při načítání neregistrovaných uživatelů došlo k chybě.'),
        }
      );
    },
    //eslint-disable-next-line
    []
  );

  return (
    <div>
      <Tabs color="primary" value={domainTab} onChange={(_, val) => setDomainTab(val)}>
        {domains.map((d) => (
          <Tab key={d} label={d} />
        ))}
      </Tabs>
      <br />
      <Typography variant="h4">Seznam uživatelů</Typography>
      <br />
      <Typography variant="h6">Registrovaní uživatelé</Typography>
      <Table>
        <TableHead>
          <TableCell>Email</TableCell>
          <TableCell>Počet objednávek</TableCell>
          <TableCell>Utraceno Celkem</TableCell>
          <TableCell>Ø Utraceno za objednávku</TableCell>
        </TableHead>
        <TableBody>
          {users
            .filter((u) => u.registrationDomain === domains[domainTab])
            .map((usr) => (
              <UsersTableRow
                isRegistered={true}
                address={usr.address}
                age={usr.age}
                birthday={usr.birthday}
                companyId={usr.companyId}
                deliveryAddress={usr.deliveryAddress}
                email={usr.email}
                key={usr.userId}
                mass={usr.mass}
                nameday={usr.nameday}
                vat={usr.vat}
                orders={orders.filter((o) => o.userId === usr.userId)}
              />
            ))}
        </TableBody>
      </Table>
      <br />
      <br />
      <Typography variant="h6">Neregistrovaní uživatelé</Typography>
      <Table>
        <TableHead>
          <TableCell>Email</TableCell>
          <TableCell>Počet objednávek</TableCell>
          <TableCell>Utraceno Celkem</TableCell>
          <TableCell>Ø Utraceno za objednávku</TableCell>
        </TableHead>
        <TableBody>
          {orders
            .filter(
              (o, index, self) =>
                (o.userId === undefined || o.userId === null || o.userId.length === 0) &&
                o.domain === domains[domainTab] &&
                index === self.map((ord) => ord.deliveryInfo.email).indexOf(o.deliveryInfo.email)
            )
            .map((o) => (
              <UsersTableRow
                isRegistered={false}
                address={{
                  city: o.deliveryInfo.city,
                  company: o.deliveryInfo.company,
                  houseNum: o.deliveryInfo.houseNum,
                  name: o.deliveryInfo.name,
                  phone: o.deliveryInfo.phone,
                  street: o.deliveryInfo.street,
                  surname: o.deliveryInfo.surname,
                  zip: o.deliveryInfo.zip,
                }}
                companyId={o.deliveryInfo.companyId}
                deliveryAddress={{
                  city: o.deliveryInfo.delCity,
                  company: o.deliveryInfo.delComplany,
                  houseNum: o.deliveryInfo.delHouseNum,
                  name: o.deliveryInfo.delName,
                  phone: o.deliveryInfo.delPhone,
                  street: o.deliveryInfo.delStreet,
                  surname: o.deliveryInfo.delSurname,
                  zip: o.deliveryInfo.delZip,
                }}
                email={o.deliveryInfo.email}
                key={o.id}
                vat={o.deliveryInfo.vat}
                orders={orders.filter(
                  (ord) =>
                    (ord.userId === undefined || ord.userId === null || ord.userId.length === 0) &&
                    ord.deliveryInfo.email === o.deliveryInfo.email
                )}
              />
            ))}
        </TableBody>
      </Table>
    </div>
  );
}
