import { jsPDF } from 'jspdf';
import { Order } from '../Types/order';
import autoTable from 'jspdf-autotable';
import { Roboto } from '../Fonts/Roboto';
import { ShippingPaymentConfiguration } from '@magistrmartin/eshop-frontend-shared';
import { round } from './Utils';

// const generateSecuredId = (id: number) => {
//   let prefix = 42132;
//   let suffix = 92691;
//   for (let i = 0; i < id; i++) {
//     prefix += ((i * prefix) % 100000) + ((i * i * 723) % 100000) + ((prefix * (100000 - prefix)) % 100000) + ((i * i * i * 931) % 100000);
//     prefix %= 100000;
//     suffix += ((suffix * 31) % 100000) + ((((i * i) % 100000) * i * 41) % 100000) + 942 + ((i * suffix) % 100000) + i;
//     suffix %= 100000;
//   }
//   if (prefix < 10000) prefix += 10000 * (id % 10);
//   return `${prefix}00000`.substring(0, 5) + id.toString() + `${suffix}00000`.substring(0, 5);
// };

const toDataUrl = (url: string, callback: (data: string) => void) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = () => {
    const reader = new FileReader();
    reader.onloadend = () => callback((reader.result || '').toString());
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
};

export async function generateOrderPdf(order: Order, deliveryPaymentConfig?: ShippingPaymentConfiguration) {
  toDataUrl('/img/logo.jpg', (img) => {
    const doc = new jsPDF();

    doc.addFileToVFS('Roboto-Regular.ttf', Roboto);
    doc.addFont('Roboto-Regular.ttf', 'Roboto', 'normal');
    doc.setFont('Roboto');

    doc.addImage(img, 'JPEG', 10, 10, 80, 23);

    doc.text(`\nInformace o objednávce č. ${order.id}`, 10, 35);
    autoTable(doc, {
      body: [
        ['Číslo objednávky', order.id ?? -1],
        [
          'Typ platby',
          deliveryPaymentConfig?.paymentPrices[order.paymentType as 'pickup' | 'bankTransaction' | 'paymentGate']
            .label ?? 'Error',
        ],
        [
          'Způsob dopravy',
          deliveryPaymentConfig?.shippingPrices[
            order.deliveryInfo.deliveryType as 'personalPickup' | 'packeta' | 'packetaHome'
          ].label ?? 'Error',
        ],
        [
          'Dodací adresa',
          `${order.deliveryInfo.surname} ${order.deliveryInfo.name}, ${
            order.deliveryInfo.delStreet || order.deliveryInfo.street
          } ${order.deliveryInfo.delHouseNum || order.deliveryInfo.houseNum}, ${
            order.deliveryInfo.delCity || order.deliveryInfo.city
          }  ${order.deliveryInfo.delZip || order.deliveryInfo.zip}`,
        ],
        [
          'Fakturační adresa',
          `${order.deliveryInfo.surname} ${order.deliveryInfo.name}, ${order.deliveryInfo.street} ${order.deliveryInfo.houseNum}, ${order.deliveryInfo.city}  ${order.deliveryInfo.zip}`,
        ],
      ],
      startY: 45,
      styles: {
        font: 'Roboto',
        fontStyle: 'normal',
      },
    });
    doc.text('Objednané produkty', 10, 90);

    autoTable(doc, {
      head: [['Produkt', 'Množství', 'Cena za ks s DPH', 'Celková cena']],
      body: order.orderedProductsList.map((op) => [
        `${op.productTitle} ${op.productSubtitle}`,
        op.totalAmmount || '',
        `${round((op.finalPrice || 0) / (op.totalAmmount || 1), 2)} Kč`,
        `${op.finalPrice} Kč`,
      ]),
      styles: {
        font: 'Roboto',
        fontStyle: 'normal',
      },
      startY: 95,
      headStyles: { fillColor: [85, 50, 139] },
    });

    autoTable(doc, {
      body: [
        ['Doprava', (order.deliveryInfo.deliveryPrice || 0) > 0 ? `${order.deliveryInfo.deliveryPrice} Kč` : 'Zdarma'],
        ['Platba', (order.paymentPrice || 0) > 0 ? `${order.paymentPrice} Kč` : 'Zdarma'],
        ['Celková fakturovaná částka s DPH', `${order.totalPrice} Kč`],
        ['Zaplaceno', '0 Kč'],
        [
          {
            title: 'K úhradě',
            styles: {
              fontStyle: 'bold',
            },
          },
          {
            title: `${order.totalPrice} Kč`,
            styles: {
              fontStyle: 'bold',
            },
          },
        ],
      ],
      styles: {
        font: 'Roboto',
      },
    });

    doc.save('Objednávka.pdf');
  });
}
