import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, TextField } from '@mui/material';
import { catalogService } from '../../Utils/ApiService';
import { useLayout } from '../../Layout/LayoutContext';

interface IProps {
  prdId: number;
}

export default function ChangePrdId({ prdId }: IProps) {
  const [open, setOpen] = useState(false);
  const [newId, setNewId] = useState('');

  const layout = useLayout();

  const saveChanges = () => {
    catalogService.post('/products/changeId', { oldId: prdId, newId: newId }, null, {
      success: () => {
        window.location.reload();
      },
      error: () => layout.error('Při měnění id došlo k chybě'),
    });
  };

  return (
    <>
      <Fab style={{ textTransform: 'none' }} size="small" onClick={() => setOpen(true)} color="primary">
        ID
      </Fab>
      <Dialog maxWidth="lg" open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Změnit ID Produktu</DialogTitle>
        <DialogContent>
          <TextField variant="standard" label="Nové ID" value={newId} onChange={(e) => setNewId(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button color="primary" onClick={saveChanges}>
            Uložit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
