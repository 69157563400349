import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import { catalogService } from '../../Utils/ApiService';
import { IProductStamp } from '../EditProduct/EditProduct';

interface IProps {
  openerRenderer: (open: () => void) => JSX.Element;
  products?: (IProduct | IProductStamp)[];
  onSelect: (product: IProductStamp) => void;
  title?: string;
  text?: string;
  label?: string;
  selectButtonLabel?: string;
}

export default function ProductSelectModal({
  onSelect,
  openerRenderer,
  products: productsParam,
  title,
  text,
  label,
  selectButtonLabel,
}: IProps) {
  const [opened, setOpened] = useState(false);
  const [allProducts, setAllProducts] = useState<IProductStamp[]>(
    (productsParam?.length || 0) === 0 ? [] : (productsParam as IProductStamp[])
  );
  const [selectedProduct, setSelectedProduct] = useState<IProductStamp | undefined>(undefined);

  useEffect(() => {
    if ((productsParam?.length || 0) === 0) {
      catalogService.get(
        'products/all',
        { fields: 'id,title,subtitle' },
        {
          success: setAllProducts,
          error: console.log,
        }
      );
    }
  }, [productsParam]);

  return (
    <>
      {openerRenderer(() => setOpened(true))}
      <Dialog open={opened} onClose={() => setOpened(false)} fullWidth maxWidth="md">
        <DialogTitle>{title || 'Výběr produktu'}</DialogTitle>
        <DialogContent>
          {text}
          <Autocomplete
            value={{
              id: selectedProduct?.id || 0,
              label:
                allProducts.filter((p) => p.id === selectedProduct?.id).map((p) => `${p.title} ${p.subtitle}`)[0] || '',
            }}
            onChange={(e, val) => val && setSelectedProduct(allProducts.find((p) => p.id === val.id))}
            options={allProducts.map((p) => ({
              id: p.id,
              label: `${p.title} ${p.subtitle}`,
            }))}
            isOptionEqualToValue={(v, o) => v.id === o.id}
            fullWidth
            renderInput={(params) => <TextField label={label || 'Produkt'} {...params} />}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpened(false);
              setSelectedProduct(undefined);
            }}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              if (selectedProduct) {
                console.log(selectedProduct);
                onSelect(selectedProduct);
                setOpened(false);
                setSelectedProduct(undefined);
              }
            }}
            disabled={selectedProduct === undefined}
          >
            {selectButtonLabel || 'Vybrat'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
