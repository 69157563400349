import readXlsxFile from 'read-excel-file';
import ExportJsonExcel from 'js-export-excel';
import { IExcelError } from './ProductsImport';

export interface ISelloutBonusImportRow {
  code: string;
  id: number;
  manufacturer: string;
  title: string;
  sellout: number;
  validityFrom: Date;
  validityTo: Date;
}

export interface ISelloutBonusExportRow {
  sales: number;
}


const excelSchema = {
  'Kod produktu': {
    prop: 'code',
    type: String,
  },
  'PDK kod': {
    prop: 'id',
    type: Number,
    required: true,
  },
  Výrobce: {
    prop: 'manufacturer',
    type: String,
  },
  'Název produktu': {
    prop: 'title',
    type: String,
  },
  'Bonus Kč/ks': {
    prop: 'sellout',
    type: Number,
    required: true,
  },
  'Platnost OD': {
    prop: 'validityFrom',
    type: Date,
    required: true,
  },
  'Platnost DO': {
    prop: 'validityTo',
    type: Date,
    required: true,
  },
};

export async function importSelloutFromExcel(
  file?: File
): Promise<{ rows: ISelloutBonusImportRow[]; errors: IExcelError[] }> {
  return readXlsxFile(file, { schema: excelSchema });
}

export const exportSelloutToExcel = (data: ISelloutBonusExportRow[]) => {
  const excel = new ExportJsonExcel({
    fileName: 'export',
    datas: [
      {
        sheetData: data,
        sheetName: 'sheet',
        sheetFilter: ['code', 'id', 'manufacturer', 'title', 'sellout', 'validityFrom', 'validityTo', 'sales'],
        sheetHeader: [
          'Kod produktu',
          'PDK kod',
          'Výrobce',
          'Název produktu',
          'Bonus Kč/ks',
          'Platnost OD',
          'Platnost DO',
          'Prodejnost',
        ],
      },
    ],
  });
  excel.saveExcel();
};
