import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import OrderStatusLabel from '../Components/OrderStatusLabel';
import { useLayout } from '../Layout/LayoutContext';
import { InternalOrder } from '../Types/order';
import { InternalOrderState, StatusChangeHistory } from '@magistrmartin/eshop-frontend-shared';
import { ordersService } from '../Utils/ApiService';
import { getKeyExtractorComparer } from '../Utils/Comparers';
import { round, separateThousands } from '../Utils/Utils';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      width: '100vw',
      height: 'calc(100vh - 100px)',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

interface IProps {
  token: string;
}

export default function InternalOrderDistributorEdit({ token }: IProps) {
  const classes = useStyles();
  const [order, setOrder] = useState<InternalOrder | undefined | 'error'>(undefined);
  const [comment, setComment] = useState('');

  const layout = useLayout();

  useEffect(
    () => {
      ordersService.get(
        '/internal/orders/getSecured',
        { securityToken: token },
        {
          success: setOrder,
          error: () => {
            layout.error(
              'Při načítání objednávky došlo k chybě. Zkontrolujte si prosím, že máte validní personalizovaný odkaz a případně nás prosím kontaktujte.'
            );
            setOrder('error');
          },
        }
      );
    },
    //eslint-disable-next-line
    []
  );

  const save = (newStatus: InternalOrderState) =>
    order &&
    order !== 'error' &&
    layout.confirm(
      'Změna statusu objednávky',
      'Opravdu chcete uložit objednávku a změnit její status? Status ani komentář nebude možné dále měnit',
      () =>
        ordersService.post(
          '/internal/orders/changeStatus',
          { orderId: order.id, newStatus: newStatus, remark: comment },
          null,
          {
            success: (newRecord: StatusChangeHistory) =>
              setOrder({
                ...order,
                status: newStatus,
                statusChangeHistoryList: [...(order.statusChangeHistoryList || []), newRecord],
              }),
            error: () =>
              layout.error(
                'Při ukládání změn došlo k chybě. Obnovte stránku a zkuste to prosím znovu, případně nás prosím kontaktujte.'
              ),
          }
        )
    );

  const changedFulfilled = (productId: number, newValue: boolean) =>
    order !== 'error' &&
    order !== undefined &&
    ordersService.post(
      '/internal/orders/setItemUnfulfilled',
      {
        orderId: order?.id,
        productId: productId,
        newValue: newValue,
      },
      null,
      {
        success: () =>
          setOrder({
            ...order,
            orderedProductsList: order.orderedProductsList.map((p) =>
              p.productId === productId ? { ...p, unfulfilled: newValue } : p
            ),
          }),
        error: () => layout.error('Při ukládání dat došlo k chybě'),
      }
    );

  return (
    <>
      {order === undefined ? (
        <div className={classes.container}>
          <Typography>Načítám objednávku</Typography>
        </div>
      ) : order === 'error' ? (
        <div className={classes.container}>
          <Typography>
            Objednávku se nepodařilo načíst. Zkontrolujte si, že používáte personalizovaný odkaz obdržený emailem a
            případně nás prosím kontaktujte.
          </Typography>
        </div>
      ) : (
        <>
          <div style={{ width: '90vw', paddingLeft: '2vw' }}>
            <br />
            <img src="/img/logo.jpg" style={{ height: 64 }} alt="logo" />
            <br />
            <br />
            <Typography variant="h4">Rekapitulace objednávky č. {order.id}</Typography>
            <br />
            <Typography>
              Děkujeme za vyřízení objednávky. Prosíme o kontrolu akce, jestli souhlasí nastavení u nás v interním
              systému MM s Vaší aktuální nabídkou. Předejdeme tak nejasnostem a pozdržení naskladnění závozu. Děkujeme
              za spolupráci.
            </Typography>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Typography variant="h6">Objednané produkty</Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>PDK Produktu</TableCell>
                      <TableCell>Produkt</TableCell>
                      <TableCell>Množství</TableCell>
                      <TableCell>Akce</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order.orderedProductsList
                      .slice()
                      .sort(getKeyExtractorComparer('productTitle'))
                      .map((p) => (
                        <TableRow key={p.productId} style={p.unfulfilled ? { backgroundColor: '#dc3545' } : {}}>
                          <TableCell>
                            <Button color="primary" onClick={() => changedFulfilled(p.productId, !p.unfulfilled)}>
                              {p.unfulfilled ? 'Nastavit jako vykryto' : 'Nastavit jako nevykryto'}
                            </Button>
                          </TableCell>
                          <TableCell>{p.productId}</TableCell>
                          <TableCell>
                            {p.productTitle} {p.productSubtitle}
                          </TableCell>
                          <TableCell>{p.amount}</TableCell>
                          <TableCell>{p.action}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <br />
                <br />
                <Typography variant="h6">Marketingová spolupráce</Typography>
                <br />
                <Typography>
                  V případě zájmu o navýšení odběrů můžeme spolu individuálně projít možnosti spolupráce zejména v
                  oblasti marketingu. Přehled nejoblíbenějších služeb zasíláme v příloze. Možno domluvit i další služby
                  včetně nastavení cen (nemusí být striktně formou cen v nabídce, ale i rabatovým ekvivalentem nebo cena
                  navázána na prodeje).
                </Typography>
                <br />
                <Typography>
                  Postupně zalistováváme i další novinky. V případě zájmu nám můžete zaslat obrázky skrz uschovna.cz
                  nebo wetransfer.com. Přes email posílejte maximálně jednotky položek kvůli velikosti emailové
                  schránky. Děkujeme za pochopení. Dále zasílejte i textové popisky, případně další textové a zejména
                  grafické materiály, které můžeme využít na podporu prodeje. Děkujeme!
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h6">Informace o objednávce</Typography>
                <br />
                <table>
                  <tr>
                    <td>
                      <b>
                        <Typography>Lékárna: </Typography>
                      </b>
                    </td>
                    <td>
                      <Typography>{order.pharma}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>
                        <Typography>Distributor: </Typography>
                      </b>
                    </td>
                    <td>
                      <Typography>{order.dristributor}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>
                        <Typography>Připravil: </Typography>
                      </b>
                    </td>
                    <td>
                      <Typography>{order.author}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>
                        <Typography>Kontakt: </Typography>
                      </b>
                    </td>
                    <td>
                      {order.authorPhone}, {order.authorMail}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>
                        <Typography>Celková cena:</Typography>
                      </b>
                    </td>
                    <td>
                      <Typography>
                        {separateThousands(round(order.totalPrice || 0, 2))} Kč ve výrobních cenách bez DPH
                      </Typography>
                    </td>
                  </tr>
                </table>
                <Typography variant="h6">Historie vývoje objednávky</Typography>
                <br />
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <OrderStatusLabel orderStatus="Waiting" />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </td>
                      <td>
                        <Typography>{new Date(order.createdDate || '').toLocaleString('cs-CZ')}</Typography>
                      </td>
                    </tr>
                    {order.statusChangeHistoryList.map((h) => (
                      <React.Fragment key={h.id}>
                        <tr>
                          <td>
                            <OrderStatusLabel orderStatus={h.newState as InternalOrderState} />
                          </td>
                          <td>
                            <Typography>{new Date(h.date || '').toLocaleString('cs-CZ')}</Typography>
                          </td>
                        </tr>
                        {h.remark && h.newState !== 'received' && (
                          <tr>
                            <td colSpan={2}>
                              <Typography>Komentář: {h.remark}</Typography>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
                {order.status === 'new' && (
                  <>
                    <br />
                    <br />
                    <TextField
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      multiline
                      rows={4}
                      label="Komentář"
                      fullWidth
                      variant="outlined"
                    />
                    <br />
                    <br />
                    <Button onClick={() => save('fullfilled')} color="primary" variant="contained">
                      Uložit a nastavit status na Vykryto
                    </Button>
                    <br />
                    <br />
                    <Button onClick={() => save('partiallyFullfilled')} color="primary" variant="contained">
                      Uložit a nastavit status na Částečně vykryto
                    </Button>
                    <br />
                    <br />
                    <Button onClick={() => save('rejected')} color="primary" variant="contained">
                      Uložit a nastavit status na Zamítnuto
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
            <br />
          </div>
        </>
      )}
    </>
  );
}
