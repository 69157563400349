import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  TextField,
} from '@mui/material';
import { useLayout } from '../../Layout/LayoutContext';
import { ExpectedFreeItemsDelivery } from '../../Types/base';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import { catalogService, ordersService } from '../../Utils/ApiService';

const emptyFreeItemDelivery: ExpectedFreeItemsDelivery = {
  amount: 0,
  isFromCatalog: true,
};

interface IProps {
  onAdd: (newFreeItem: ExpectedFreeItemsDelivery) => void;
}

export default function AddFreItemDeliveryModal({ onAdd }: IProps) {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [newFreeItem, setNewFreeItem] = useState<ExpectedFreeItemsDelivery>(emptyFreeItemDelivery);
  const [opened, setOpened] = useState(false);

  const layout = useLayout();

  useEffect(() => {
    const fields = ['id', 'title', 'subtitle', 'supplierTag', 'excelOrder'];
    catalogService.get(
      'products/all',
      {
        fields: fields.join(','),
        mapped: true,
      },
      {
        success: setProducts,
        error: layout.error,
      }
    );
    // eslint-disable-next-line
  }, []);

  const saveChanges = () =>
    ordersService.post('/stats/expectedFreeItemsDelivery', {}, newFreeItem, {
      success: (res: ExpectedFreeItemsDelivery) => {
        onAdd(res);
        setOpened(false);
        setNewFreeItem(emptyFreeItemDelivery);
      },
      error: () => layout.error('Při ukládání došlo k chybě'),
    });

  return (
    <>
      <Button color="primary" onClick={() => setOpened(true)}>
        Přidat produkt do evidence
      </Button>
      <Dialog maxWidth="lg" open={opened} onClose={() => setOpened(false)} fullWidth>
        <DialogTitle title="Přidat produkt pro kusy zdarma" />
        <DialogContent>
          <Autocomplete
            value={{ id: 0, label: '', p: undefined }}
            onChange={(e, val) =>
              val?.p &&
              setNewFreeItem({
                ...newFreeItem,
                productFullName: `${val?.p.title} ${val?.p.subtitle}`,
                supplier: val?.p.supplierTag,
                productId: val?.p.id,
              })
            }
            fullWidth
            renderOption={(_, p) => <ListItem key={p.id}>{p.label}</ListItem>}
            options={[
              { id: 0, label: '', p: undefined },
              ...products.map((p) => ({
                id: p.id,
                label: `${p.title} ${p.subtitle} (${p.supplierTag})`,
                p: p,
              })),
            ]}
            renderInput={(p) => <TextField label="Vybrat produkt" {...p} />}
          />
          <br />
          <TextField
            variant="standard"
            label="Počet kusů zdarma"
            type="number"
            fullWidth
            onChange={(e) =>
              setNewFreeItem({
                ...newFreeItem,
                amount: parseInt(e.target.value),
              })
            }
            value={newFreeItem.amount}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setOpened(false)}>
            Zrušit
          </Button>
          <Button
            color="primary"
            disabled={newFreeItem.productId === undefined || newFreeItem.amount <= 0}
            onClick={saveChanges}
          >
            Přidat
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
