import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Grid,
  CircularProgress,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { IRack } from '../../Types/base';
import RackEditor from '../CategoryManagement/Components/RackEditor';
import { catalogService } from '../../Utils/ApiService';
import { useLayout } from '../../Layout/LayoutContext';

interface IProps {
  productId: number;
}

export default function ViewProductPositionModal({ productId }: IProps) {
  const [open, setOpen] = useState(false);
  const [rack, setRack] = useState<IRack | undefined>(undefined);

  const layout = useLayout();

  useEffect(() => {
    if (open) {
      catalogService.get(
        `/racks/byProduct`,
        { productId: productId },
        {
          success: setRack,
          error: () => layout.error('Při nacítání pozice produktu došlo k chybě'),
        }
      );
    } else {
      setRack(undefined);
    }
    //eslint-disable-next-line
  }, [productId, open]);

  return (
    <>
      <IconButton size="large" onClick={() => setOpen(true)}>
        <Search />
      </IconButton>
      <Dialog maxWidth="lg" fullWidth open={open} onClose={() => setOpen(false)}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          {rack ? (
            <RackEditor rack={rack} productToHighlight={productId} />
          ) : (
            <Grid container justifyContent="center">
              <CircularProgress />
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setOpen(false)}>
            Zavřít
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
