import React, { useEffect, useState } from 'react';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useLayout } from '../../Layout/LayoutContext';
import { catalogService, statisticsService } from '../../Utils/ApiService';
import { ICategory } from '@magistrmartin/eshop-frontend-shared';

interface IProps {
  onLoad: (products: number[]) => void;
}

export default function LoadProductsModal({ onLoad }: IProps) {
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [months, setMonths] = useState(0);
  const [category, setCategory] = useState('');
  const [allCategories, setAllCategories] = useState<ICategory[]>([]);
  const layout = useLayout();

  useEffect(() => {
    catalogService.get('categories/all', [], {
      success: setAllCategories,
      error: layout.error,
    });
    //eslint-disable-next-line
  }, []);

  const load = () =>
    statisticsService.get(
      'topSaledProductsIds',
      { productsCount: count, months: months, categoryId: category },
      {
        success: (data: number[]) => {
          setOpen(false);
          onLoad(data);
        },
        error: () => layout.error('Při načítání dat došlo k chybě'),
      }
    );

  return (
    <>
      <Button color="primary" onClick={() => setOpen(true)}>
        Nahrát nejprodávanější produkty
      </Button>
      <Dialog maxWidth="md" open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Načíst nejprodávanější produkty</DialogTitle>
        <DialogContent>
          <TextField
            variant="standard"
            label="Počet produktů"
            value={count}
            type="number"
            onChange={(e) => setCount(parseInt(e.target.value))}
          />
          &nbsp;
          <TextField
            variant="standard"
            label="Počet měsíců"
            value={months}
            type="number"
            onChange={(e) => setMonths(parseInt(e.target.value))}
          />
          <Autocomplete
            value={{
              id: category,
              label: allCategories.filter((c) => c.id === category).map((c) => `${c.id} - ${c.description}`)[0] || '',
            }}
            onChange={(e, val) => val && setCategory(val.id)}
            options={allCategories.map((c) => ({
              id: c.id,
              label: `${c.id} - ${c.description}`,
            }))}
            fullWidth
            isOptionEqualToValue={(o, v) => o.id === v.id}
            renderInput={(p) => <TextField label="Kategorie" {...p} />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button color="primary" disabled={count <= 0 || months <= 0} onClick={load}>
            Nahrát produkty
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
