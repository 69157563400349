import React, { useEffect, useState } from 'react';
import { Table, TableRow, TableHead, Typography, TableBody, TableCell, IconButton, Button } from '@mui/material';
import { useLayout } from '../Layout/LayoutContext';
import { PharmosWatchListItem } from '../Types/base';
import { catalogService } from '../Utils/ApiService';
import { nullOrUndefined } from '../Utils/Utils';
import { Delete } from '@mui/icons-material';
import { IProductStamp } from './EditProduct/EditProduct';
import ProductSelectModal from './Modals/ProductSelectModal';

export default function PharmosWatchList() {
  const [wlItems, setWLItems] = useState<PharmosWatchListItem[]>([]);

  const layout = useLayout();

  useEffect(() => {
    layout.setIsLoading(true);
    catalogService.get(
      '/pharmos/watchList',
      {},
      {
        success: setWLItems,
        error: () => layout.error('Při načítání Watch Listu došlo k chybe'),
        finally: () => layout.setIsLoading(false),
      }
    );
    // eslint-disable-next-line
  }, []);

  const addProduct = (product: IProductStamp) =>
    catalogService.post(
      '/pharmos/watchList',
      {},
      {
        id: 0,
        productId: product.id,
        productName: `${product.title} ${product.subtitle}`,
        foundDate: undefined,
      } as PharmosWatchListItem,
      {
        success: (item: PharmosWatchListItem) => setWLItems([...wlItems, item]),
        error: () => layout.error('Při přidávání produktu došlo k chybě'),
      }
    );

  const deleteItem = (id: number) =>
    catalogService.delete('/pharmos/watchList', { id: id }, null, {
      success: () => setWLItems(wlItems.filter((i) => i.id !== id)),
      error: () => layout.error('Při mazání produktu došlo k chybě'),
    });

  const refreshList = () => {
    catalogService.post('/pharmos/updateWatchList', {}, null, {
      success: () => window.location.reload(),
      error: () => layout.error('Při kontrole dostupnosti došlo k chybě'),
    });
  };

  return (
    <>
      {['active', 'found']
        .filter(
          (type) =>
            wlItems.filter(
              (wi) =>
                (type === 'active' && nullOrUndefined(wi.foundDate)) ||
                (type === 'found' && !nullOrUndefined(wi.foundDate))
            ).length > 0
        )
        .map((type) => (
          <React.Fragment key={type}>
            <br />
            <Typography variant="h6">
              {type === 'active' ? 'Aktivní produkty na watchListu' : 'Nalezené produkty na watchlistu'}
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>PDK produktu</TableCell>
                  <TableCell>Název produktu</TableCell>
                  {type === 'found' && <TableCell>Dostupné od</TableCell>}
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {wlItems
                  .filter(
                    (wi) =>
                      (type === 'active' && nullOrUndefined(wi.foundDate)) ||
                      (type === 'found' && !nullOrUndefined(wi.foundDate))
                  )
                  .map((wi) => (
                    <TableRow key={wi.id}>
                      <TableCell>{wi.id}</TableCell>
                      <TableCell>{wi.productId}</TableCell>
                      <TableCell>{wi.productName}</TableCell>
                      {type === 'found' && (
                        <TableCell>{new Date(wi.foundDate || '').toLocaleString('cs-CZ')}</TableCell>
                      )}
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            layout.deleteConfirm('Opravdu chcecte smazat teto produkt?', '', () => deleteItem(wi.id))
                          }
                          size="large"
                        >
                          <Delete color="error" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <br />
          </React.Fragment>
        ))}
      <ProductSelectModal
        onSelect={addProduct}
        openerRenderer={(open) => (
          <Button variant="contained" onClick={open} color="primary">
            Přidat produkt
          </Button>
        )}
        selectButtonLabel="Přidat"
      />
      <Button onClick={refreshList} color="primary">
        Obnovit watch list
      </Button>
    </>
  );
}
