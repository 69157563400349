import React from 'react';
import { Grid, TextField, Theme } from '@mui/material';
import SwitchButton from './SwitchButton';
import { IBaseDiscount, IMinPrice } from '@magistrmartin/eshop-frontend-shared';
import PriceInput from './PriceInput';
import { createStyles, makeStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemRoot: {
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  })
);

interface IProps {
  discount: IBaseDiscount;
  setDiscount: (dis: IBaseDiscount) => void;
}

function MinPriceChanger({ minimum, setMinimum }: { minimum?: IMinPrice; setMinimum: (min: IMinPrice) => void }) {
  const classes = useStyles();

  return (
    <>
      <Grid className={classes.itemRoot} item xs={6}>
        <TextField
          variant="standard"
          type="number"
          fullWidth
          label="Od"
          value={minimum?.value}
          onChange={(e) =>
            setMinimum({
              ...(minimum || {}),
              value: parseFloat(e.target.value),
            })
          }
        />
      </Grid>
      <Grid className={classes.itemRoot} item xs={6}>
        <SwitchButton
          data={{ items: 'ks', price: 'Kč' }}
          value={minimum?.factor}
          onChange={(val) => setMinimum({ ...(minimum || {}), factor: val as 'items' | 'price' })}
        />
      </Grid>
    </>
  );
}

function VariablePropertiesEditor({
  discount,
  setDiscount,
}: {
  discount: IBaseDiscount;
  setDiscount: (dis: IBaseDiscount) => void;
}) {
  const classes = useStyles();

  return discount.type === 'FREE_DELIVERY' ? (
    <Grid container>
      <MinPriceChanger minimum={discount.minimum} setMinimum={(min) => setDiscount({ ...discount, minimum: min })} />
    </Grid>
  ) : discount.type === 'PRESENT' ? (
    <Grid container>
      <MinPriceChanger minimum={discount.minimum} setMinimum={(min) => setDiscount({ ...discount, minimum: min })} />
      <Grid className={classes.itemRoot} item xs={6}>
        <TextField
          variant="standard"
          fullWidth
          label="ID Dárku"
          value={discount?.details || undefined}
          onChange={(e) => setDiscount({ ...discount, details: e.target.value })}
        />
      </Grid>
      <Grid className={classes.itemRoot} item xs={6}>
        <SwitchButton
          data={{ normal: 'klasický', repeate: 'Opakovací' }}
          value={discount.single ? 'normal' : 'repeate'}
          onChange={(val) => setDiscount({ ...discount, single: val === 'normal' })}
        />
      </Grid>
    </Grid>
  ) : discount.type === 'CENTRAL_PRESENT' ? (
    <Grid container>
      <MinPriceChanger minimum={discount.minimum} setMinimum={(min) => setDiscount({ ...discount, minimum: min })} />
      <Grid className={classes.itemRoot} item xs={6}>
        <TextField
          variant="standard"
          fullWidth
          label="ID Dárku"
          value={discount?.details ? discount.details.split(';')[0] : undefined}
          onChange={(e) =>
            setDiscount({
              ...discount,
              details: discount?.details
                ? e.target.value + ';' + (discount.details.split(';')[1] || 'all')
                : e.target.value + ';all',
            })
          }
        />
      </Grid>
      <Grid className={classes.itemRoot} item xs={6}>
        <SwitchButton
          data={{ normal: 'klasický', repeate: 'Opakovací' }}
          value={discount.single ? 'normal' : 'repeate'}
          onChange={(val) => setDiscount({ ...discount, single: val === 'normal' })}
        />
      </Grid>
      <Grid className={classes.itemRoot} item xs={12}>
        <SwitchButton
          data={{
            all: 'Všichni',
            registered: 'Přihlášení',
            unregistered: 'Nepřihlášení',
          }}
          value={discount?.details ? discount.details.split(';')[1] || 'all' : 'all'}
          onChange={(val) =>
            setDiscount({
              ...discount,
              details: discount?.details ? (discount.details.split(';')[0] || '') + ';' + val : ';' + val,
            })
          }
        />
      </Grid>
    </Grid>
  ) : discount.type === 'MIX' ? (
    <Grid container>
      <Grid className={classes.itemRoot} item xs={6}>
        <TextField
          variant="standard"
          type="number"
          fullWidth
          label="n"
          value={discount.minimum?.value || undefined}
          onChange={(e) =>
            setDiscount({
              ...discount,
              minimum: {
                ...(discount.minimum || {}),
                value: parseInt(e.target.value),
              },
            })
          }
        />
      </Grid>
      <Grid className={classes.itemRoot} item xs={6}>
        <TextField
          variant="standard"
          type="number"
          fullWidth
          label="+ m zdarma"
          value={discount?.details || undefined}
          onChange={(e) => setDiscount({ ...discount, details: e.target.value })}
        />
      </Grid>
    </Grid>
  ) : discount.type === 'SPECIAL' ? (
    <Grid container>
      <MinPriceChanger minimum={discount.minimum} setMinimum={(min) => setDiscount({ ...discount, minimum: min })} />
      <Grid className={classes.itemRoot} item xs={4}>
        <PriceInput
          fullWidth
          label="Sleva"
          value={discount.discount?.value}
          onChange={(val) =>
            setDiscount({
              ...discount,
              discount: { ...(discount.discount || {}), value: val },
            })
          }
        />
      </Grid>
    </Grid>
  ) : discount.type === 'SECOND_ITEM' ? (
    <Grid container>
      <Grid className={classes.itemRoot} item xs={4}>
        <PriceInput
          fullWidth
          label="Sleva"
          value={discount.discount?.value}
          onChange={(val) =>
            setDiscount({
              ...discount,
              discount: { ...(discount.discount || {}), value: val },
            })
          }
        />
      </Grid>
      <Grid className={classes.itemRoot} item xs={4}>
        <SwitchButton
          data={{ absolute: 'Kč', relative: '%' }}
          value={discount.discount?.factor}
          onChange={(val) =>
            setDiscount({
              ...discount,
              discount: {
                ...(discount.discount || {}),
                factor: val as 'absolute' | 'relative',
              },
            })
          }
        />
      </Grid>
    </Grid>
  ) : discount.type === 'CROSS' ? (
    <Grid container>
      <Grid className={classes.itemRoot} item xs={6}>
        <PriceInput
          fullWidth
          label="Sleva"
          value={discount.discount?.value}
          onChange={(val) =>
            setDiscount({
              ...discount,
              discount: { ...(discount.discount || {}), value: val },
            })
          }
        />
      </Grid>
      <Grid className={classes.itemRoot} item xs={6}>
        <TextField
          variant="standard"
          fullWidth
          type="number"
          label="Počet ve skupine"
          value={discount.minimum?.value || ''}
          onChange={(e) =>
            setDiscount({
              ...discount,
              minimum: {
                ...(discount.minimum || {}),
                value: parseInt(e.target.value),
              },
            })
          }
        />
      </Grid>
      <Grid className={classes.itemRoot} item xs={6}>
        <TextField
          variant="standard"
          fullWidth
          label="Propojená skupina"
          value={discount?.details || undefined}
          onChange={(e) => setDiscount({ ...discount, details: e.target.value })}
        />
      </Grid>
    </Grid>
  ) : (
    <></>
  );
}

export default function BasicDiscountEditor({ discount, setDiscount }: IProps) {
  const classes = useStyles();

  return (
    <>
      <VariablePropertiesEditor discount={discount} setDiscount={setDiscount} />
      <br />
      <Grid container>
        <Grid item xs={12} md={6} className={classes.itemRoot}>
          <DatePicker
            format="yyyy-MM-dd"
            label="Platí od"
            value={discount.validity?.from || null}
            onChange={(date) =>
              setDiscount({
                ...discount,
                validity: {
                  ...(discount.validity || {}),
                  from: date || undefined,
                },
              })
            }
            slots={{ textField: TextField }}
            slotProps={{ textField: { fullWidth: true } }}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.itemRoot}>
          <DatePicker
            format="yyyy-MM-dd"
            label="Platí do"
            value={discount.validity?.to || null}
            onChange={(date) =>
              setDiscount({
                ...discount,
                validity: {
                  ...(discount.validity || {}),
                  to: date || undefined,
                },
              })
            }
            slots={{ textField: TextField }}
            slotProps={{ textField: { fullWidth: true } }}
          />
        </Grid>
        <Grid item xs={12} className={classes.itemRoot}>
          <TextField
            variant="standard"
            fullWidth
            margin="normal"
            label="Poznámka ke slevě"
            value={discount.remark || ''}
            onChange={(e) => setDiscount({ ...discount, remark: e.target.value })}
          />
        </Grid>
      </Grid>
    </>
  );
}
