import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';
import { DiscountType, IBaseDiscount } from '@magistrmartin/eshop-frontend-shared';
import { useLayout } from '../../Layout/LayoutContext';
import { catalogService } from '../../Utils/ApiService';
import SwitchButton from '../../Components/SwitchButton';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    discountRow: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'lightgrey',
      },
    },
    selectedRow: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  })
);

interface IProps {
  productId?: number;
  onSave: (dis: IBaseDiscount) => void;
  forbiddenDiscounts?: DiscountType[];
}
export default function LinkDiscount({ onSave, forbiddenDiscounts, productId }: IProps) {
  const classes = useStyles();

  const allowedDiscountTypes = {
    FREE_DELIVERY: 'Doprava zdarma',
    PRESENT: 'Dárek',
    CENTRAL_PRESENT: 'Centrální dárek',
    MIX: 'Rabat MIX',
    SPECIAL: 'Speciální sleva',
    SECOND_ITEM: 'Sleva na 2. produkt',
    CROSS: 'Křížová sleva',
    BASIC: '...', // TODO: refactor, in CreateDiscount.tsx is the same object...
  };

  forbiddenDiscounts?.forEach((fd) => delete allowedDiscountTypes[fd]);

  const [open, setOpen] = useState(false);
  const [type, setType] = useState<DiscountType>(Object.keys(allowedDiscountTypes)[0] as DiscountType);
  const [allDiscounts, setAllDiscounts] = useState<IBaseDiscount[] | undefined>(undefined);
  const [discount, setDiscount] = useState<IBaseDiscount | undefined>(undefined);
  const layout = useLayout();

  if (Object.keys(allowedDiscountTypes).length === 0) return <></>;

  const loadDiscounts = () => {
    catalogService.get(
      '/discounts/all',
      {},
      {
        success: setAllDiscounts,
        error: (err) => layout.error('Slevy se nepodařilo načíst: ' + err),
      }
    );
  };

  const saveChanges = () => {
    discount &&
      catalogService.post('discounts/link', { productId: productId, discountId: discount.id }, null, {
        success: () => {
          onSave(discount);
          setDiscount(undefined);
          setOpen(false);
        },
        error: layout.error,
      });
  };

  if (open && allDiscounts === undefined) loadDiscounts();

  return (
    <>
      <Button color="primary" onClick={() => setOpen(true)}>
        Přiřadit existující slevu
      </Button>
      <Dialog maxWidth="lg" open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Přiřadit existující slevu</DialogTitle>
        <DialogContent>
          <SwitchButton
            data={allowedDiscountTypes}
            value={type}
            onChange={(val) => {
              setType(val as DiscountType);
              setDiscount(undefined);
            }}
          />
          <br />
          <hr />
          <br />
          <br />
          {allDiscounts && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Poznámka</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allDiscounts
                  .filter((d) => d.type === type)
                  .map((d) => (
                    <TableRow
                      key={d.id}
                      className={`${classes.discountRow} ${discount?.id === d.id ? classes.selectedRow : ''}`}
                      onClick={() => setDiscount(d)}
                    >
                      <TableCell>{d.id}</TableCell>
                      <TableCell>{d.remark}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button color="primary" disabled={discount === undefined} onClick={saveChanges}>
            Přiřadit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
