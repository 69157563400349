import React from 'react';
import { Autocomplete, Chip, TextField, Typography } from '@mui/material';

interface IProps {
  onChange: (newVal: string[]) => void;
  value: string[];
  options: any[];
  label: string;
  addLabel: string;
  showAll?: boolean;

  chipLabelSelector: (option: string, options: any[]) => string;
  keyPropFn: (opt: any) => string;
  valuePropFn: (opt: any) => string;
}

export default function MultiSelect({
  value,
  options,
  label,
  addLabel,
  onChange,
  chipLabelSelector,
  keyPropFn,
  valuePropFn,
  showAll,
}: IProps) {
  return (
    <>
      <Typography variant="caption" color="textSecondary">
        {label}
      </Typography>
      <br />
      <br />

      {value.map((val, i) => (
        <Chip
          key={val}
          label={chipLabelSelector(val, options)}
          onDelete={() => onChange(value.filter((_, ind) => ind !== i))}
          color="primary"
        />
      ))}

      <Autocomplete
        value={{ id: '', label: '' }}
        onChange={(e, val) => val?.id && onChange([...value, val.id])}
        options={[{ id: '', label: '' }, ...options.map((o) => ({ id: keyPropFn(o), label: valuePropFn(o) }))]}
        fullWidth
        isOptionEqualToValue={(o, v) => o.id === v.id}
        renderInput={(p) => <TextField label={addLabel} {...p} />}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id || -1}>
              {option.label}
            </li>
          );
        }}
      />
    </>
  );
}
