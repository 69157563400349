import React from 'react';
import { Button, Container, Theme, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
  })
);

export default function NotAuthenticated() {
  const classes = useStyles();
  const { loginWithRedirect } = useAuth0();

  return (
    <Container maxWidth="xs" className={classes.root}>
      <Typography variant="h6" align="center">
        Pro přístup na tyto stránky se musíte přihlásit na účet s administrátorskými právy.
      </Typography>
      <br />
      <br />
      <Button color="primary" variant="contained" onClick={() => loginWithRedirect({ ui_locales: 'cs-CZ cs' })}>
        Přihlásit se
      </Button>
    </Container>
  );
}
