import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import ExportJsonExcel from 'js-export-excel';
import { getKeyExtractorComparer } from './Comparers';
import { OrderPreview } from '../Types/order';
import { round, toCzechTime } from './Utils';
import { ShippingPaymentConfiguration } from '@magistrmartin/eshop-frontend-shared';
import { getTotalGain } from '../Pages/OrdersHistory';

export const exportOrders = (orders: OrderPreview[], deliveryPaymentConfig: ShippingPaymentConfiguration) => {
  const excel = new ExportJsonExcel({
    fileName: 'ordersExport',
    datas: [
      {
        sheetData: orders.map((o) => ({
          ...o,
          client: o.domain + ' ' + o.name + ' ' + o.surname,
          formattedDate:
            toCzechTime(new Date(o.createdDate || ''))
              .toLocaleString('cs-CZ', { weekday: 'short' })
              .toLocaleUpperCase() +
            ' ' +
            toCzechTime(new Date(o.createdDate || '')).toLocaleDateString('cs-CZ') +
            toCzechTime(new Date(o.createdDate || '')).toLocaleTimeString('cs-CZ'),
          formattedShipping:
            //@ts-ignore
            deliveryPaymentConfig?.shippingPrices[o.deliveryType].label + ' ' + o.deliveryPrice + ' Kč',
          formattedPayment:
            //@ts-ignore
            deliveryPaymentConfig?.paymentPrices[o.paymentType].label + ' ' + o.paymentPrice + ' Kč',
          gain: round(getTotalGain(o), 0).toLocaleString('cs-CZ') + ' Kč',
          margins: round((100 * getTotalGain(o)) / (o.productsTotalGains + o.additionalGains), 2).toLocaleString(
            'cs-CZ'
          ),
        })),
        sheetName: 'orders',
        sheetFilter: [
          'client',
          'id',
          'formattedDate',
          'formattedShipping',
          'formattedPayment',
          'mass',
          'status',
          'paid',
          'totalPrice',
          'gain',
          'margins',
        ],
        sheetHeader: [
          'Klient',
          'ID',
          'Datum',
          'Doprava',
          'Platba',
          'Hmotnost',
          'Status',
          'Zaplaceno',
          'Cena',
          'Zisk',
          'Marže',
        ],
      },
    ],
  });
  excel.saveExcel();
};

export const exportToExcel = (products: IProduct[]) => {
  const excel = new ExportJsonExcel({
    fileName: 'export',
    datas: [
      {
        sheetData: products.sort(getKeyExtractorComparer('excelOrder')).map((p) => {
          return {
            ...p,
            categories: p.categories?.join(';'),
            alternatives: p.alternatives?.join(';'),
            combineWith: p.combineWith?.join(';'),
            reccomendedAlternative: p.reccomendedAlternative?.alternativeId,
            reccomendedAlternativeAdvantage: p.reccomendedAlternative?.description,
          };
        }),
        sheetName: 'sheet',
        sheetFilter: [
          'id',
          'title',
          'subtitle',
          'characteristics',
          'categories',
          'reccomendedPrice',
          'actionPrice',
          'ammount',
          'shortDescription',
          'description',
          'manufacturer',
          'supplier',
          'series',
          'brandMark',
          'form',
          'activeSubstance',
          'age',
          'mass',
          'sukl',
          'alternatives',
          'reccomendedAlternative',
          'reccomendedAlternativeAdvantage',
          'published',
          'excelOrder',
          'pharmaGroup',
          'isNatural',
          'isBio',
          'isVegan',
          'noLactose',
          'whilePregnancy',
          'whileBreastFeeding',
          'combineWith',
          'productMass',
          'productWidth',
          'productHeight',
          'productDepth',
          'groupPackageAmmount',
          'higherGroupPackageAmmount',
          'maxExpirationLength',
          'preferention',
          'isMain',
          'buyingPrice',
          'tradeDiscount',
          'bonification',
          'sellout',
          'centralDistribution',
          'doNotScann',
        ] as (keyof IProduct | 'reccomendedAlternativeAdvantage')[],
        sheetHeader: [
          'PDK',
          'Hlavní název',
          'Vedlejší název',
          'Charakteristika',
          'Kategorie ID',
          'Doporučená cena',
          'Akční cena',
          'Množství jednotek',
          'Krátký popis produktu',
          'Popis produktu',
          'Výrobce',
          'Dodavatel na trh',
          'Produktová řada',
          'Značka',
          'Léková forma',
          'Účinná látka',
          'Věková osa',
          'Hmotnostní osa',
          'SÚKL',
          'PDK druhů velikostí/příchuťí produktů',
          'PDK alternativ',
          'Výhody oproti známému produktu',
          'Zveřejněn v Online Lékárně',
          'Pořadí',
          'Farmakologická skupina',
          'Přírodní',
          'Bio',
          'Vegan',
          'Bez laktózy',
          'V těhotenství',
          'Při kojení',
          'Kombinujte s',
          'Hmotnost produktu',
          'Šířka produktu',
          'Výška produktu',
          'Hloubka produktu',
          'Skupinové balení',
          'Vyšší skupinové balení',
          'Délka EXP',
          'Preference',
          'Hlavní/vedlejší',
          'Nákupní cena',
          'Sleva',
          'Bonifikace',
          'Sell-out',
          'CD',
          'IC',
        ],
      },
    ],
  });
  excel.saveExcel();
};
