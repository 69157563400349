import readXlsxFile from 'read-excel-file';
import { IExcelError } from './ProductsImport';

export interface IBonificationImportRow {
  id: number;
  title: string;
  bonification: number;
}

const excelSchema = {
  'Kod PDK': {
    prop: 'id',
    type: Number,
    required: true,
  },
  'Název produktu': {
    prop: 'title',
    type: String,
  },
  'Základní obratový bonus': {
    prop: 'bonification',
    required: true,
    parse: (raw: string) => parseFloat(raw.toString().split("-")[0])
  },
};

export async function importBonificationsFromExcel(file?: File): Promise<{ rows: IBonificationImportRow[]; errors: IExcelError[] }> {
  return readXlsxFile(file, { schema: excelSchema });
}
