import React, { useEffect, useState } from 'react';
import { IconButton, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField } from '@mui/material';
import SwitchButton from '../Components/SwitchButton';
import { webStructureService } from '../Utils/ApiService';
import { useLayout } from '../Layout/LayoutContext';
import EditFooterLink from './Modals/EditFooterLink';
import { Delete } from '@mui/icons-material';
import { domains } from '../Utils/Mutations';

export interface ILink {
  category?: number;
  link?: string;
  linkOrder?: number;
  name?: string;
  id?: number;
}

interface ICategory {
  categoryOrder?: number;
  description?: string;
  id?: number;
  footerLinksList?: ILink[];
  domain?: string;
}

export default function FooterSettings() {
  const layout = useLayout();
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<ICategory | undefined>(undefined);
  const [domainTab, setDomainTab] = useState(0);

  const changeDomain = (newDomainIndex: number) => {
    setSelectedCategory(undefined);
    setCategories([]);
    setDomainTab(newDomainIndex);
  };

  useEffect(
    () => {
      webStructureService.get(
        '/footer/',
        { domain: domains[domainTab] },
        {
          success: setCategories,
          error: layout.error,
        }
      );
    },
    //eslint-disable-next-line
    [domainTab]
  );

  const addCategory = (name: string) => {
    if (name === undefined || name.length === 0) return;
    webStructureService.post('/footer/category', { name: name, domain: domains[domainTab] }, null, {
      success: (res: ICategory) => setCategories([...categories, res]),
      error: layout.error,
    });
  };

  return (
    <div>
      <Tabs color="primary" value={domainTab} onChange={(_, val) => changeDomain(val)}>
        {domains.map((d) => (
          <Tab key={d} label={d} />
        ))}
      </Tabs>
      <br />
      <SwitchButton
        data={Object.fromEntries(categories.map((c) => [c.id?.toString(), c.description]))}
        value={selectedCategory?.id?.toString() || ''}
        onChange={(val) => setSelectedCategory(categories.filter((c) => c.id?.toString() === val)[0])}
      />
      <TextField variant="standard" label="Přidat kategorii" onBlur={(e) => addCategory(e.target.value)} />
      <br />
      <br />
      {selectedCategory && (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Název</TableCell>
                <TableCell>Odkaz</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(selectedCategory.footerLinksList || []).map((l) => (
                <TableRow key={l.id}>
                  <TableCell>{l.name}</TableCell>
                  <TableCell>{l.link}</TableCell>
                  <TableCell>
                    <EditFooterLink
                      link={l}
                      onSave={(link) => {
                        setCategories(
                          categories.map((c) =>
                            c.id === selectedCategory.id
                              ? {
                                  ...c,
                                  footerLinksList: (c.footerLinksList || []).map((ln) =>
                                    ln.id === link.id ? { ...link } : ln
                                  ),
                                }
                              : c
                          )
                        );
                        setSelectedCategory(categories.filter((cat) => cat.id === selectedCategory.id)[0]);
                      }}
                    />
                    <IconButton
                      onClick={() => {
                        layout.deleteConfirm(
                          'Opravdu chcete smazat tento odkaz?',
                          'Odkaz na stránku z patičky bude trvale smazán',
                          () => {
                            webStructureService.delete('/footer/link', { id: l.id }, null, {
                              success: () => window.location.reload(),
                              error: () => layout.error('Při mazání došlo k chybě.'),
                            });
                          }
                        );
                      }}
                      aria-label="delete link"
                      size="large"
                    >
                      <Delete color="error" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <br />
          <br />
          <EditFooterLink
            categoryId={selectedCategory.id}
            onSave={(link) => {
              setCategories(
                categories.map((c) =>
                  c.id === selectedCategory.id ? { ...c, footerLinksList: [...(c.footerLinksList || []), link] } : c
                )
              );
              setSelectedCategory(categories.filter((cat) => cat.id === selectedCategory.id)[0]);
            }}
          />
        </>
      )}
    </div>
  );
}
