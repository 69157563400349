import { FormControl, FormHelperText, Input, InputAdornment } from '@mui/material';
import React from 'react';

interface IProps {
  label?: string;
  value?: number;
  onChange?: (value: number) => void;
  fullWidth?: boolean;
  className?: string;
  isFloat?: boolean;
  disabled?: boolean;
}

export default function PriceInput({ label, value, onChange, fullWidth, className, isFloat, disabled }: IProps) {
  if (onChange === undefined) onChange = () => {};
  return (
    <FormControl variant="standard" fullWidth={fullWidth === true} className={className || ''} disabled={disabled}>
      <FormHelperText id="price input text">{label || ''}</FormHelperText>
      <Input
        type="number"
        value={value || 0}
        onChange={(e) =>
          (onChange as (value: number) => void)(
            (isFloat && parseFloat(e.target.value || '0')) || parseInt(e.target.value || '0')
          )
        }
        endAdornment={<InputAdornment position="end">Kč</InputAdornment>}
        aria-describedby="price input"
      />
    </FormControl>
  );
}
