import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Button, Grid, Tab, FormHelperText } from '@mui/material';
import dayjs, { Dayjs} from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useState, useMemo } from 'react';
import { catalogService } from '../Utils/ApiService';
import 'dayjs/locale/cs';
dayjs.locale('cs');

function generateSmallPDF(date : Dayjs | null, time : Dayjs | null) {
    const params: { Date?: Dayjs } = {};
    if (date != null && time != null) {
        params.Date = date
        .hour(time.hour())
        .minute(time.minute())
        .second(0);
    }
    
    catalogService.get(
        "/temperatures/smallDocument",
        {
            Date: params.Date?.format('YYYY-MM-DD-HH-mm-ss'),
        },
        {
            success: (blob) => {
                const file = new Blob([blob], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            },
            error: (error) => console.error('Error fetching PDF:', error)
        }
    );
}

function generateBigPDF(date2 : Dayjs | null, startTime : Dayjs | null, endTime : Dayjs | null) {
    if (date2 === null || startTime === null || endTime === null) {
        console.error('Date or time value is not set');
        return;
    }
    
    const startDate = date2
    .hour(startTime.hour())
    .minute(startTime.minute())
    .second(0);

    const endDate = date2
    .hour(endTime.hour())
    .minute(endTime.minute())
    .second(0);

    catalogService.get(
    "/temperatures/fullDocument",
    {
        startDate: startDate.format('YYYY-MM-DD-HH-mm-ss'),
        endDate: endDate.format('YYYY-MM-DD-HH-mm-ss')
    },
    {
        success: (blob) => {
            const file = new Blob([blob], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        },
        error: (error) => console.error('Error fetching PDF:', error)
    }
    );
}

export default function TemperatureDocument() {
    const [tabIndex, setIndex] = useState('1');
    
    const [date, setDate] = useState<Dayjs | null>(dayjs());
    const [time, setTime] = useState<Dayjs | null>(dayjs());
    
    const [date2, setDate2] = useState<Dayjs | null>(dayjs());
    const [startTime, setStartTime] = useState<Dayjs | null>(null);
    const [endTime, setEndTime] = useState<Dayjs | null>(null);
    const [error, setError] = useState<boolean>(false);

    const minDate = dayjs("2024-10-23"); //beginning of measurement in database
    
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setIndex(newValue);
      };

    const getSundayOfCurrentWeek = () => dayjs().endOf('week'); // Sunday of the current week  
    const shouldDisableDate = (day: Dayjs) => {
        const sunday = getSundayOfCurrentWeek();
        return day.isAfter(sunday); // Disable any date after Sunday of the current week
    };

    const isDisabled = useMemo(() => {
        if (startTime && endTime) {
            if (endTime.isAfter(startTime)) {
                setError(false);
                return false;
            }
            else {
                setError(true);
                return true;
            }
        }
        return true;
    }, [startTime, endTime]);

    return (
        <TabContext value={tabIndex}>
            <TabList onChange={handleChange}>
                <Tab label="Teplotní doklad - závoz" value="1" />
                <Tab label="Teplotní doklad - kompletní" value="2" />
            </TabList>
  
            <TabPanel value="1">
                <Grid container rowSpacing="5" direction="column" alignItems="left">
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='cs'>
                        <DatePicker
                            label="Den měření teplot"
                            value={date}
                            onChange={(newValue) => setDate(dayjs(newValue))}
                            shouldDisableDate={shouldDisableDate}
                            minDate={minDate}
                        />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='cs'>
                    <TimePicker
                        label="Čas měření"
                        ampm={false}
                        value={time}
                        onChange={(newValue) => setTime(newValue)}
                    />
                    </LocalizationProvider>
                    </Grid>
                    <Grid item>
                        <Button 
                            color='primary' 
                            variant="contained"
                            onClick={() => generateSmallPDF(date, time)}
                            >
                            Generovat PDF dokument
                        </Button>
                    </Grid>
                </Grid>
            </TabPanel>
            
            <TabPanel value="2">
                <Grid container rowSpacing="5" direction="column" alignItems="left">
                    <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='cs'>
                    <DatePicker
                        label="Den měření teplot"
                        value={date2}
                        onChange={(newValue) => setDate2(dayjs(newValue))}
                        shouldDisableDate={shouldDisableDate}
                        minDate={minDate}
                    />
                     </LocalizationProvider>
                     </Grid>

                    <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='cs'>
                    <TimePicker
                        label="Počáteční čas"
                        ampm={false}
                        value={startTime}
                        onChange={(newValue) => setStartTime(newValue)}
                        shouldDisableTime={(timeValue, clockType) => {
                            if (clockType === 'hours') {
                                return timeValue.hour() < 4 || timeValue.hour() > 20;
                            }
                            if (clockType === 'minutes') {
                                const minutes = timeValue.minute();
                                return minutes % 10 !== 0;
                            }
                            return false;
                          }}
                          
                    />
                    </LocalizationProvider>
                    </Grid>
                    <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='cs'>
                    <TimePicker
                        label="Koncový čas"
                        ampm={false}
                        value={endTime}
                        onChange={(newValue) => setEndTime(newValue)}
                        shouldDisableTime={(timeValue, clockType) => {
                            if (clockType === 'hours') {
                                return timeValue.hour() < 4  || timeValue.hour() > 20;
                            }
                            return false;
                          }}
                    />
                    {error && (
                    <FormHelperText error>
                        Koncový čas musí být větší než počáteční.
                    </FormHelperText>
                    )}
                    </LocalizationProvider>
                    </Grid>
                    <Grid item>
                    <Button
                        style={{minWidth: "fitcontent", minHeight: "fit-content"}} 
                        color='primary' 
                        variant="contained" 
                        disabled={isDisabled}
                        onClick={() => generateBigPDF(date, startTime, endTime)}
                        >    
                        Generovat PDF dokument
                    </Button>
                    </Grid>
                </Grid>
            </TabPanel>
        </TabContext>
    )
}