import React, { useEffect, useState } from 'react';
import {
  Button,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Theme,
  Toolbar,
} from '@mui/material';
import { DistributionOrder } from '../Types/order';
import { catalogService, ordersService } from '../Utils/ApiService';
import { useLayout } from '../Layout/LayoutContext';
import { Pagination } from '@mui/material';
import { getKeyExtractorReversedComparer } from '../Utils/Comparers';
import { round, separateThousands, toCzechTime } from '../Utils/Utils';
import { useHistory } from 'react-router-dom';
import { reducers } from '../Utils/Reducers';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pointer: { cursor: 'pointer' },
    right: { float: 'right' },
    search: {
      flexGrow: 1,
      marginLeft: 16,
      marginRight: 16,
    },
  })
);

export default function MMDistroOrdersHistory() {
  const classes = useStyles();

  const [orders, setOrders] = useState<DistributionOrder[]>([]);
  const [childrenOpen, setChildrenOpen] = useState<number[]>([]);
  const [filters] = useState<{
    [index: string]: (prd: DistributionOrder) => boolean;
  }>({});
  const [allProducts, setAllProducts] = useState<IProduct[]>([]);
  //   const [filters, setFilters] = useState<{ [index: string]: (prd: DistributionOrder) => boolean }>({});
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [tab, setTab] = useState<'ordered' | 'notEnough'>('ordered');

  const layout = useLayout();
  const history = useHistory();

  useEffect(() => {
    ordersService.get(
      '/distributionOrder',
      {},
      {
        success: setOrders,
        error: () => layout.error('Při načítání objednávek došlo k chybě'),
      }
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    catalogService.get(
      '/products/all',
      { mapped: true, fields: 'id,supplierTag' },
      {
        success: setAllProducts,
        error: () => layout.error('Při načítání objednávek došlo k chybě'),
      }
    );
    // eslint-disable-next-line
  }, []);

  const rowsPerPage = 25;

  const searchFilter = (o: DistributionOrder) => {
    const s = search.toLowerCase();
    const affectedCols = [o.id?.toString() || ''];
    return affectedCols.reduce((acc, act) => acc || act.toLowerCase().includes(s), false);
  };

  let filteredOrders = orders || [];
  Object.keys(filters).forEach((k) => (filteredOrders = filteredOrders.filter(filters[k])));
  if (search.length > 0) filteredOrders = filteredOrders.filter(searchFilter);

  filteredOrders = filteredOrders.sort(getKeyExtractorReversedComparer('id'));

  return (
    <>
      <Toolbar>
        <Button color="primary" onClick={() => history.push('/orders/ao')}>
          Objednávky z automatického objednávání
        </Button>
        <Button disabled>Objednávky z MM Distribuce</Button>
      </Toolbar>
      <Toolbar>
        {/* <OrdersFilters filters={filters} setFilters={setFilters} /> */}
        <TextField
          variant="standard"
          margin="dense"
          className={classes.search}
          label="vyhledat"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Pagination
          count={Math.ceil(filteredOrders.length / rowsPerPage)}
          showFirstButton
          showLastButton
          page={page}
          onChange={(_, val) => setPage(val)}
          color="primary"
        />
      </Toolbar>
      <br />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Datum vytvoření</TableCell>
            <TableCell>Lékárna</TableCell>
            <TableCell>Počet položek</TableCell>
            <TableCell>Celková cena</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredOrders
            .slice((page - 1) * rowsPerPage, Math.min(rowsPerPage * page, filteredOrders.length))
            .map((o) => (
              <React.Fragment key={o.id || -1}>
                <TableRow
                  className={classes.pointer}
                  onClick={() =>
                    childrenOpen.includes(o.id || 0)
                      ? setChildrenOpen(childrenOpen.filter((ch) => ch !== o.id))
                      : setChildrenOpen([...childrenOpen, o.id || 0])
                  }
                >
                  <TableCell>{o.id}</TableCell>
                  <TableCell>
                    {toCzechTime(new Date(o.createdDate || '')).toLocaleString('cs-CZ')}{' '}
                    {toCzechTime(new Date(o.createdDate || ''))
                      .toLocaleString('cs-CZ', { weekday: 'short' })
                      .toLocaleUpperCase()}
                  </TableCell>
                  <TableCell>{o.place.name}</TableCell>
                  <TableCell>{o.products.length}</TableCell>
                  <TableCell>
                    {separateThousands(round(o.products.map((p) => p.finalPrice).reduce(reducers.sum, 0), 2))} Kč
                  </TableCell>
                </TableRow>
                {childrenOpen.includes(o.id || 0) && (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Tabs value={tab} onChange={(e, val) => setTab(val)}>
                        <Tab value="ordered" label="Objednané produkty" />
                        <Tab value="notEnough" label="Nevykryté produkty" />
                      </Tabs>
                      <br />
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Produkt</TableCell>
                            <TableCell>Objednané množství</TableCell>
                            {tab === 'notEnough' && <TableCell>Nevykryté množství</TableCell>}
                            <TableCell>Cena za ks</TableCell>
                            <TableCell>Finální cena</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {o.products
                            .filter(
                              (p) =>
                                (tab === 'ordered' && p.totalAmount > 0) ||
                                (tab === 'notEnough' && p.unavailableAmount > 0)
                            )
                            .sort((a, b) =>
                              `${
                                allProducts.find((pp) => pp.id === a.productId)?.supplierTag || ''
                              }&${a.productTitle}&${a.productSubtitle}` <
                              `${
                                allProducts.find((pp) => pp.id === b.productId)?.supplierTag || ''
                              }&${b.productTitle}&${b.productSubtitle}`
                                ? -1
                                : 1
                            )
                            .map((p) => (
                              <TableRow key={p.productId}>
                                <TableCell>{p.productId}</TableCell>
                                <TableCell>
                                  {p.productTitle} {p.productSubtitle}
                                  <br />
                                  {allProducts.find((pp) => pp.id === p.productId)?.supplierTag}
                                </TableCell>
                                <TableCell>{p.totalAmount}</TableCell>
                                {tab === 'notEnough' && <TableCell>{p.unavailableAmount}</TableCell>}
                                <TableCell>{separateThousands(round(p.price, 2))} Kč</TableCell>
                                <TableCell>{separateThousands(round(p.finalPrice, 2))} Kč</TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
        </TableBody>
      </Table>
    </>
  );
}
