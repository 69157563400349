import { Button, IconButton, Slider, TextField, Theme, Tooltip, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { reducers } from '../../../Utils/Reducers';
import useMousePosition from '../../../Hooks/Orders/useMousePosition';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import { catalogService } from '../../../Utils/ApiService';
import { useLayout } from '../../../Layout/LayoutContext';
import { IRack, IShelf } from '../../../Types/base';
import AddProductModal from './AddProductModal';
import { ArrowDropDownCircle, Delete } from '@mui/icons-material';
import { getAbsoluteMargins, getBasedRelativeMargins, round, sellingPrice } from '../../../Utils/Utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      textAlign: 'center',
      justifyContent: 'center',
      display: 'flex',
    },
    rack: {
      border: `3px solid ${theme.palette.mode === 'dark' ? '#cccccc' : '#666666'}`,
    },
    shelf: {
      width: '100%',
      borderBottom: `3px solid ${theme.palette.mode === 'dark' ? '#cccccc' : '#666666'}`,
      position: 'relative',
    },
    product: {
      backgroundColor: theme.palette.mode === 'dark' ? '#a3a3a3' : '#424242',
      position: 'absolute',
      cursor: 'pointer',
      bottom: 0,
    },
    draggedProduct: {
      backgroundColor: theme.palette.mode === 'dark' ? '#a3a3a3' : '#424242',
      position: 'fixed',
    },
    addProductButton: {
      backgroundColor: theme.palette.mode === 'dark' ? '#111111' : '#eeeeee',
      position: 'absolute',
      height: '100%',
      bottom: 0,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? '#222222' : '#e3e3e3',
      },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '-webkit-user-select': 'none',
      '-moz-user-select': 'none',
      '-ms-user-select': 'none',
      userSelect: 'none',
    },
    removeArea: {
      border: `3px solid ${theme.palette.mode === 'dark' ? '#cccccc' : '#666666'}`,
      borderRadius: 4,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 60,
      marginTop: 20,
      marginBottom: 20,
      '-webkit-user-select': 'none',
      '-moz-user-select': 'none',
      '-ms-user-select': 'none',
      userSelect: 'none',
    },
  })
);

interface IProps {
  rack: IRack;
  onChange?: (newRack: IRack) => void;
  onRemove?: () => void;
  productToHighlight?: number;
}

export const emptyRack: IRack = {
  id: 0,
  title: '',
  order: 0,
  width: 800,
  depth: 0,
  shelves: [],
};

const defaultProduct: IProduct = { id: 0 }; // TODO
const defaultSize: number = 100;

export default function RackEditor({ onChange, rack: defaultRack, onRemove, productToHighlight }: IProps) {
  const isEditable = onChange !== undefined;

  const [rack, setRack] = useState<IRack>({ ...emptyRack });
  const [draggedProduct, setDraggedProduct] = useState<number | undefined>(undefined);
  const [shelfPosition, setShelfPosition] = useState<{ shelf: number; index: number } | undefined>(undefined);
  const [allProducts, setAllProducts] = useState<IProduct[]>([]);
  const [shelfToAdd, setShelfToAdd] = useState<IShelf | undefined>(undefined);
  const [scaleRatio, setScaleRatio] = useState(parseFloat(localStorage.getItem('rackScaleRatio') || '0.5'));

  const updateScaleRatio = (newRatio: number) => {
    setScaleRatio(newRatio);
    localStorage.setItem('rackScaleRatio', newRatio.toString());
  };

  const classes = useStyles();
  const layout = useLayout();
  const { x: mouseX, y: mouseY } = useMousePosition();

  useEffect(() => {
    setRack({ ...defaultRack });
  }, [defaultRack]);

  useEffect(
    () => {
      if (defaultRack.shelves.flatMap((s) => s.products).length > 0) {
        catalogService.get(
          '/products/noauth/list',
          { mapped: true, publishedOnly: false, ids: defaultRack.shelves.flatMap((s) => s.products) },
          {
            success: setAllProducts,
            error: () => layout.error('Při nacítání produktů došlo k chybě'),
          }
        );
      }
    },
    // eslint-disable-next-line
    [defaultRack]
  );

  const saveChanges = () => {
    catalogService.post('/racks/', {}, rack, {
      success: (r) => {
        onChange && onChange(r);
        layout.success('Změny úspěšně uloženy');
      },
      error: () => layout.error('Při ukládání změn došlo k chybě'),
    });
  };

  const releaseProduct = () => {
    if (!draggedProduct) return;
    if (shelfPosition !== undefined) {
      const newRack = { ...rack };
      newRack.shelves = newRack.shelves.map((shelf) => ({
        ...shelf,
        products: shelf.products.filter((p) => p !== draggedProduct),
      }));
      if (shelfPosition.shelf >= 0)
        newRack.shelves
          .find((s) => s.id === shelfPosition.shelf)
          ?.products.splice(shelfPosition.index, 0, draggedProduct);
      setRack(newRack);
    }
    setDraggedProduct(undefined);
    setShelfPosition(undefined);
  };

  const calculateProductsWidth = (products: number[], maxIndex: number) =>
    products
      .filter((_, i) => i < maxIndex)
      .map((p) => ((allProducts.find((pp) => pp.id === p) || defaultProduct).productWidth || defaultSize) + 2)
      .reduce(reducers.sum, 0) * scaleRatio;

  const calculateShelfMass = (shelf: IShelf) =>
    shelf.products
      .map((p) => allProducts.find((pp) => pp.id === p))
      .map((p) => Math.floor(rack.depth / (p?.productDepth || 99999)) * (p?.productMass || 0))
      .reduce(reducers.sum, 0);

  const generateShelfProducts = (shelf: IShelf) => {
    let productsToGenerate = shelf.products.filter((p) => p !== draggedProduct);
    if (shelfPosition && draggedProduct && shelfPosition.shelf === shelf.id) {
      productsToGenerate.splice(shelfPosition.index, 0, draggedProduct);
    }
    return productsToGenerate.map((product, i) => {
      const fullProduct = allProducts.find((pp) => pp.id === product) || defaultProduct;
      const price =
        (fullProduct.physicalStorePrice || 0) > 0
          ? fullProduct.physicalStorePrice || 0
          : fullProduct.reccomendedPrice || 0;
      return (
        <Tooltip
          title={
            draggedProduct ? (
              ''
            ) : (
              <div>
                <p>
                  <b>
                    {fullProduct.title} {fullProduct.subtitle}
                  </b>
                </p>
                <p>Doporučená cena: {fullProduct.reccomendedPrice} Kč</p>
                <p>Cena v lékárně: {price} Kč</p>
                <p>Cena v lékárně za jednotku: {round(price / (fullProduct.ammount || 1), 2)} Kč</p>
                <p>
                  Marže v lékárně:{' '}
                  {round(getAbsoluteMargins(fullProduct) - sellingPrice(fullProduct) + (price || 0), 2)} Kč,{' '}
                  {getBasedRelativeMargins(fullProduct, price)} %
                </p>
                <p>Produktů za sebou: {Math.floor(rack.depth / (fullProduct.productDepth || 1))}</p>
                <p>Aktivní látky: {fullProduct.activeSubstances?.map((s) => s)?.join(', ')}</p>
                <p>Ctrl + Click pro otevření detailů produktu</p>
              </div>
            )
          }
        >
          <div
            key={product}
            className={classes.product}
            style={{
              width:
                ((allProducts.find((pp) => pp.id === product) || defaultProduct).productWidth || defaultSize) *
                scaleRatio,
              height:
                ((allProducts.find((pp) => pp.id === product) || defaultProduct).productHeight || defaultSize) *
                scaleRatio,
              left: calculateProductsWidth(productsToGenerate, i),
              backgroundColor:
                productToHighlight === product
                  ? 'green'
                  : draggedProduct === product
                  ? 'orange'
                  : (allProducts.find((pp) => pp.id === product)?.productHeight || 0) > shelf.height ||
                    calculateProductsWidth(productsToGenerate, i) +
                      (allProducts.find((pp) => pp.id === product)?.productWidth || 0) * scaleRatio >
                      rack.width * scaleRatio
                  ? 'red'
                  : undefined,
            }}
            onMouseDown={(e) =>
              e.ctrlKey || e.metaKey
                ? window.open('/products/edit/' + product, '_blank')
                : setDraggedProduct(draggedProduct === product ? undefined : product)
            }
          />
        </Tooltip>
      );
    });
  };

  const swapShelves = (idx1: number, idx2: number) => {
    const newShelves = [...rack.shelves];
    const help = { ...newShelves[idx1] };
    newShelves[idx1].height = newShelves[idx2].height;
    newShelves[idx1].remark = newShelves[idx2].remark;
    newShelves[idx1].products = [...newShelves[idx2].products];
    newShelves[idx2].height = help.height;
    newShelves[idx2].remark = help.remark;
    newShelves[idx2].products = [...help.products];
    setRack({ ...rack, shelves: newShelves });
  };

  const addShelf = () => {
    catalogService.post(
      '/racks/shelf',
      {},
      { rackId: rack.id, height: 100, products: [], remark: '' },
      {
        success: (s) => setRack({ ...rack, shelves: [...rack.shelves, s] }),
        error: () => layout.error('Při vytváření police došlo k chybě'),
      }
    );
  };

  const removeCategory = () => {
    catalogService.delete('/racks/', { id: rack.id }, null, {
      success: onRemove || (() => {}),
      error: () => layout.error('Při odstranování kategorie došlo k chybě'),
    });
  };

  const removeShelf = (shelf: IShelf) => {
    catalogService.delete('/racks/shelf', { shelfId: shelf.id }, null, {
      success: () => setRack({ ...rack, shelves: rack.shelves.filter((s) => s.id !== shelf.id) }),
      error: () => layout.error('Při odstranování police došlo k chybě'),
    });
  };

  return (
    <>
      <div className={classes.root}>
        <div>
          {/* {isEditable && (
            <> */}
          <Slider
            step={0.05}
            min={0.1}
            max={3}
            value={scaleRatio}
            valueLabelDisplay="auto"
            marks={[
              { value: 0.1, label: '0.1' },
              { value: 0.25, label: '0.25' },
              { value: 0.5, label: '0.5' },
              { value: 0.75, label: '0.75' },
              { value: 1, label: '1' },
              { value: 1.5, label: '1.5' },
              { value: 2, label: '2' },
              { value: 3, label: '3' },
            ]}
            style={{ width: '30vw', position: 'absolute', transform: 'translateX(-50%)' }}
            onChange={(_, newScaleRatio) => updateScaleRatio(newScaleRatio as number)}
          />
          <br />
          <br />
          <br />
          {/* </> */}
          {/* )} */}
          {isEditable ? (
            <>
              <TextField
                label="Název"
                style={{ width: rack.width * scaleRatio }}
                value={rack.title}
                onChange={(e) => setRack({ ...rack, title: e.target.value })}
              />
              <br />
              <br />
              <TextField
                label="Šířka (mm)"
                type="number"
                style={{ width: rack.width * scaleRatio * 0.45 }}
                value={rack.width}
                onChange={(e) => setRack({ ...rack, width: parseFloat(e.target.value) })}
              />
              <TextField
                label="Hloubka (mm)"
                type="number"
                style={{ width: rack.width * scaleRatio * 0.45, marginLeft: rack.width * scaleRatio * 0.1 }}
                value={rack.depth}
                onChange={(e) => setRack({ ...rack, depth: parseFloat(e.target.value) })}
              />
            </>
          ) : (
            <Typography variant="h6">{rack.title}</Typography>
          )}
          <br />
          <br />
          <br />
          <div className={classes.rack} style={{ width: rack.width * scaleRatio }}>
            {rack.shelves.map((shelf, shelfNumber) => (
              <React.Fragment key={shelf.id}>
                <div
                  key={shelf.id}
                  className={classes.shelf}
                  style={{ height: shelf.height * scaleRatio }}
                  onMouseMove={
                    isEditable
                      ? (e) => {
                          if (draggedProduct !== undefined) {
                            const position = e.clientX - e.currentTarget.getBoundingClientRect().left;
                            const currentProducts = shelf.products.filter((p) => p !== draggedProduct);
                            let index = currentProducts.length;
                            let cumSum = 0;
                            for (let i = 0; i < currentProducts.length; i++) {
                              cumSum +=
                                ((allProducts.find((pp) => pp.id === currentProducts[i]) || defaultProduct)
                                  .productWidth || defaultSize) * scaleRatio;
                              if (cumSum > position) {
                                index = i;
                                break;
                              }
                              cumSum += 2 * scaleRatio;
                            }
                            setShelfPosition({ shelf: shelf.id, index: index });
                          }
                        }
                      : undefined
                  }
                >
                  <span style={{ fontFamily: 'Roboto', position: 'absolute', left: -58, bottom: '40%' }}>
                    {shelfNumber + 1}
                    {isEditable && shelfNumber + 1 !== rack.shelves.length && (
                      <IconButton onClick={() => swapShelves(shelfNumber, shelfNumber + 1)}>
                        <ArrowDropDownCircle />
                      </IconButton>
                    )}
                  </span>
                  {generateShelfProducts(shelf)}
                  {isEditable ? (
                    <>
                      {draggedProduct === undefined &&
                        rack.width - calculateProductsWidth(shelf.products, shelf.products.length) / scaleRatio >
                          30 && (
                          <div
                            className={classes.addProductButton}
                            style={{
                              left: calculateProductsWidth(shelf.products, shelf.products.length),
                              width:
                                rack.width * scaleRatio - calculateProductsWidth(shelf.products, shelf.products.length),
                            }}
                            onClick={() => setShelfToAdd(shelf)}
                            unselectable="on"
                          >
                            +
                          </div>
                        )}
                      <span
                        style={{
                          position: 'absolute',
                          display: 'flex',
                          left: rack.width * scaleRatio + 16,
                          bottom: 32,
                        }}
                      >
                        <TextField
                          style={{ width: 350 }}
                          placeholder="Poznámka"
                          value={shelf.remark || ''}
                          onChange={(e) =>
                            setRack({
                              ...rack,
                              shelves: rack.shelves.map((s) =>
                                s.id === shelf.id ? { ...s, remark: e.target.value } : s
                              ),
                            })
                          }
                        />
                      </span>
                      <span
                        style={{
                          position: 'absolute',
                          display: 'flex',
                          left: rack.width * scaleRatio + 16,
                          bottom: 0,
                          alignItems: 'flex-end',
                        }}
                      >
                        <TextField
                          style={{ width: 100 }}
                          type="number"
                          value={shelf.height}
                          margin="none"
                          onChange={(e) =>
                            setRack({
                              ...rack,
                              shelves: rack.shelves.map((s) =>
                                s.id === shelf.id ? { ...s, height: parseFloat(e.target.value) } : s
                              ),
                            })
                          }
                        />
                        {shelf.products.length === 0 && (
                          <IconButton color="error" onClick={() => removeShelf(shelf)}>
                            <Delete />
                          </IconButton>
                        )}
                        <span style={{ fontFamily: 'Roboto', fontSize: '0.8rem', minWidth: 132 }}>
                          {shelf.products
                            .map((p) => allProducts.find((pp) => pp.id === p)?.productHeight || 0)
                            .reduce(reducers.max, 0)}{' '}
                          mm, {round(calculateShelfMass(shelf) / 1000, 2)} kg
                        </span>
                      </span>
                    </>
                  ) : (
                    <Typography>{shelf.remark}</Typography>
                  )}
                </div>
              </React.Fragment>
            ))}
          </div>
          {isEditable ? (
            <>
              {draggedProduct === undefined ? (
                <>
                  <Button
                    variant="contained"
                    onClick={saveChanges}
                    style={{ width: rack.width * scaleRatio, marginTop: 32 }}
                  >
                    Uložit změny
                  </Button>
                  <br />
                  {rack.id > 0 && (
                    <Button onClick={addShelf} color="primary">
                      Přidat polici
                    </Button>
                  )}
                </>
              ) : (
                <div
                  unselectable="on"
                  className={classes.removeArea}
                  onMouseMove={(e) => setShelfPosition({ shelf: -1, index: 0 })}
                  style={{
                    width: rack.width * scaleRatio,
                    backgroundColor: shelfPosition?.shelf === -1 ? 'red' : undefined,
                  }}
                >
                  Odstranit produkt
                </div>
              )}
              <br />
              <br />
              <Typography textAlign="left">
                Celková výška: {rack.shelves.map((s) => s.height + 18).reduce(reducers.sum, 0) / 1000} m
              </Typography>
              <Typography textAlign="left">
                Celková hmotnost: {rack.shelves.map(calculateShelfMass).reduce(reducers.sum, 0) / 1000} kg
              </Typography>
              <Button
                variant="contained"
                color="error"
                onClick={() => layout.confirm('Opravdu chcete smazat celou kategorii?', '', removeCategory)}
                style={{ width: rack.width * scaleRatio, marginTop: 32 }}
              >
                Smazat celou kategorii
              </Button>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      {draggedProduct !== undefined && isEditable && (
        <div
          className={classes.draggedProduct}
          style={{
            width:
              ((allProducts.find((pp) => pp.id === draggedProduct) || defaultProduct).productWidth || defaultSize) *
              scaleRatio,
            height:
              ((allProducts.find((pp) => pp.id === draggedProduct) || defaultProduct).productHeight || defaultSize) *
              scaleRatio,
            top: mouseY,
            left: mouseX,
          }}
          onMouseUp={releaseProduct}
        />
      )}
      {isEditable ? (
        <AddProductModal
          open={shelfToAdd !== undefined}
          onClose={() => setShelfToAdd(undefined)}
          onSelect={(p) => {
            setRack({
              ...rack,
              shelves: rack.shelves.map((s) =>
                s.id === shelfToAdd?.id ? { ...s, products: [...s.products, p.id] } : s
              ),
            });
            setAllProducts([...allProducts, p]);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
}
