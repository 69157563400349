import React, { useEffect, useState } from 'react';
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
} from '@mui/material';
import { useLayout } from '../Layout/LayoutContext';
import { ToOrderItem } from '../Types/order';
import { ordersService } from '../Utils/ApiService';
import AddToOrderItemModal from './Modals/AddToOrderItem';
import { getKeyExtractorReversedComparer } from '../Utils/Comparers';
import { Delete } from '@mui/icons-material';
import ToOrderItemRespond from './Modals/ToOrderItemRespond';

const notificationMailSettingsKey = 'newToOrderItemMail';

export default function ToOrder() {
  const [itemsToOrder, setItemsToOrder] = useState<ToOrderItem[]>([]);
  const [notificationEmail, setNotificationEmail] = useState('');

  const layout = useLayout();

  useEffect(
    () => {
      ordersService.get(
        '/internal/toOrderItems',
        {},
        {
          success: setItemsToOrder,
          error: () => layout.error('Při načítání produktů k objednání došlo k chybě'),
        }
      );
    },
    //eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      ordersService.get(
        '/settings/',
        { key: notificationMailSettingsKey },
        {
          success: setNotificationEmail,
          error: () => layout.error('Při načítání notifikačního emailu došlo k chybě'),
        }
      );
    },
    //eslint-disable-next-line
    []
  );

  const saveMail = () =>
    ordersService.post('/settings/', { key: notificationMailSettingsKey }, notificationEmail, {
      success: () => layout.success('Email byl úspěšně uložen'),
      error: () => layout.error('Při ukládání mailu došlo k chybě'),
    });

  const deleteItem = (item: ToOrderItem) =>
    layout.deleteConfirm('Smazat produkt', 'Opravdu chcete smazat tento produkt z evidence?', () =>
      ordersService.delete('/internal/toOrderItem', { id: item.id }, null, {
        success: () => setItemsToOrder(itemsToOrder.filter((i) => i.id !== item.id)),
        error: () => layout.error('Při mazání produktu došlo k chybě'),
      })
    );

  return (
    <>
      <div style={{ display: 'flex' }}>
        <AddToOrderItemModal onAdd={(newItem) => setItemsToOrder([...itemsToOrder, newItem])} />
        <span style={{ flexGrow: 1 }}></span>
        <Button
          color="secondary"
          onClick={() =>
            layout.deleteConfirm('Opravdu chcete vymazat celou historii vyhledávání?', '', () =>
              ordersService.delete('/internal/toOrder/all', {}, null, {
                success: () => setItemsToOrder([]),
                error: () => layout.error('Při mazání došlo k chybě'),
              })
            )
          }
        >
          Smazat vše
        </Button>{' '}
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>PDK</TableCell>
            <TableCell>Název produktu</TableCell>
            <TableCell>Dodavatel</TableCell>
            <TableCell>Poznámka</TableCell>
            <TableCell>Odpověď</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...itemsToOrder].sort(getKeyExtractorReversedComparer('createdDate')).map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.productId}</TableCell>
              <TableCell>{item.productFullName}</TableCell>
              <TableCell>{item.supplier}</TableCell>
              <TableCell>{item.remark}</TableCell>
              <TableCell>{item.response}</TableCell>
              <TableCell>
                <ToOrderItemRespond
                  item={item}
                  onSave={(newItem) => setItemsToOrder(itemsToOrder.map((i) => (i.id === newItem.id ? newItem : i)))}
                />
                <IconButton onClick={() => deleteItem(item)} size="large">
                  <Delete color="error" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <br />
      <AddToOrderItemModal onAdd={(newItem) => setItemsToOrder([...itemsToOrder, newItem])} />
      <br />
      <Toolbar>
        <TextField
          variant="standard"
          label="Email pro upozornění"
          value={notificationEmail}
          onChange={(e) => setNotificationEmail(e.target.value)}
        />
        <Button color="primary" onClick={saveMail}>
          Uložit
        </Button>
      </Toolbar>
    </>
  );
}
