import React, { useEffect, useState } from 'react';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { IBaseDiscount, ISelectsConfig } from '@magistrmartin/eshop-frontend-shared';
import { useLayout } from '../../Layout/LayoutContext';
import { catalogService } from '../../Utils/ApiService';
import { IProduct, ICategory } from '@magistrmartin/eshop-frontend-shared';
import SwitchButton from '../../Components/SwitchButton';
import { getKeyExtractorComparer } from '../../Utils/Comparers';
import { reducers } from '../../Utils/Reducers';

interface IProps {
  products: IProduct[];
  discount: IBaseDiscount;
  onSave: (linked: IProduct) => void;
}

export default function Linkproducts({ onSave, discount, products }: IProps) {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<string | undefined>(undefined);
  const [selectsConfig, setSelectsConfig] = useState<ISelectsConfig | undefined>(undefined);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [tab, setTab] = useState('SINGLE');
  const layout = useLayout();

  useEffect(() => {
    layout.setIsLoading(true);
    catalogService.get(
      '/settings/selects/',
      {},
      {
        success: (conf: ISelectsConfig) => {
          layout.setIsLoading(false);
          setSelectsConfig({
            brandmarks: conf.brandmarks.sort(getKeyExtractorComparer('value')),
            characteristics: conf.characteristics.sort(getKeyExtractorComparer('text')),
            manufacturers: conf.manufacturers.sort(getKeyExtractorComparer('value')),
            productSeries: conf.productSeries.sort(getKeyExtractorComparer('value')),
            suppliers: conf.suppliers.sort(getKeyExtractorComparer('name')),
            drugs: conf.drugs.sort(getKeyExtractorComparer('value')),
            units: conf.units.sort(getKeyExtractorComparer('first')),
            simpleForms: conf.simpleForms.sort(getKeyExtractorComparer('name')),
            pharmaGroups: conf.pharmaGroups.sort(getKeyExtractorComparer('value')),
          });
        },
        error: layout.error,
      }
    );
    catalogService.get(
      '/categories/all',
      {},
      {
        success: setCategories,
        error: console.log,
      }
    );
    // eslint-disable-next-line
  }, []);

  const getProductIds = () => {
    const nonRegulatedProducts = products.filter((p) => p.isRegulated !== true);
    return tab === 'SINGLE'
      ? (selected && [selected]) || []
      : tab === 'CATEGORY'
        ? (selected &&
            nonRegulatedProducts
              .filter((p) => p.categories?.map((c) => c.startsWith(selected)).reduce(reducers.or))
              .map((p) => p.id)) ||
          []
        : tab === 'SUPPLIER'
          ? (selected &&
              nonRegulatedProducts.filter((p) => p.supplier?.toString() === selected.toString()).map((p) => p.id)) ||
            []
          : tab === 'MANUFACTURER'
            ? (selected &&
                nonRegulatedProducts
                  .filter((p) => p.manufacturer?.toString() === selected.toString())
                  .map((p) => p.id)) ||
              []
            : tab === 'BRAND'
              ? (selected &&
                  nonRegulatedProducts
                    .filter((p) => p.brandMark?.toString() === selected.toString())
                    .map((p) => p.id)) ||
                []
              : tab === 'SERIES'
                ? (selected &&
                    nonRegulatedProducts
                      .filter((p) => p.series?.toString() === selected.toString())
                      .map((p) => p.id)) ||
                  []
                : [];
  };

  const saveChanges = () => {
    const productIds = getProductIds();
    productIds.length > 0 &&
      catalogService.post('discounts/linkAll', { discountId: discount.id }, productIds, {
        success: () => window.location.reload(),
        error: layout.error,
      });
  };

  const selectOptions =
    tab === 'SINGLE'
      ? products
      : tab === 'CATEGORY'
        ? categories
        : tab === 'SUPPLIER'
          ? selectsConfig?.suppliers || []
          : tab === 'MANUFACTURER'
            ? selectsConfig?.manufacturers || []
            : tab === 'BRAND'
              ? selectsConfig?.brandmarks || []
              : tab === 'SERIES'
                ? selectsConfig?.productSeries || []
                : [];

  const selectKey =
    tab === 'SINGLE'
      ? (prd: IProduct) => prd?.id
      : tab === 'CATEGORY'
        ? (cat: ICategory) => cat?.id
        : tab === 'SUPPLIER'
          ? (tab: any) => tab?.id
          : tab === 'MANUFACTURER'
            ? (tab: any) => tab?.id
            : tab === 'BRAND'
              ? (tab: any) => tab?.id
              : tab === 'SERIES'
                ? (tab: any) => tab?.id
                : () => '';

  const selectValue =
    tab === 'SINGLE'
      ? (prd: IProduct) => `${prd?.title} ${prd?.subtitle}`
      : tab === 'CATEGORY'
        ? (cat: ICategory) => `[${cat?.id}] ${cat?.description}`
        : tab === 'SUPPLIER'
          ? (tab: any) => tab?.name
          : tab === 'MANUFACTURER'
            ? (tab: any) => tab?.value
            : tab === 'BRAND'
              ? (tab: any) => tab?.value
              : tab === 'SERIES'
                ? (tab: any) => tab?.value
                : () => '';

  console.log(selected);

  return (
    <>
      <Button color="primary" onClick={() => setOpen(true)}>
        Napojit produkt
      </Button>
      <Dialog maxWidth="lg" open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Napojit produkt na slevu</DialogTitle>
        <DialogContent>
          <SwitchButton
            data={{
              SINGLE: 'Produkt',
              CATEGORY: 'Kategorie',
              MANUFACTURER: 'Výrobce',
              SUPPLIER: 'Dodavatel na trh',
              BRAND: 'Značka',
              SERIES: 'Produktová řada',
            }}
            value={tab}
            onChange={(val) => {
              setTab(val);
              setSelected(undefined);
            }}
          />
          {/* <Autocomplete
            value={{ id: selected || "", label: selectValue(selected) || "" }}
            onChange={(e, val) => val && setSelected(val.id)}
            options={[...selectOptions.map((o) => ({ id: selectKey(o), label: selectValue(o) })), { id: "", label: "" }]}
            fullWidth
            isOptionEqualToValue={(o, v) => o.id === v.id}
            renderInput={(p) => <TextField {...p} />}
          /> */}
          <Autocomplete
            value={(selectOptions as any[]).find((s) => selectKey(s) === selected) || null}
            onChange={(e, val) => val && setSelected(selectKey(val))}
            options={selectOptions}
            fullWidth
            isOptionEqualToValue={(o, v) => selectKey(o) === selectKey(v)}
            getOptionLabel={(o) => selectValue(o)}
            renderInput={(p) => <TextField {...p} />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button color="primary" disabled={selected === undefined} onClick={saveChanges}>
            Napojit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
