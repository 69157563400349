import React from 'react';
import { TextField, Typography } from '@mui/material';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import { Editor } from '@tinymce/tinymce-react';
import { apiUrl } from '../../Utils/ApiService';

interface IProps {
  product: IProduct;
  changeProduct: (attr: keyof IProduct, val: any) => void;
}

export default function DescriptionEditor({ product, changeProduct }: IProps) {
  return (
    <>
      <TextField
        variant="standard"
        fullWidth
        label="Krátký popis produktu"
        multiline
        value={product?.shortDescription || ''}
        onChange={(e) => changeProduct('shortDescription', e.target.value)}
      />
      <br />
      <br />

      <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
        Popis produktu
      </Typography>
      <br />
      <br />

      <Editor
        apiKey="ygw8t6cqi14winm1hwe2cr6yhal1lvoygowen2ni7qen02yz"
        initialValue={product?.description || ''}
        init={{
          plugins: [
            'advlist autolink lists link image',
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount',
          ],
          toolbar:
            'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help',
          automatic_uploads: true,
          height: 720,
          images_reuse_filename: true,
          images_upload_url: `${apiUrl}images/uploadImage`,
          images_upload_base_path: 'https://api.magistrmartin.cz/images/noauth/image/images/',
        }}
        onBlur={(e) => changeProduct('description', e.target.getContent())}
      />
    </>
  );
}
