import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { Info } from '@mui/icons-material';
import React, { useState } from 'react';
import { SupplierDetails } from '../../Types/base';

interface IProps {
  supplier: SupplierDetails;
}

export default function SupplierDetailsModal({ supplier }: IProps) {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          setOpened(true);
        }}
        size="large"
      >
        <Info />
      </IconButton>
      <Dialog open={opened} onClose={() => setOpened(false)} fullWidth maxWidth="lg">
        <DialogTitle>Detaily dodavatele na trh</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>
                {supplier.supplierName} ({supplier.supplierTag})
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6">Reprezentant</Typography>
              <Typography>
                {supplier.representatntName} {supplier.representantSurname}
              </Typography>
              <Typography>{supplier.representantEmail}</Typography>
              <Typography>{supplier.representantPhone}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6">Eco manager</Typography>
              <Typography>
                {supplier.ecoManagerName} {supplier.ecoManagerSurname}
              </Typography>
              <Typography>{supplier.ecoManagerEmail}</Typography>
              <Typography>{supplier.ecoManagerPhone}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6">Marketingový manager</Typography>
              <Typography>
                {supplier.marketingManagerName} {supplier.marketingManagerSurname}
              </Typography>
              <Typography>{supplier.marketingManagerEmail}</Typography>
              <Typography>{supplier.marketingManagerPhone}</Typography>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setOpened(false)}>
            Zavřít
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
