import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { ILink } from '../FooterSettings';
import { webStructureService } from '../../Utils/ApiService';
import { useLayout } from '../../Layout/LayoutContext';
import { Edit } from '@mui/icons-material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  })
);

interface IProps {
  link?: ILink;
  categoryId?: number;
  onSave: (link: ILink) => void;
}

export default function EditFooterLink({ onSave, link, categoryId }: IProps) {
  const classes = useStyles();
  const [opened, setOpened] = useState(false);
  const [actLink, setActLink] = useState(link || { category: categoryId });
  const layout = useLayout();

  const reset = () => {
    setActLink({});
    setOpened(false);
  };

  const saveLink = () => {
    webStructureService.post(
      '/footer/link',
      { category: actLink.category, id: actLink.id, link: actLink.link || '', name: actLink.name || '' },
      null,
      {
        success: (data: ILink) => {
          onSave(data);
          reset();
        },
        error: layout.error,
      }
    );
  };

  return (
    <>
      {(link && (
        <>
          <IconButton onClick={() => setOpened(true)} aria-label="Edit" size="large">
            <Edit />
          </IconButton>
        </>
      )) || (
        <Button variant="contained" color="primary" onClick={() => setOpened(true)}>
          Přidat odkaz do patičky
        </Button>
      )}

      <Dialog open={opened} onClose={reset}>
        <DialogTitle>Odkaz v patičce</DialogTitle>
        <DialogContent>
          <TextField
            variant="standard"
            fullWidth
            label="Název"
            value={actLink.name || ''}
            onChange={(e) => setActLink({ ...actLink, name: e.target.value })}
          />
          <TextField
            variant="standard"
            fullWidth
            label="Odkaz"
            value={actLink.link || ''}
            onChange={(e) => setActLink({ ...actLink, link: e.target.value })}
          />
          <br />
          <br />
          <Typography variant="caption">
            Pro použití statické stránky zadejte odkaz ve tvaru https://magistrmartin.cz/staticSite/[id], kde [id] je id
            stránky uvedené v jejich přehledu.{' '}
            <u
              className={classes.link}
              onClick={() => setActLink({ ...actLink, link: 'https://magistrmartin.cz/staticSite/' })}
            >
              Pro automatické vložení klikni sem.
            </u>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={reset}>Cancel</Button>
          <Button color="primary" onClick={saveLink}>
            Uložit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
