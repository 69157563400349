import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import { useLayout } from '../Layout/LayoutContext';
import { catalogService, statisticsService } from '../Utils/ApiService';
import { exportSelloutToExcel, importSelloutFromExcel } from '../Utils/SelloutIE';
import { getKeyExtractorComparer, getKeyExtractorReversedComparer } from '../Utils/Comparers';
import { DatePicker } from '@mui/x-date-pickers';
import { Sellout } from '../Types/base';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';

export default function SelloutBonus() {
  const [quarterFrom, setQuarterFrom] = useState<Date>(
    new Date(new Date().getFullYear(), new Date().getMonth() - 3, new Date().getDate())
  );
  const [quarterTo, setQuarterTo] = useState<Date>(new Date());
  const [originalSellouts, setOriginalSellouts] = useState<Sellout[]>([]);
  const [newSellouts, setNewSellouts] = useState<Sellout[]>([]);
  const [allProducts, setAllProducts] = useState<IProduct[]>([]);

  const layout = useLayout();

  const loadSellouts = () => {
    catalogService.get(
      '/sellout/validAt',
      { validityDate: quarterFrom.toISOString().split('T')[0] },
      {
        success: setOriginalSellouts,
        error: () => layout.error('Nepodařilo se načíst původní záznamy selloutů'),
      }
    );
    catalogService.get(
      '/sellout/validAt',
      { validityDate: quarterTo.toISOString().split('T')[0] },
      {
        success: setNewSellouts,
        error: () => layout.error('Nepodařilo se načíst nové záznamy selloutů'),
      }
    );
  };

  useEffect(() => {
    catalogService.get(
      '/products/all',
      { fields: 'id,title,subtitle', full: false, mapped: false },
      {
        success: setAllProducts,
        error: () => console.log,
      }
    );
  }, []);

  const importSellout = (file: File) => {
    layout.setIsLoading(true);
    importSelloutFromExcel(file).then((data) => {
      catalogService.post(
        '/sellout/import',
        {},
        data.rows.map((r) => ({
          id: 0,
          productId: r.id,
          bonus: r.sellout,
          validFrom: r.validityFrom,
          validTo: r.validityTo,
        })),
        {
          success: () => layout.success('Import proběhl úspěšně'),
          error: () => layout.error('Import se nezdařil'),
          finally: () => layout.setIsLoading(false),
        }
      );
    });
  };

  const lowerSellouts = originalSellouts
    .filter((ns) => ns.bonus > (newSellouts.find((os) => os.productId === ns.productId)?.bonus || 0))
    .map((s) => s.productId);
  const higherSellouts = newSellouts
    .filter((ns) => ns.bonus > (originalSellouts.find((os) => os.productId === ns.productId)?.bonus || 0))
    .map((s) => s.productId);
  const equalSellouts = newSellouts
    .filter((ns) => ns.bonus === (originalSellouts.find((os) => os.productId === ns.productId)?.bonus || 0))
    .map((s) => s.productId);

  const addSelloutSales = (file: File) => {
    layout.setIsLoading(true);
    importSelloutFromExcel(file).then((data) => {
      const minDate = data.rows.sort(getKeyExtractorComparer('validityFrom'))[0].validityFrom;
      const maxDate = data.rows.sort(getKeyExtractorReversedComparer('validityTo'))[0].validityTo;
      // @ts-ignore
      const ids = [...new Set(data.rows.map((d) => d.id))];
      statisticsService.post(
        '/totalSalesHistory',
        {
          from: minDate.toISOString().split('T')[0],
          to: maxDate.toISOString().split('T')[0],
        }, // TODO use monthly data
        ids,
        {
          success: (sales: { [index: number]: { [index: string]: number } }) => {
            const dataWithSales = data.rows.map((d) => ({
              ...d,
              sales: (sales[d.id] || {})[3] || 0,
            }));
            exportSelloutToExcel(dataWithSales);
          },
          error: () => layout.error('Při načítání hitorie prodejností došlo k chybě'),
          finally: () => layout.setIsLoading(false),
        }
      );
    });
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">Přehled změn sell-outu mezi kvartály.</Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <DatePicker
            label="Původní kvartál"
            value={quarterFrom}
            onChange={(d) => d && setQuarterFrom(d)}
            views={['year', 'month']}
            slots={{ textField: TextField }}
            slotProps={{ textField: { fullWidth: true } }}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <DatePicker
            label="Nový kvartál"
            value={quarterTo}
            onChange={(d) => d && setQuarterTo(d)}
            views={['year', 'month']}
            slots={{ textField: TextField }}
            slotProps={{ textField: { fullWidth: true } }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Button onClick={loadSellouts}>Načíst data</Button>
        </Grid>
        <Grid item xs={12}>
          {[
            {
              label: 'Produkty, kde došlo ke zvýšení',
              productIds: higherSellouts,
            },
            { label: 'Produkty beze změny', productIds: equalSellouts },
            {
              label: 'Produkty, kde došlo ke snížení',
              productIds: lowerSellouts,
            },
          ]
            .filter(({ productIds }) => productIds.length)
            .map(({ productIds, label }, i) => (
              <React.Fragment key={i}>
                <Typography variant="h6">{label}</Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Id</TableCell>
                      <TableCell>Název</TableCell>
                      <TableCell>Původní sell-out</TableCell>
                      <TableCell>Nový sell-out</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productIds.map((pid) => (
                      <TableRow key={pid}>
                        <TableCell>{pid}</TableCell>
                        <TableCell>
                          {allProducts.filter((p) => p.id === pid).map((p) => `${p.title} ${p.subtitle}`)[0] || pid}
                        </TableCell>
                        <TableCell>{originalSellouts.filter((s) => s.productId === pid)[0]?.bonus || '-'} Kč</TableCell>
                        <TableCell>{newSellouts.filter((s) => s.productId === pid)[0]?.bonus || '-'} Kč</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </React.Fragment>
            ))}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4">Import sell-outu</Typography>
          <br />
          <DropzoneArea
            filesLimit={1}
            maxFileSize={134217728}
            showPreviews={false}
            onChange={(files) => files.length > 0 && importSellout(files[0])}
          />
          <br />
          <Typography variant="caption">
            Web předpokládá soubor s hlavičkou obashující následující názvy sloupců:
            <br />
            <b>Kod produktu | PDK kod | Výrobce | Název produktu | Bonus Kč/ks | Platnost OD | Platnost DO</b>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4">Přidání prodejnosti k reportu sell-outu</Typography>
          <br />
          <DropzoneArea
            filesLimit={1}
            maxFileSize={134217728}
            showPreviews={false}
            onChange={(files) => files.length > 0 && addSelloutSales(files[0])}
          />
        </Grid>
      </Grid>
    </>
  );
}
