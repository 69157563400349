import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Theme } from '@mui/material';
import { useLayout } from '../../Layout/LayoutContext';
import { openFileContext } from '../../Utils/Utils';
import { imagesService } from '../../Utils/ApiService';
import { ArrowLeft, ArrowRight, Delete } from '@mui/icons-material';
import { createStyles, makeStyles } from '@mui/styles';

interface IProps {
  images: string[];
  setImages: (newImage: string[]) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    queueImage: {
      width: '100%',
    },
    flex: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

export default function ProductImagesSettings({ images, setImages }: IProps) {
  const [open, setOpen] = useState(false);
  const layout = useLayout();
  const classes = useStyles();

  const saveFiles = (files?: File[]) => {
    if (files !== undefined && files.length > 0) {
      if (files[0].size >= 1048576) {
        new Compressor(files[0], {
          quality: 0.8,
          convertSize: 1048576,
          success: (result) => {
            layout.warning('Obrázek byl automaticky komprimován, může dojít ke ztrátě kvality.');
            saveFiles([new File([result], files[0].name + '.jpg')]);
          },
          error: (err) => layout.error('Při kompresi došlo k chybě: ' + err.message),
        });
      } else {
        imagesService.uploadFile('uploadAndAdjust', files[0], { location: 'images' }, null, {
          success: (file) => setImages([...images, `https://api.magistrmartin.cz/images/noauth/image/images/${file}`]),
          error: layout.error,
        });
      }
    }
  };

  const swapImages = (i: number, j: number) => {
    const newImages = [...images];
    const tmp = newImages[i + 1];
    newImages[i + 1] = newImages[j + 1];
    newImages[j + 1] = tmp;
    return newImages;
  };

  return (
    <>
      <Button color="primary" onClick={() => setOpen(true)}>
        Fronta výhod
      </Button>
      <Dialog maxWidth="lg" open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Fronta výhod</DialogTitle>
        <DialogContent>
          <Grid container>
            {images
              .filter((_, i) => i > 0)
              .map((img, i) => (
                <Grid item xs={12} md={3} key={img + i}>
                  {img.includes('youtube') ? (
                    <iframe src={img} title={'youtube video: ' + i} />
                  ) : (
                    <img className={classes.queueImage} src={img} alt="" />
                  )}
                  <br />
                  <div className={classes.flex}>
                    <IconButton aria-label="left" onClick={() => i > 0 && setImages(swapImages(i - 1, i))} size="large">
                      <ArrowLeft />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() => setImages(images.filter((_, index) => index !== i + 1))}
                      size="large"
                    >
                      <Delete color="error" />
                    </IconButton>
                    <IconButton
                      aria-label="right"
                      onClick={() => i < images.length - 2 && setImages(swapImages(i, i + 1))}
                      size="large"
                    >
                      <ArrowRight />
                    </IconButton>
                  </div>
                </Grid>
              ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => openFileContext(saveFiles)} color="primary">
            Nahrát nový obrázek
          </Button>
          <Button
            onClick={() =>
              layout.simpleTextForm(
                '',
                'Zadejte odkaz obrázku z webu případně odkaz na video z youtube.',
                'URL',
                (url) => setImages([...images, url])
              )
            }
            color="secondary"
          >
            Přidat odkaz na online obrázek/video
          </Button>
          <Button onClick={() => setOpen(false)}>Zavřít</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
