import React, { useState } from 'react';
import { Collapse, IconButton, ListItem, ListItemAvatar, ListItemText, TextField } from '@mui/material';
import { ICategory } from '@magistrmartin/eshop-frontend-shared';
import { Delete, Edit, ExpandLess, ExpandMore, VisibilityOff } from '@mui/icons-material';
import { catalogService } from '../Utils/ApiService';
import { useLayout } from '../Layout/LayoutContext';
import { reducers } from '../Utils/Reducers';

interface IProps {
  root: ICategory;
  level?: number;
  changeSubcategories: (newSubs: ICategory[]) => void;
  setEditItem: (root: ICategory) => void;
  remove: () => void;
  products: { id: number; categories: string[] }[];
}

export const getProductsCountInCategory = (
  category: ICategory,
  products: { id: number; categories: string[] }[]
): number =>
  products.filter((p) =>
    p.categories
      .map((pc) => pc === category.id + '*' || (pc.startsWith(category.id) && !pc.includes('*')))
      .reduce(reducers.or, false)
  ).length;

function CategoryTreeItem({ root, level, changeSubcategories, remove, setEditItem, products }: IProps) {
  const [open, setOpen] = useState(false);
  const [newSub, setNewSub] = useState('');
  const layout = useLayout();

  const newId = `${root.id}.${(
    '0' +
    root.subCategories
      .map<number>((c) => parseInt(c.id.substring(root.id.length + 1)) + 1)
      .reduce<number>(reducers.max, 1)
      .toString()
  ).slice(-2)}`;

  if (level === undefined) level = 0;

  return (
    <>
      <ListItem style={{ paddingLeft: level * 24 }} button onClick={() => setOpen(!open)}>
        <ListItemAvatar>
          <>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                layout.deleteConfirm(
                  'Opravdu chcete smazat tuto kategorii?',
                  'Podkategorie bude trvale smazána.',
                  remove
                );
              }}
              aria-label="delete"
              size="large"
            >
              <Delete />
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setEditItem(root);
              }}
              aria-label="edit"
              size="large"
            >
              <Edit />
            </IconButton>
            {!root.visible && <VisibilityOff />}
          </>
        </ListItemAvatar>
        <ListItemText
          primary={`${root.description} (${getProductsCountInCategory(root, products)})`}
          secondary={root.id}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {root.subCategories.map((sc, i) => (
          <CategoryTreeItem
            products={products}
            key={sc.id}
            root={sc}
            level={(level as number) + 1}
            changeSubcategories={(newSubs: ICategory[]) => {
              const newRootSubs = [...root.subCategories];
              newRootSubs[i] = { ...newRootSubs[i], subCategories: newSubs };
              changeSubcategories(newRootSubs);
            }}
            remove={() =>
              catalogService.delete('categories/remove', { id: sc.id }, null, {
                success: () => {
                  changeSubcategories(root.subCategories.filter((s) => s.id !== sc.id));
                },
                error: layout.error,
              })
            }
            setEditItem={setEditItem}
          />
        ))}
        <ListItem style={{ paddingLeft: (level + 1) * 24 }}>
          <TextField
            variant="standard"
            label="Přidat kategorii"
            fullWidth
            value={newSub}
            onChange={(e) => setNewSub(e.target.value)}
            onBlur={() => {
              if (newSub.length > 0) {
                catalogService.post(
                  'categories/add',
                  {},
                  {
                    description: newSub,
                    id: newId,
                    parentId: root.id,
                  },
                  {
                    success: () => {
                      setNewSub('');
                      changeSubcategories([
                        ...root.subCategories,
                        {
                          description: newSub,
                          id: newId,
                          subCategories: [],
                          visible: true,
                        },
                      ]);
                    },
                    error: layout.error,
                  }
                );
              }
            }}
          />
        </ListItem>
      </Collapse>
    </>
  );
}

export default CategoryTreeItem;
