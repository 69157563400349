import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Typography, Divider } from '@mui/material';
import { PickupPlace } from '@magistrmartin/eshop-frontend-shared';
import { useLayout } from '../Layout/LayoutContext';
import { ordersService } from '../Utils/ApiService';
import { Cancel, CheckCircle, Delete, Edit, InfoOutlined } from '@mui/icons-material';
import { useHistory } from 'react-router';

export default function Pharmas() {
  const [pickupPlaces, setPickupPlaces] = useState<PickupPlace[]>([]);
  const [openedChildRows, setOpenedChildRows] = useState<number[]>([]);

  const layout = useLayout();
  const history = useHistory();

  useEffect(
    () => {
      ordersService.get(
        'infrastructure/admin/allPickupPlaces',
        {},
        {
          success: setPickupPlaces,
          error: () => layout.error('Při načítání odběrových míst došlo k chybě'),
        }
      );
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <Grid container>
        <Grid item xs={2}>
          <Typography variant="h6">Registrované lékárny</Typography>
        </Grid>
      </Grid>
      
      <br />
      <br />
      <br />
      
      <Grid container alignItems="center" spacing={4}>
          <Grid xs={1}>
          </Grid>
          <Grid xs={2}>
            <Typography align='center'>Název</Typography>
          </Grid>
          <Grid xs={2}>
            <Typography align='center'>Email</Typography>
          </Grid>
          <Grid xs={2}>
            <Typography align='center'>Telefon</Typography>
          </Grid>
          <Grid xs={1}>
            <Typography align='center'>Rezervace eReceptu</Typography>
          </Grid>
          <Grid xs={1}>
            <Typography align='center'>Rezervace lékárna</Typography>
          </Grid>
          <Grid xs={1}>
            <Typography align='center'>MM Distribuce</Typography>
          </Grid>
          <Grid xs={1}>
            <Typography align='center'>Email ověřen</Typography>
          </Grid>
          <Grid xs={1}>
          </Grid>
      </Grid>

      <br />
      <Divider style={{margin:6}} />
      <br />
      <br />

        {pickupPlaces.map((pp) => (
          <React.Fragment key={pp.id}>
            <br />
            <br />
            <Grid container spacing={3}>
                <Grid item xs={1} sx={{padding: 0}} >
                  <IconButton
                    style={{ padding: 0}}
                    onClick={() =>
                      setOpenedChildRows(
                        openedChildRows.includes(pp.id || -1)
                          ? openedChildRows.filter((p) => p !== (pp.id || -1))
                          : [...openedChildRows, pp.id || -1]
                      )
                    }
                    size="large"
                  >
                    <InfoOutlined />
                  </IconButton>
                </Grid>
                <Grid item xs={2} sx={{padding: 0}}>
                  <Typography>{pp.name}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography style={{ wordWrap: "break-word" }}>{pp.mail}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography>{pp.phone}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    aria-label="ERecept"
                    style={{ padding: 0}}
                    onClick={() =>
                      layout.confirm(
                        'Povolit lékárnu v Rezervaci eReceptu.',
                        'Opravdu chcete povolit/zamítnout lékárnu v Rezervaci eReceptu?',
                        () => {
                          ordersService.post(
                            'infrastructure/admin/approveForPrescription',
                            {
                              placeId: pp.id,
                              approved: !pp.approvedForPrescription,
                            },
                            null,
                            {
                              success: () =>
                                setPickupPlaces(
                                  pickupPlaces.map((p) =>
                                    p.id === pp.id
                                      ? {
                                          ...p,
                                          approvedForPrescription: !p.approvedForPrescription,
                                        }
                                      : p
                                  )
                                ),
                              error: () => layout.error('Při ukládání došlo k chybě'),
                            }
                          );
                        }
                      )
                    }
                    size="large"
                  >
                    {pp.approvedForPrescription ? <CheckCircle color="primary" /> : <Cancel color="disabled" />}
                  </IconButton>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    aria-label="EShop"
                    style={{ padding: 0}}
                    onClick={() =>
                      layout.confirm(
                        'Povolit lékárnu v Rezervace lékárna.',
                        'Opravdu chcete povolit/zamítnout lékárnu v Rezervace lékárna?',
                        () => {
                          ordersService.post(
                            'infrastructure/admin/approveForEshop',
                            {
                              placeId: pp.id,
                              approved: !pp.approvedForEshop,
                            },
                            null,
                            {
                              success: () =>
                                setPickupPlaces(
                                  pickupPlaces.map((p) =>
                                    p.id === pp.id
                                      ? {
                                          ...p,
                                          approvedForEshop: !p.approvedForEshop,
                                        }
                                      : p
                                  )
                                ),
                              error: () => layout.error('Při ukládání došlo k chybě'),
                            }
                          );
                        }
                      )
                    }
                    size="large"
                  >
                    {pp.approvedForEshop ? <CheckCircle color="primary" /> : <Cancel color="disabled" />}
                  </IconButton>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    aria-label="Distribution"
                    style={{ padding: 0}}
                    onClick={() =>
                      layout.confirm(
                        'Povolit lékárnu v MM Distribuci',
                        'Opravdu chcete povolit/zamítnout lékárnu v MM Distribuci?',
                        () => {
                          ordersService.post(
                            'infrastructure/admin/approveForDistribution',
                            {
                              placeId: pp.id,
                              approved: !pp.approvedForDistribution,
                            },
                            null,
                            {
                              success: () =>
                                setPickupPlaces(
                                  pickupPlaces.map((p) =>
                                    p.id === pp.id
                                      ? {
                                          ...p,
                                          approvedForDistribution: !p.approvedForDistribution,
                                        }
                                      : p
                                  )
                                ),
                              error: () => layout.error('Při ukládání došlo k chybě'),
                            }
                          );
                        }
                      )
                    }
                    size="large"
                  >
                    {pp.approvedForDistribution ? <CheckCircle color="primary" /> : <Cancel color="disabled" />}
                  </IconButton>
                </Grid>
                <Grid item xs={1}>
                  {pp.mailConfirmed ? <CheckCircle style={{ color: 'green'}} /> : <Cancel color="error" />}
                </Grid>
                <Grid item xs={1}>
                  <IconButton style={{ padding: 0}} onClick={() => history.push(`/pharmas/edit/${pp.id}`)} size="large">
                    <Edit />
                  </IconButton>
                  <IconButton
                    style={{ padding: 0}}
                    onClick={() =>
                      layout.deleteConfirm('Smazat lékárnu', 'Opravdu chcete smazat tuto lékárnu?', () =>
                        ordersService.delete('/infrastructure/admin/pickupPlace', { placeId: pp.id }, null, {
                          success: () => window.location.reload(),
                          error: () => layout.error('Při mazání lékárny došlo k chybě'),
                        })
                      )
                    }
                    size="large"
                  >
                    <Delete color="error" />
                  </IconButton>
                </Grid>
              </Grid>
              <br />
              <br />
              <Divider />

            {openedChildRows.includes(pp.id || -1) && (
              <Grid item xs={6}>
                <Grid container spacing={4}>
                  <Grid item xs={3}>
                    <Typography>
                      <br />
                      <br />
                      <b>Ulice: </b>
                      {pp.street}
                      <br />
                      <b>ČP: </b>
                      {pp.houseNum}
                      <br />
                      <b>PSČ: </b>
                      {pp.zip}
                      <br />
                      <b>Město: </b>
                      {pp.city}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      {pp.openningHoursList.map((oh) => (
                        <React.Fragment key={oh.id}>
                          <b>{oh.dayName}:</b> {oh.value || 'Zavřeno'}
                          <br />
                        </React.Fragment>
                      ))}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </React.Fragment>
        ))}
    </>
  );
}
