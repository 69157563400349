import React, { useEffect, useState } from 'react';
import RackEditor, { emptyRack } from './Components/RackEditor';
import { IRack } from '../../Types/base';
import { useLayout } from '../../Layout/LayoutContext';
import { catalogService } from '../../Utils/ApiService';
import { Button, Grid } from '@mui/material';

export default function CategoryManagement() {
  const [racks, setRacks] = useState<IRack[]>([]);
  const [selectedRackId, setSelectedRackId] = useState(0);

  const layout = useLayout();

  useEffect(
    () => {
      catalogService.get(
        '/racks/all',
        {},
        {
          success: setRacks,
          error: () => layout.error('Při načítání produktů došlo k chybě'),
        }
      );
    },
    // eslint-disable-next-line
    []
  );

  const selectedRack = selectedRackId === -1 ? { ...emptyRack } : racks.find((r) => r.id === selectedRackId);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {racks.map((r, i) => (
            <React.Fragment key={r.id}>
              <Button
                color={selectedRackId === r.id ? 'primary' : 'inherit'}
                size="small"
                style={{ margin: 8 }}
                onClick={(e) => setSelectedRackId(r.id)}
              >
                {i + 1}. {r.title}
              </Button>
            </React.Fragment>
          ))}
          <br />
          <Button variant="contained" onClick={() => setSelectedRackId(-1)}>
            Přidat kategorii
          </Button>
        </Grid>
        <Grid item xs={12}>
          {selectedRack && (
            <RackEditor
              rack={selectedRack}
              onChange={(newRack) => {
                if (selectedRackId === -1) {
                  setRacks([...racks, newRack]);
                  setSelectedRackId(newRack.id);
                } else setRacks(racks.map((r) => (r.id === newRack.id ? { ...newRack } : r)));
              }}
              onRemove={() => {
                setRacks(racks.filter((r) => r.id !== selectedRackId));
                setSelectedRackId(0);
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
