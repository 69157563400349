import * as Excel from 'exceljs';
import { Distributor, PickupPlace, IProduct, IProfile } from '@magistrmartin/eshop-frontend-shared';
import { ExtendedInternalOrder, InternalOrder, InternalOrderedProduct } from '../Types/order';
import { reducers } from './Reducers';
import { addDecimals, manufacturePrice, round, separateThousands } from './Utils';

export interface IMMDistributionOrderedProductInfo {
  product: IProduct;
  onStock: string;
  guess: string;
  distributionStock: string;
  ordered: number;
}

export const createInternalOrder = (
  products: { product: IProduct; amount: number }[],
  place: PickupPlace,
  profile: IProfile,
  distributor: Distributor,
  supplierId: number
) => {
  return {
    id: 0,
    orderedProductsList: [...products]
      .sort((a, b) =>
        (a.product.title || '') < (b.product.title || '') ? -1 : a.product.title === b.product.title ? 0 : 1
      )
      .map((p) => ({
        amount: recalculateAmount(p.product, p.amount),
        id: 0,
        orderId: 0,
        productId: p.product.id,
        action: getSpecials(p.product, p.amount),
        productTitle: p.product.title,
        productSubtitle: p.product.subtitle,
        remark: '',
        extra:
          (getBestRabat(p.product, p.amount)?.rabatExtra || 0) *
          Math.floor(
            recalculateAmount(p.product, p.amount) / (getBestRabat(p.product, p.amount)?.rabatBase || 9999999999)
          ),
        unfulfilled: false,
      })),
    statusChangeHistoryList: [],
    author: `${profile.address?.name} ${profile.address?.surname}`,
    authorMail: profile.email,
    authorPhone: profile.address?.phone,
    createdDate: new Date(),
    dristributor: `${distributor.name} - ${distributor.customerNumber}`,
    pharma: place.name,
    totalPrice: calculateTotalPrice(products),
    supplierId: supplierId,
    newComment: false,
  } as ExtendedInternalOrder;
};

export async function generateExcelOrder(order: InternalOrder, onGenerated: (rawFile: Blob) => void) {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('Objednávka');

  worksheet.getColumn('A').width = 118 / 7.5;
  worksheet.getColumn('B').width = 242 / 7.5;
  worksheet.getColumn('C').width = 88 / 7.5;
  worksheet.getColumn('D').width = 245 / 7.5;

  const headerSize = createHeader(
    order.pharma || '',
    worksheet,
    order.dristributor || '',
    order.author || '',
    order.authorMail || '',
    order.authorPhone || '',
    order.id || 1
  );
  const bodySize = createProductsTable(order.orderedProductsList, headerSize + 1, worksheet);
  createFooter(headerSize + bodySize + 1, order.totalPrice || 0.0, worksheet);

  worksheet.pageSetup.margins = {
    top: 0.3,
    bottom: 0.3,
    left: 0.3,
    right: 0.1,
    footer: 0,
    header: 0,
  };
  toDataUrl('/img/logo.jpg', (x) => {
    worksheet.addImage(workbook.addImage({ base64: x as string, extension: 'jpeg' }), {
      tl: { col: 0, row: 0 },
      ext: { width: 321, height: 92 },
    });
    workbook.xlsx.writeBuffer().then((buf) => onGenerated(new Blob([buf])));
  });
}

export async function generateMMExcelOrder(
  products: IMMDistributionOrderedProductInfo[],
  getProductPrice: (product: IProduct) => number,
  onGenerated: (rawFile: Blob) => void
) {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('Objednávka');

  worksheet.addRow([
    'PKD Produktu',
    'Název produktu',
    'Stav skladu lékárna',
    'Odhadnovaná prodejnost',
    'Stav skaldu MM distribuce',
    'Návrh objednávky',
    'Cena s DPH/ks',
    'Cena celkem',
    'Sleva',
  ]);

  [...products]
    .sort((a, b) =>
      (a.product.title || '') < (b.product.title || '') ? -1 : a.product.title === b.product.title ? 0 : 1
    )
    .forEach(({ product, distributionStock, guess, onStock, ordered }) => {
      worksheet.addRow([
        product.id,
        `${product.title} ${product.subtitle}`,
        `${onStock} ks`,
        `${guess} ks`,
        `${distributionStock} ks`,
        `${ordered} ks`,
        `${addDecimals(separateThousands(getProductPrice(product)), 2) || '-'} Kč`,
        `${addDecimals(separateThousands(round((getProductPrice(product) || 0) * ordered, 2)), 2)} Kč`,
        `${
          getProductPrice(product) < (product.buyingPrice || 0)
            ? `${addDecimals(
                separateThousands(round((product.buyingPrice || 0) - getProductPrice(product), 2)),
                2
              )} Kč, ${round(
                (100 * ((product.buyingPrice || 0) - getProductPrice(product))) / (product.buyingPrice || 1),
                2
              )} %`
            : ''
        }`,
      ]);
    });

  worksheet.pageSetup.margins = {
    top: 0.3,
    bottom: 0.3,
    left: 0.3,
    right: 0.1,
    footer: 0,
    header: 0,
  };
  workbook.xlsx.writeBuffer().then((buf) => onGenerated(new Blob([buf])));
}

const createHeader = (
  place: string,
  worksheet: Excel.Worksheet,
  distributor: string,
  author: string,
  authorMail: string,
  authorPhone: string,
  orderId: number
) => {
  worksheet.addRow(['', '', 'Lékárna:', place]);
  worksheet.addRow(['', '', 'Distributor:', `${distributor}`]);
  worksheet.addRow([]);
  worksheet.addRow(['', '', 'Připravil:', author]);
  worksheet.addRow(['', '', 'Kontakt:', `${authorPhone}, ${authorMail}`]);
  worksheet.addRow([]);
  worksheet.addRow([`Objednávka produktů č. ${orderId}`]);
  worksheet.addRow([]);

  worksheet.mergeCells('A1:B5');
  worksheet.mergeCells('A6:D6');
  worksheet.mergeCells('A7:D8');

  [1, 2, 4, 5].forEach(
    (i) =>
      (worksheet.getCell(`C${i}`).font = {
        bold: true,
        size: 11,
        name: 'Calibri',
      })
  );
  worksheet.getCell('A7').font = {
    bold: true,
    size: 16,
    name: 'Calibri',
    color: { argb: 'FF660066' },
  };

  worksheet.getCell('A7').alignment = {
    horizontal: 'left',
    vertical: 'middle',
    wrapText: true,
  };

  return 8;
};

const createProductsTable = (products: InternalOrderedProduct[], startRow: number, worksheet: Excel.Worksheet) => {
  worksheet.addRow(['PDK produktu', 'Produkt', 'Množství', 'Akce']);

  ['A', 'B', 'C', 'D'].forEach(
    (col) =>
      (worksheet.getCell(`${col}${startRow}`).fill = {
        fgColor: { argb: 'FF660066' },
        type: 'pattern',
        pattern: 'solid',
      })
  );
  ['A', 'B', 'C', 'D'].forEach(
    (col) =>
      (worksheet.getCell(`${col}${startRow}`).font = {
        bold: true,
        size: 11,
        name: 'Calibri',
        color: { argb: 'FFFFFFFF' },
      })
  );

  worksheet.getCell(`A${startRow}`).alignment = {
    horizontal: 'center',
    vertical: 'middle',
    wrapText: true,
  };
  worksheet.getCell(`C${startRow}`).alignment = {
    horizontal: 'center',
    vertical: 'middle',
    wrapText: true,
  };

  products.forEach((p, i) => {
    worksheet.addRow([p.productId.toString(), `${p.productTitle} ${p.productSubtitle}`, p.amount, p.action]);

    worksheet.getCell(`A${startRow + i + 1}`).alignment = {
      horizontal: 'center',
      vertical: 'middle',
      wrapText: true,
    };
    worksheet.getCell(`C${startRow + i + 1}`).alignment = {
      horizontal: 'center',
      vertical: 'middle',
      wrapText: true,
    };

    if (i % 2 === 1) {
      ['A', 'B', 'C', 'D'].forEach(
        (col) =>
          (worksheet.getCell(`${col}${startRow + i + 1}`).fill = {
            fgColor: { argb: 'FFD8D8D8' },
            type: 'pattern',
            pattern: 'solid',
          })
      );
    }
  });

  return 1 + products.length;
};

const createFooter = (startRow: number, totalPrice: number, worksheet: Excel.Worksheet) => {
  worksheet.addRow([
    `Magistr Martin udělal objednávku ${separateThousands(round(totalPrice, 2))} Kč ve výrobních cenách bez DPH.`,
  ]);
  worksheet.addRow([]);
  worksheet.addRow([
    'Děkujeme za vyřízení objednávky. Prosíme o kontrolu akce, jestli souhlasí nastavení u nás v interním systému MM s Vaší aktuální nabídkou. Předejdeme tak nejasnostem a pozdržení naskladnění závozu. Děkujeme za spolupráci.',
  ]);
  worksheet.addRow([]);
  worksheet.addRow([]);
  worksheet.addRow([]);
  worksheet.addRow([]);
  worksheet.addRow([
    'V případě zájmu o navýšení odběrů můžeme spolu individuálně projít možnosti spolupráce zejména v oblasti marketingu. Přehled nejoblíbenějších služeb zasíláme v příloze. Možno domluvit i další služby a včetně nastavení cen (nemusí být striktně formou cen v nabídce, ale i rabatovým ekvivalentem nebo cena navázána na prodeje).',
  ]);
  worksheet.addRow([]);
  worksheet.addRow([]);
  worksheet.addRow([]);
  worksheet.addRow([
    'Postupně zalistováváme i další novinky. V případě zájmu nám můžete zaslat obrázky skrz uschovna.cz nebo wetransfer.com. Přes email posílejte maximálně jednotky položek kvůli velikosti emailové schránky. Děkujeme za pochopení. Dále zasílejte i textové popisky, případně další textové a zejména grafické materiály, které můžeme využít na podporu prodeje. Děkujeme!',
  ]);
  worksheet.addRow([]);
  worksheet.addRow([]);
  worksheet.addRow([]);
  worksheet.addRow([]);

  worksheet.mergeCells(`A${startRow}:D${startRow}`);
  worksheet.mergeCells(`A${startRow + 1}:D${startRow + 1}`);
  worksheet.mergeCells(`A${startRow + 2}:D${startRow + 4}`);
  worksheet.mergeCells(`A${startRow + 5}:D${startRow + 6}`);
  worksheet.mergeCells(`A${startRow + 7}:D${startRow + 10}`);
  worksheet.mergeCells(`A${startRow + 11}:D${startRow + 15}`);

  worksheet.getCell(`A${startRow}`).font = {
    bold: true,
    size: 11,
    name: 'Calibri',
    color: { argb: 'FF660066' },
  };
  worksheet.getCell(`A${startRow + 2}`).font = {
    bold: true,
    size: 11,
    name: 'Calibri',
  };

  worksheet.getCell(`A${startRow}`).alignment = {
    horizontal: 'right',
    vertical: 'top',
    wrapText: true,
  };
  worksheet.getCell(`A${startRow + 2}`).alignment = {
    horizontal: 'left',
    vertical: 'top',
    wrapText: true,
  };
  worksheet.getCell(`A${startRow + 7}`).alignment = {
    horizontal: 'left',
    vertical: 'top',
    wrapText: true,
  };
  worksheet.getCell(`A${startRow + 11}`).alignment = {
    horizontal: 'left',
    vertical: 'top',
    wrapText: true,
  };
};

const toDataUrl = (url: string, callback: (data: string | ArrayBuffer | null) => void) => {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
};

const getSpecials = (prod: IProduct, amount: number) => {
  const res = [];
  if ((prod.tradeDiscount || 0) > 0) res.push(`Sleva ${prod.tradeDiscount} %`);
  // if ((prod.bonification || 0) > 0) res.push(`Bonifikace ${prod.bonification} %`);
  if ((prod.invoiceBonus || 0) > 0) res.push(`Bonus faktura ${prod.invoiceBonus} %`);
  if ((prod.sellout || 0) > 0) res.push(`Sell-out ${prod.sellout} %`);

  const bestRabat = getBestRabat(prod, amount);

  if (bestRabat)
    res.push(
      `Rabat ${bestRabat.rabatBase} + ${bestRabat.rabatFree}${bestRabat.rabatExtra ? ` + ${bestRabat.rabatExtra}` : ''}`
    );
  return res.join(', ');
};

export const recalculateAmount = (prod: IProduct, amount: number) => {
  const rabat = getBestRabat(prod, amount);
  if (rabat === undefined) return amount;

  const base = rabat.rabatBase;
  const free = rabat.rabatExtra + rabat.rabatFree;

  const appliedCount = Math.floor(amount / base);
  return Math.max(appliedCount * base, amount - appliedCount * free);
};

export const getBestRabat = (prod: IProduct, amount: number) => {
  let bestRabat: { rabatBase: number; rabatFree: number; rabatExtra: number; id: number } | undefined = undefined;
  if (prod === undefined) return undefined;
  for (let r of prod.rabats || []) {
    r.rabatBase <= amount &&
      (bestRabat === undefined ||
        (bestRabat.rabatFree + bestRabat.rabatExtra) / bestRabat.rabatBase <
          (r.rabatFree + r.rabatExtra) / r.rabatBase) &&
      (bestRabat = r);
  }
  return bestRabat;
};

const calculateTotalPrice = (products: { product: IProduct; amount: number }[]) =>
  products.map((p) => recalculateAmount(p.product, p.amount) * manufacturePrice(p.product)).reduce(reducers.sum, 0);
