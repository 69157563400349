import React, { useEffect, useState } from 'react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Theme } from '@mui/material';
import { catalogService } from '../Utils/ApiService';
import { useLayout } from '../Layout/LayoutContext';
import { DiscountType, IBaseDiscount } from '@magistrmartin/eshop-frontend-shared';
import SwitchButton from '../Components/SwitchButton';
import DiscountDetailsCard from '../Components/DiscountDetailsCard';
import CreateDiscount from './Modals/CreateDiscount';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import { addProductDiscount, removeProductDiscount } from '../Utils/Utils';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    discountRow: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'lightgrey',
      },
    },
    selectedRow: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    itemRoot: {
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  })
);

export default function Discounts() {
  const classes = useStyles();
  const [allDiscounts, setAllDiscounts] = useState<IBaseDiscount[]>([]);
  const [allProducts, setAllProducts] = useState<IProduct[]>([]);
  const [type, setType] = useState<DiscountType>('FREE_DELIVERY');
  const [discount, setDiscount] = useState<IBaseDiscount | undefined>(undefined);
  const layout = useLayout();

  const allowedDiscountTypes = {
    FREE_DELIVERY: 'Doprava zdarma',
    PRESENT: 'Dárek',
    CENTRAL_PRESENT: 'Centrální dárek',
    MIX: 'Rabat MIX',
    SPECIAL: 'Speciální sleva',
    SECOND_ITEM: 'Sleva na 2. produkt',
    CROSS: 'Křížová sleva',
  };

  const loadDiscounts = () => {
    catalogService.get(
      '/discounts/all',
      {},
      {
        success: setAllDiscounts,
        error: (err) => layout.error('Slevy se nepodařilo načíst: ' + err),
      }
    );
  };

  const loadProducts = () => {
    catalogService.get(
      '/products/all',
      { full: true },
      {
        success: setAllProducts,
        error: (err) => layout.error('Produkty se nepodařilo načíst: ' + err),
      }
    );
  };

  useEffect(() => {
    loadDiscounts();
    loadProducts();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container>
      <Grid className={classes.itemRoot} item xs={12} md={6}>
        <SwitchButton
          data={allowedDiscountTypes}
          value={type}
          onChange={(val) => {
            setType(val as DiscountType);
            setDiscount(undefined);
          }}
        />
        <br />
        <hr />
        <br />
        <br />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Poznámka</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allDiscounts
              .filter((d) => d.type === type)
              .map((d) => (
                <TableRow
                  key={d.id}
                  className={`${classes.discountRow} ${discount?.id === d.id ? classes.selectedRow : ''}`}
                  onClick={() => setDiscount(d)}
                >
                  <TableCell>{d.id}</TableCell>
                  <TableCell>{d.remark}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <CreateDiscount onSave={(d) => setAllDiscounts([...allDiscounts, d])} />
      </Grid>
      <Grid className={classes.itemRoot} item xs={12} md={6}>
        {discount !== undefined && (
          <DiscountDetailsCard
            discount={discount}
            onChange={(changed) => {
              setAllDiscounts(allDiscounts.map((d) => (d.id === changed.id ? changed : d)));
              setDiscount(changed);
            }}
            onDelete={() => {
              setAllDiscounts(allDiscounts.filter((d) => d.id !== discount.id));
              setDiscount(undefined);
            }}
            onUnlink={(unlinkedProd) =>
              setAllProducts(
                allProducts.map((p) => (p.id === unlinkedProd.id ? removeProductDiscount(unlinkedProd, discount) : p))
              )
            }
            onLink={(linkedProd) =>
              setAllProducts(
                allProducts.map((p) => (p.id === linkedProd.id ? addProductDiscount(linkedProd, discount) : p))
              )
            }
            products={allProducts}
          />
        )}
      </Grid>
    </Grid>
  );
}
