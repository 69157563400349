import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useLayout } from '../../Layout/LayoutContext';
import { ExpectedFreeItemsDelivery } from '../../Types/base';
import { ordersService } from '../../Utils/ApiService';

const emptyFreeItemDelivery: ExpectedFreeItemsDelivery = {
  productFullName: '',
  isFromCatalog: false,
  amount: 0,
};

interface IProps {
  onAdd: (newFreeItem: ExpectedFreeItemsDelivery) => void;
}

export default function AddFreItemDeliveryMessageModal({ onAdd }: IProps) {
  const [newFreeItem, setNewFreeItem] = useState<ExpectedFreeItemsDelivery>(emptyFreeItemDelivery);
  const [opened, setOpened] = useState(false);

  const layout = useLayout();

  const saveChanges = () =>
    ordersService.post('/stats/expectedFreeItemsDelivery', {}, newFreeItem, {
      success: (res: ExpectedFreeItemsDelivery) => {
        onAdd(res);
        setOpened(false);
        setNewFreeItem(emptyFreeItemDelivery);
      },
      error: () => layout.error('Při ukládání došlo k chybě'),
    });

  return (
    <>
      <Button color="primary" onClick={() => setOpened(true)}>
        Přidat zprávu do evidence
      </Button>
      <Dialog maxWidth="lg" open={opened} onClose={() => setOpened(false)} fullWidth>
        <DialogTitle title="Přidat produkt pro kusy zdarma" />
        <DialogContent>
          <br />
          <TextField
            variant="standard"
            label="Zpráva"
            fullWidth
            onChange={(e) => setNewFreeItem({ ...newFreeItem, productFullName: e.target.value })}
            value={newFreeItem.productFullName}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setOpened(false)}>
            Zrušit
          </Button>
          <Button color="primary" disabled={(newFreeItem.productFullName || '').length === 0} onClick={saveChanges}>
            Přidat
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
