import { Autocomplete, Button, IconButton, LinearProgress, TextField, Theme, Typography } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { DropzoneArea } from 'material-ui-dropzone';
import React, { useEffect, useState } from 'react';
import LoadingButton from '../Components/LoadingButton';
import { useLayout } from '../Layout/LayoutContext';
import { PharmosScanHistory } from '../Types/base';
import { IProduct, PickupPlace } from '@magistrmartin/eshop-frontend-shared';
import { catalogService, ordersService, statisticsService } from '../Utils/ApiService';
import { exportToExcel } from '../Utils/ProductsExport';
import { IExcelError, importFromExcel } from '../Utils/ProductsImport';
import { round } from '../Utils/Utils';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    importRoot: {
      display: 'flex',
      marginTop: '22px',
    },
    flexGrow: {
      flexGrow: 1,
    },
  })
);

export default function ImportProducts() {
  const [importResult, setImportResult] = useState<{ rows: IProduct[]; errors: IExcelError[] } | undefined>(undefined);
  const [activePharmosScans, setActivePharmosScans] = useState<PharmosScanHistory[]>([]);
  const [showErrors, setShowErrors] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [selectedPlaceId, setSelectedPlaceId] = useState<number>(3);
  const [places, setPlaces] = useState<PickupPlace[]>([]);
  const [fileToImport, setFileToImport] = useState<File | undefined>(undefined);
  const layout = useLayout();
  const classes = useStyles();

  const reset = () => {
    setUploading(false);
    setShowErrors(false);
    setImportResult(undefined);
  };

  useEffect(
    () => {
      refreshActiveScans();
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      ordersService.get(
        '/infrastructure/pickupPlaces',
        {},
        {
          success: setPlaces,
          error: () => layout.error('Při načítání odběrových míst došlo k chybě'),
        }
      );
    },
    // eslint-disable-next-line
    []
  );

  const refreshActiveScans = () => {
    catalogService.get(
      '/pharmos/activeScans',
      {},
      {
        success: setActivePharmosScans,
        error: () => layout.error('Při načítání aktivních scanů z pharmosu došlo k chybě.'),
      }
    );
  };

  return (
    <>
      <Typography variant="h4">Import produktů z excelu</Typography>
      <br />
      <DropzoneArea
        filesLimit={1}
        maxFileSize={134217728}
        showPreviews={false}
        onChange={(files) => files.length > 0 && importFromExcel(files[0]).then(setImportResult)}
      />
      {importResult && (
        <div className={classes.importRoot}>
          <Typography className={classes.flexGrow}>
            Ze souboru bylo načteno {importResult.rows.length} produktů
            {importResult.errors.length > 0 && <>, a nastalo {importResult.errors.length} chyb.</>} Chcete tyto produkty
            skutečně importovat?
          </Typography>
          <Button color="secondary" onClick={() => setShowErrors(!showErrors)}>
            Zobrazit chyby
          </Button>
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={() => {
              setUploading(true);
              catalogService.post('products/import', {}, importResult.rows, {
                success: () => {
                  layout.success('Produkty byly naimportovány v pořádku');
                  reset();
                },
                error: () => {
                  layout.error('Při importu došlo k chybě');
                  reset();
                },
              });
            }}
            label="Importovat"
            loadingLabel="Probíhá import"
            loading={uploading}
          />
        </div>
      )}
      {showErrors && importResult && (
        <ul>
          {importResult.errors.map((e, i) => (
            <li key={i}>
              Řádek: {e.row}, sloupec: {e.column}, chyba: {e.error}, hodnota: {e.value}
            </li>
          ))}
        </ul>
      )}
      <br />
      <Typography variant="h4">Export produktů do excelu</Typography>
      <br />
      <LoadingButton
        color="primary"
        variant="contained"
        onClick={() => {
          setUploading(true);
          catalogService.get(
            '/products/all',
            { full: true },
            {
              success: (products: IProduct[]) => {
                exportToExcel(products);
                setUploading(false);
              },
              error: () => {
                setUploading(false);
                layout.error('Při načítání produktů došlo k chybě');
              },
            }
          );
        }}
        label="Exportovat"
        loadingLabel="Probíhá export"
        loading={uploading}
      />
      <br /> <br />
      <br />
      <Button
        color="secondary"
        variant="contained"
        onClick={() =>
          catalogService.post('/products/removeGarbageCharacters', {}, null, {
            success: () => layout.success('Nežádoucí znaky úspěšně smazány'),
            error: () => layout.error('Při mazání nežádoucích znaků došlo k chybě'),
          })
        }
      >
        Smazat nežádoucí znaky
      </Button>
      <br />
      <br />
      <Typography variant="h4">Import historie projedností</Typography>
      <br />
      <Autocomplete
        value={{
          id: selectedPlaceId,
          label: places.find((p) => p.id === selectedPlaceId)?.name,
        }}
        onChange={(e, val) => val?.id && setSelectedPlaceId(val.id)}
        options={places.map((p) => ({ id: p.id, label: p.name }))}
        fullWidth
        isOptionEqualToValue={(o, v) => o.id === v.id}
        renderInput={(p) => <TextField label="Lékárna" {...p} />}
      />
      <br />
      <DropzoneArea
        filesLimit={1}
        maxFileSize={134217728}
        showPreviews={false}
        onChange={(files) => files.length > 0 && setFileToImport(files[0])}
      />
      <br />
      <Button
        disabled={!selectedPlaceId || !fileToImport}
        color="primary"
        variant="contained"
        onClick={() => {
          if (fileToImport) {
            layout.info('Import historie prodejností byl spuštěn. Tato operace může chvíli trvat.');
            statisticsService.uploadFile('importDataFromFile', fileToImport, { pharmaId: selectedPlaceId }, null, {
              success: () => layout.success('Data byla importována'),
              error: () => layout.error('Při impotu došlo k chybě'),
            });
            setFileToImport(undefined);
          }
        }}
      >
        Importovat historii
      </Button>
      <br />
      <br />
      <Typography variant="h4">Aktivní scan cen produktů z Pharmosu</Typography>
      <br />
      {activePharmosScans.map((s) => (
        <React.Fragment key={s.id}>
          <Typography>Začátek: {new Date(s.startTime + 'Z').toLocaleString('cs-CZ')}</Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography>
              Progres: {s.scanned} z {s.totalToScan} ({round((100 * s.scanned) / s.totalToScan, 2)} %)
            </Typography>
            <IconButton onClick={refreshActiveScans} size="large">
              <Refresh />
            </IconButton>
          </div>
          <LinearProgress
            style={{ width: 200 }}
            color="primary"
            variant="determinate"
            value={(100 * s.scanned) / s.totalToScan}
          />
          <br />
          <Typography>
            Předpokládaný konec:{' '}
            {new Date(
              Date.now() +
                ((+new Date(new Date().toISOString().slice(0, -1)) - +new Date(s.startTime)) *
                  (s.totalToScan - s.scanned)) /
                  s.scanned
            ).toLocaleString('cs-CZ')}
          </Typography>
        </React.Fragment>
      ))}
      {activePharmosScans.length === 0 && <Typography>Momentálně neexistují žádné aktivní scany</Typography>}
      <br />
      <Button
        color="primary"
        onClick={() => {
          catalogService.post('/pharmos/scan', {}, null);
          setTimeout(window.location.reload, 2000);
        }}
      >
        Spustit scan z pharmosu
      </Button>
    </>
  );
}
