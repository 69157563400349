import React from 'react';
import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import { getKeyExtractorComparer } from '../../Utils/Comparers';
import { IProductStamp } from '../EditProduct/NewProduct';
import { HomeConfigType } from './HomeConfig';
import { DatePicker } from '@mui/x-date-pickers';

interface IProps {
  config: HomeConfigType;
  setConfig: (newConifg: HomeConfigType) => void;
  allProducts: IProductStamp[];
}

export default function DailySpecials({ config, setConfig, allProducts }: IProps) {
  return (
    <>
      <Grid container spacing={3}>
        {(config.todaysSpecial || []).map((sp, i) => (
          <React.Fragment key={i}>
            <Grid item xs={3}>
              {allProducts && (
                <Autocomplete
                  value={{
                    id: sp.productId,
                    label:
                      allProducts.filter((p) => p.id === sp.productId).map((p) => `${p.title} ${p.subtitle}`)[0] || '',
                  }}
                  onChange={(e, val) =>
                    val &&
                    setConfig({
                      ...config,
                      todaysSpecial: (config.todaysSpecial || []).map((ss, ii) =>
                        i === ii ? { ...ss, productId: val.id } : ss
                      ),
                    })
                  }
                  options={allProducts
                    .sort(getKeyExtractorComparer('title'))
                    .map((p) => ({ id: p.id, label: `${p.title} ${p.subtitle}` }))}
                  isOptionEqualToValue={(o, v) => o.id === v.id}
                  fullWidth
                  renderInput={(p) => <TextField label="Produkt" {...p} />}
                />
              )}
            </Grid>
            <Grid item xs={3}>
              <DatePicker
                format="yyyy-MM-dd"
                label="Platí dne"
                value={sp.validAt ? new Date(sp.validAt) : null}
                onChange={(date) =>
                  setConfig({
                    ...config,
                    todaysSpecial: (config.todaysSpecial || []).map((ss, ii) =>
                      i === ii ? { ...ss, validAt: date || new Date() } : ss
                    ),
                  })
                }
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant="standard"
                type="number"
                label="Speciální cena"
                value={sp.specialPrice || 0.0}
                onChange={(e) =>
                  setConfig({
                    ...config,
                    todaysSpecial: (config.todaysSpecial || []).map((ss, ii) =>
                      i === ii ? { ...ss, specialPrice: parseFloat(e.target.value) } : ss
                    ),
                  })
                }
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                color="secondary"
                onClick={() =>
                  setConfig({
                    ...config,
                    todaysSpecial: (config.todaysSpecial || []).filter((ss, ii) => i !== ii),
                  })
                }
              >
                Smazat
              </Button>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      <Button
        color="primary"
        onClick={() =>
          setConfig({
            ...config,
            todaysSpecial: [
              ...(config.todaysSpecial || []),
              {
                productId: 0,
                validAt: new Date(),
                specialPrice: 0,
              },
            ],
          })
        }
      >
        Přidat produkt
      </Button>
    </>
  );
}
