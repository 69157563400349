import React, { useState } from 'react';
import { Card, CardContent, CardHeader, Collapse, IconButton, Theme, Typography } from '@mui/material';
import { Delete, ExpandLess, ExpandMore, LinkOff } from '@mui/icons-material';
import styled from 'styled-components';
import { IBaseDiscount } from '@magistrmartin/eshop-frontend-shared';
import { useLayout } from '../Layout/LayoutContext';
import { catalogService } from '../Utils/ApiService';
import EditDiscount from '../Pages/Modals/EditDiscount';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingRight: theme.spacing(2),
    },
  })
);

// @ts-ignore
const StyledHeader = styled(CardHeader)`
  padding: 4px;
`;

interface IProps {
  title: String;
  children?: React.ReactNode;
  discount: IBaseDiscount;
  productId: number;
  onDelete: () => void;
  onUnlink: () => void;
  onEdit: (newDiscount: IBaseDiscount) => void;
}

export default function DiscountCard({ title, children, discount, productId, onDelete, onEdit, onUnlink }: IProps) {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();
  const layout = useLayout();

  const deleteDiscout = () =>
    layout.deleteConfirm(
      'Smazat tuto slevu',
      'Tato sleva bude trvale smazána. V případě, že je použita u více produktů, bude smazána ode všech.',
      () => {
        catalogService.delete('/discounts/', { id: discount.id }, null, {
          success: onDelete,
          error: layout.error,
        });
      }
    );
  const unlinkDiscount = () =>
    layout.deleteConfirm(
      'Smazat odkaz na tuto slevu',
      'Odkaz na tuto slevu bude smazán. Sleva bude dále existovat, ovšem nebude již platná pro aktuální produkt',
      () => {
        catalogService.post('/discounts/unlink', { discountId: discount.id, productId: productId }, null, {
          success: onUnlink,
          error: layout.error,
        });
      }
    );

  return (
    <div className={classes.root}>
      <Card>
        <>
          {
            // @ts-ignore
            <StyledHeader
              subheader={title}
              action={
                <>
                  <EditDiscount editedDiscount={discount} onSave={onEdit} />
                  <IconButton onClick={unlinkDiscount} aria-label="Unlink" size="large">
                    <LinkOff color="error" />
                  </IconButton>
                  <IconButton onClick={deleteDiscout} aria-label="Delter" size="large">
                    <Delete color="error" />
                  </IconButton>
                  <IconButton onClick={() => setExpanded(!expanded)} aria-label="Expand" size="large">
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </>
              }
            />
          }
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography>
                <b>ID slevy: </b>
                {discount.id}
              </Typography>
              {children}
              {discount.validity && discount.validity.from && discount.validity.to && (
                <Typography>
                  <b>Platnost:</b> {discount.validity.from} -&gt; {discount.validity.to}
                </Typography>
              )}
              {discount.remark && discount.remark.length > 0 && (
                <Typography>
                  <b>Poznámka: </b> {discount.remark}
                </Typography>
              )}
            </CardContent>
          </Collapse>
        </>
      </Card>
    </div>
  );
}
