import { Button, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SelloutBonus from './SelloutBonus';
import Bonifications from './Bonifications';
import { IProductToAdd } from '../Types/base';
import { catalogService } from '../Utils/ApiService';
import { useLayout } from '../Layout/LayoutContext';
import { Delete } from '@mui/icons-material';
import BonusesControl from './BonusesControl';

export default function Bonuses() {
  const [tab, setTab] = useState(0);
  const [productsToAdd, setProductsToAdd] = useState<IProductToAdd[]>([]);

  const layout = useLayout();

  useEffect(() => {
    catalogService.get(
      '/products/productsToAdd',
      {},
      {
        success: setProductsToAdd,
        error: console.log,
      }
    );
  }, []);

  const clearProductsToAdd = () => {
    catalogService.delete('/products/clearProductsToAdd', {}, null, {
      success: () => setProductsToAdd([]),
      error: () => layout.error('Při mazání došlo k chybě'),
    });
  };

  const deleteProductToAdd = (id: number) => {
    catalogService.delete('/products/productToAdd', { id: id }, null, {
      success: () => setProductsToAdd(productsToAdd.filter((p) => p.id !== id)),
      error: () => layout.error('Při mazání došlo k chybě'),
    });
  };

  return (
    <>
      <Tabs value={tab} onChange={(_, newValue) => setTab(newValue)}>
        <Tab label="Kontrola bonusů" />
        <Tab label="Bonifikace" />
        <Tab label="Sell-Out" />
      </Tabs>
      {tab === 0 ? (
        <BonusesControl />
      ) : tab === 1 ? (
        <Bonifications
          onImport={(data) => {
            setProductsToAdd([
              ...productsToAdd,
              ...data.filter((d) => productsToAdd.find((p) => p.id === d.id) === undefined),
            ]);
          }}
        />
      ) : (
        <SelloutBonus />
      )}
      {tab > 0 && (
        <>
          <br />
          {productsToAdd.length ? (
            <>
              <Grid container>
                <Grid item xs={11}>
                  <Typography variant="h4">PDK k dodání</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Button
                    color="secondary"
                    onClick={() => layout.confirm('Opravdu chcete vymazat všechny PDK?', '', clearProductsToAdd)}
                  >
                    Smazat vše
                  </Button>
                </Grid>
                {productsToAdd.map((p) => (
                  <Grid item xs={4} md={2} lg={1} key={p.id}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton onClick={() => deleteProductToAdd(p.id)}>
                        <Delete color="error" />
                      </IconButton>
                      <Typography>{p.id}</Typography>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}
