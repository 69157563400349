import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { DiscountType, IBaseDiscount } from '@magistrmartin/eshop-frontend-shared';
import { useLayout } from '../../Layout/LayoutContext';
import SwitchButton from '../../Components/SwitchButton';
import BasicDiscountEditor from '../../Components/BasicDiscountEditor';
import { catalogService } from '../../Utils/ApiService';

interface IProps {
  productId?: number;
  onSave: (dis: IBaseDiscount) => void;
  forbiddenDiscounts?: DiscountType[];
}

export default function CreateDiscount({ onSave, productId, forbiddenDiscounts }: IProps) {
  const allowedDiscountTypes = {
    FREE_DELIVERY: 'Doprava zdarma',
    PRESENT: 'Dárek',
    CENTRAL_PRESENT: 'Centrální dárek',
    MIX: 'Rabat MIX',
    SPECIAL: 'Speciální sleva',
    SECOND_ITEM: 'Sleva na 2. produkt',
    CROSS: 'Křížová sleva',
    BASIC: '...', // TODO: This was added when refactoring shared types, should more investigate and maybe add some better typing
  };

  forbiddenDiscounts?.forEach((fd) => delete allowedDiscountTypes[fd]);

  const [open, setOpen] = useState(false);
  const [discount, setDiscount] = useState<IBaseDiscount>({
    type: Object.keys(allowedDiscountTypes)[0] as DiscountType,
  });
  const layout = useLayout();

  if (Object.keys(allowedDiscountTypes).length === 0) return <></>;

  const saveChanges = () => {
    const url = (productId && '/discounts/createAndLink') || '/discounts/';
    catalogService.post(url, { productId: productId }, discount, {
      success: (id: number) => {
        onSave({ ...discount, id: id });
        setDiscount({ type: 'FREE_DELIVERY' });
        setOpen(false);
      },
      error: layout.error,
    });
  };

  return (
    <>
      <Button color="primary" onClick={() => setOpen(true)}>
        Vytvořit novou slevu
      </Button>
      <Dialog maxWidth="lg" open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Vytvořit slevu</DialogTitle>
        <DialogContent>
          <SwitchButton
            data={allowedDiscountTypes}
            value={discount.type}
            onChange={(val) => setDiscount({ ...discount, type: val as DiscountType })}
          />
          <br />
          <hr />
          <br />
          <br />
          <BasicDiscountEditor discount={discount} setDiscount={setDiscount} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button color="primary" onClick={saveChanges}>
            Vytvořit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
