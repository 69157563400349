import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { Pagination } from '@mui/material';
import { useLayout } from '../Layout/LayoutContext';
import { catalogService } from '../Utils/ApiService';
import { Delete, SaveAlt } from '@mui/icons-material';
import { IProduct, ISubstance } from '@magistrmartin/eshop-frontend-shared';
import ProductsList from './Modals/ProductsList';

interface IProps {
  products: IProduct[];
}

export default function Substances({ products }: IProps) {
  const layout = useLayout();

  const [substances, setSubstances] = useState<ISubstance[]>([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const productsPerPage = 25;

  useEffect(
    () => {
      catalogService.get(
        '/products/substances',
        {},
        {
          success: (subs: ISubstance[]) => setSubstances([...subs, {}]),
          error: (e) => layout.error('Pomocné látky se nepodařilo nahrát [' + e + ']'),
        }
      );
    },
    // eslint-disable-next-line
    []
  );

  const getIndexOfSubstance = (id: number | undefined) =>
    substances
      .map((s, i) => {
        return { id: s.id, index: i };
      })
      .filter((s) => s.id === id)[0]?.index || substances.length;

  const changeSubstanceProp = (
    id: number | undefined,
    newVal: string | boolean | number,
    property: keyof ISubstance
  ) => {
    const i = getIndexOfSubstance(id);
    const newSubstances = [...substances];
    newSubstances[i] = { ...substances[i] };
    newSubstances[i][property] = newVal as any;
    if (i === newSubstances.length - 1) newSubstances.push({});
    setSubstances(newSubstances);
  };

  const saveSubstance = (id: number | undefined) => {
    const i = getIndexOfSubstance(id);
    catalogService.post('/products/substance', {}, substances[i], {
      success: (id: number) => {
        layout.success('Látka byla úspěšně uložena');
        changeSubstanceProp(i, id, 'id');
      },
      error: (e) => layout.error('Při ukládání látky došlo k chybě [' + e + ']'),
    });
  };

  const deleteSubstance = (id: number | undefined) => {
    const i = getIndexOfSubstance(id);
    const deleteFinnish = () => setSubstances(substances.filter((_, index) => index !== i));
    if ((substances[i].id || 0) > 0) {
      layout.deleteConfirm(
        'Smazat pomocnou látku',
        'Opravdu chcete smazat tuto látku? Spolu s látkou budou smazány odkazy ze všech produktů, které na tuto látku ukazují.',
        () =>
          catalogService.delete('/products/substance', { id: substances[i].id }, null, {
            success: deleteFinnish,
            error: (e) => layout.error('Při mazání došlo k chybě [' + e + ']'),
          })
      );
    } else deleteFinnish();
  };

  const filteredSubstances = [
    ...substances.filter(
      (s) =>
        s.name?.toLowerCase().includes(search.toLowerCase()) ||
        s.otherNames?.toLowerCase().includes(search.toLowerCase())
    ),
    {},
  ];

  return (
    <>
      <Typography>Pomocné látky</Typography>
      <Toolbar>
        <TextField
          variant="standard"
          margin="dense"
          style={{
            flexGrow: 1,
            marginRight: 16,
          }}
          label="vyhledat"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Pagination
          color="primary"
          count={Math.ceil(filteredSubstances.length / productsPerPage)}
          page={page + 1}
          onChange={(_, p) => setPage(p - 1)}
        />
      </Toolbar>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Název</TableCell>
            <TableCell>Další názvy</TableCell>
            <TableCell align="center">Bez um. slad.</TableCell>
            <TableCell align="center">Bez um. barv.</TableCell>
            <TableCell align="center">Bez konz.</TableCell>
            <TableCell align="center">Bez cukru</TableCell>
            <TableCell align="center">Bez lepku</TableCell>
            <TableCell align="center">Bez lakt.</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredSubstances
            .slice(page * productsPerPage, Math.min((page + 1) * productsPerPage, filteredSubstances.length))
            .map((s, i) => (
              <TableRow key={i}>
                <TableCell>{s.id}</TableCell>
                <TableCell>
                  <TextField
                    variant="standard"
                    value={s.name || ''}
                    onChange={(e) => changeSubstanceProp(s.id, e.target.value, 'name')}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="standard"
                    value={s.otherNames || ''}
                    onChange={(e) => changeSubstanceProp(s.id, e.target.value, 'otherNames')}
                  />
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={s.noSweeteners || false}
                    onChange={() => changeSubstanceProp(s.id, !s.noSweeteners, 'noSweeteners')}
                  />
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={s.noColoring || false}
                    onChange={() => changeSubstanceProp(s.id, !s.noColoring, 'noColoring')}
                  />
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={s.noConservants || false}
                    onChange={() => changeSubstanceProp(s.id, !s.noConservants, 'noConservants')}
                  />
                </TableCell>

                <TableCell align="center">
                  <Checkbox
                    checked={s.noSugar || false}
                    onChange={() => changeSubstanceProp(s.id, !s.noSugar, 'noSugar')}
                  />
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={s.noGluten || false}
                    onChange={() => changeSubstanceProp(s.id, !s.noGluten, 'noGluten')}
                  />
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={s.noLactose || false}
                    onChange={() => changeSubstanceProp(s.id, !s.noLactose, 'noLactose')}
                  />
                </TableCell>
                <TableCell>
                  <ProductsList products={products.filter((p) => p.substances?.map((ss) => ss.id).includes(s.id))} />
                  <IconButton
                    onClick={() => saveSubstance(s.id)}
                    color="primary"
                    aria-label="save substance"
                    size="large"
                  >
                    <SaveAlt />
                  </IconButton>
                  {i + productsPerPage * page < filteredSubstances.length - 1 && (
                    <IconButton
                      onClick={() => deleteSubstance(s.id)}
                      color="secondary"
                      aria-label="delete substance"
                      size="large"
                    >
                      <Delete />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Pagination
        color="primary"
        count={Math.ceil(filteredSubstances.length / productsPerPage)}
        page={page + 1}
        onChange={(_, p) => setPage(p - 1)}
      />
    </>
  );
}
