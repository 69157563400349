import iconv from 'iconv-lite';

export const readFile = (file: File, onRead: (content: string) => void) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    onRead(event?.target?.result?.toString() || '');
  };
  reader.readAsText(file, 'Windows-1250');
};

export const reformatXml = (file: File) => {
  readFile(file, (contentRaw) => {
    const content = contentRaw.split('\n');
    const res: string[] = [];
    let lastIdsIdx = -1;
    content.forEach((line) => {
      if (line.includes('<typ:ids>')) {
        res.push('');
        lastIdsIdx = res.length - 1;
      } else {
        if (line.includes('<typ:dic>')) {
          if (line.includes('</typ:dic>')) {
            res[lastIdsIdx] = '          <typ:ids>UD</typ:ids>';
          } else {
            res[lastIdsIdx] = '          <typ:ids>UDA5</typ:ids>';
          }
        }
        res.push(line);
      }
      downloadFile(res.join('\n'), 'text/xml', file.name);
    });
  });
};

export const downloadFile = (content: string, mimeType: string, filename: string, encoding?: string) => {
  const a = document.createElement('a');
  const blob = new Blob([encoding ? iconv.encode(content, encoding) : content], { type: mimeType });
  const url = URL.createObjectURL(blob);
  a.setAttribute('href', url);
  a.setAttribute('download', filename);
  a.click();
};
