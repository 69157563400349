import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { FilterList } from '@mui/icons-material';
import React, { useState } from 'react';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';

interface IProps {
  filters: { [index: string]: (prod: IProduct) => boolean };
  setFilters: (filters: { [index: string]: (prod: IProduct) => boolean }) => void;
}

const myFilters = {
  imagesOnly: (prod: IProduct) => (prod.images && prod.images.filter((i) => i.length > 0).length > 0) || false,
  noImagesOnly: (prod: IProduct) => prod.images === undefined || prod.images.filter((i) => i.length > 0).length === 0,
  publishedOnly: (prod: IProduct) => prod.published || false,
  notPublishedOnly: (prod: IProduct) => !prod.published,
  obsoleteOnly: (prod: IProduct) => prod.isObsolete || false,
  notObsoleteOnly: (prod: IProduct) => !prod.isObsolete,
  discountOnly: (prod: IProduct) =>
    (prod.tradeDiscount || 0) > 0 ||
    (prod.buyingRabatBase || 0) > 0 ||
    (prod.invoiceBonus || 0) > 0 ||
    (prod.bonification || 0) > 0 ||
    (prod.sellout || 0) > 0 ||
    false,
  noDiscountOnly: (prod: IProduct) => !myFilters.discountOnly(prod),
  heurekaBest: (prod: IProduct) => prod.heurekaStatus === 'best' && prod.isObsolete !== true,
  heurekaPaired: (prod: IProduct) => prod.heurekaStatus === 'paired' && prod.isObsolete !== true,
  heurekaUnpaired: (prod: IProduct) =>
    prod.heurekaStatus === undefined || (prod.heurekaStatus === 'unpaired' && prod.isObsolete !== true),
  priceChangedOnly: (prod: IProduct) => prod.lastScanbuyingPriceChange || false,
  centralDistributionOnly: (prod: IProduct) => (prod.centralDistribution || 0) === 1,
  individualPriceOnly: (prod: IProduct) => prod.doNotScann || false,
  notConnectedOnly: (prod: IProduct) => prod.vatRate === null || prod.vatRate === undefined,
  emptyStockOnly: (prod: IProduct) => prod.stockAmmount === 0,
  regulatedPriceOnly: (prod: IProduct) => prod.isRegulated === true,
  packageChangedOnly: (prod: IProduct) => prod.packageChanged === true,
  customHeurekaPrice: (prod: IProduct) => (prod.customHeurekaPrice || 0) > 0,
  noFilledSizesOnly: (prod: IProduct) =>
    (prod.productMass || 0) === 0 ||
    (prod.productHeight || 0) === 0 ||
    (prod.productWidth || 0) === 0 ||
    (prod.productDepth || 0) === 0 ||
    (prod.maxExpirationLength || 0) === 0,
  prefOne: (prod: IProduct) => prod.preferention === 1,
  prefTwo: (prod: IProduct) => prod.preferention === 2,
  prefThree: (prod: IProduct) => (prod.preferention || 3) === 3,
  mainOnly: (prod: IProduct) => prod.isMain === true,
  noMainOnly: (prod: IProduct) => (prod.isMain || false) === false,
  monthNonScanned: (prod: IProduct) =>
    prod.lastPharmosScann !== undefined &&
    prod.isObsolete !== true &&
    new Date(prod.lastPharmosScann).getTime() <
      new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate()).getTime(),
  unavailableOnly: (prod: IProduct) => prod.unavailable === true,
  availableOnly: (prod: IProduct) => prod.unavailable !== true,
};

export const serializeActiveFilters = (filters: { [index: string]: (prod: IProduct) => boolean }) => {
  return Object.keys(filters);
};

export const deserializeActiveFilters = (rawFilters: string[]) => {
  const res: { [index: string]: (prod: IProduct) => boolean } = {};
  rawFilters.forEach((f) => {
    // @ts-ignore
    if (Object.keys(myFilters).includes(f)) res[f] = myFilters[f];
  });
  return res;
};

export default function ProductsFilters({ filters, setFilters }: IProps) {
  const [open, setOpen] = useState(false);

  const toggleFilter = (name: string, toRemove: string[], func?: (prod: IProduct) => boolean) => {
    const newFilters = { ...filters };
    if (!Object.keys(filters).includes(name)) {
      toRemove.forEach((tr) => delete newFilters[tr]);
      if (func !== undefined) newFilters[name] = func;
    }
    setFilters(newFilters);
  };

  return (
    <>
      <IconButton aria-label="filters" onClick={() => setOpen(true)} size="large">
        <FilterList />
      </IconButton>
      <Dialog open={open} maxWidth="lg" fullWidth onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle>Nastavit filtry</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Obrázky</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => toggleFilter('imagesOnly', ['noImagesOnly'], myFilters.imagesOnly)}
                        color={filters['imagesOnly'] === undefined ? 'inherit' : 'primary'}
                      >
                        Vyplněný
                      </Button>
                      <Button
                        onClick={() => toggleFilter('noImagesOnly', ['imagesOnly'], myFilters.noImagesOnly)}
                        color={filters['noImagesOnly'] === undefined ? 'inherit' : 'primary'}
                      >
                        Nevyplněný
                      </Button>
                      <Button
                        onClick={() => toggleFilter('', ['imagesOnly', 'noImagesOnly'])}
                        color={
                          filters['noImagesOnly'] === undefined && filters['imagesOnly'] === undefined
                            ? 'primary'
                            : 'inherit'
                        }
                      >
                        Oboje
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Preference</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => toggleFilter('prefOne', ['prefTwo', 'prefThree'], myFilters.prefOne)}
                        color={filters['prefOne'] === undefined ? 'inherit' : 'primary'}
                      >
                        1
                      </Button>
                      <Button
                        onClick={() => toggleFilter('prefTwo', ['prefOne', 'prefThree'], myFilters.prefTwo)}
                        color={filters['prefTwo'] === undefined ? 'inherit' : 'primary'}
                      >
                        2
                      </Button>
                      <Button
                        onClick={() => toggleFilter('prefThree', ['prefOne', 'prefTwo'], myFilters.prefThree)}
                        color={filters['prefThree'] === undefined ? 'inherit' : 'primary'}
                      >
                        3
                      </Button>
                      <Button
                        onClick={() => toggleFilter('', ['prefOne', 'prefTwo', 'prefThree'])}
                        color={
                          filters['prefOne'] === undefined &&
                          filters['prefTwo'] === undefined &&
                          filters['prefThree'] === undefined
                            ? 'primary'
                            : 'inherit'
                        }
                      >
                        Vše
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Zastaralý</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => toggleFilter('obsoleteOnly', ['notObsoleteOnly'], myFilters.obsoleteOnly)}
                        color={filters['obsoleteOnly'] === undefined ? 'inherit' : 'primary'}
                      >
                        Zastaralé
                      </Button>
                      <Button
                        onClick={() => toggleFilter('notObsoleteOnly', ['obsoleteOnly'], myFilters.notObsoleteOnly)}
                        color={filters['notObsoleteOnly'] === undefined ? 'inherit' : 'primary'}
                      >
                        Nezastaralé
                      </Button>
                      <Button
                        onClick={() => toggleFilter('', ['obsoleteOnly', 'notObsoleteOnly'])}
                        color={
                          filters['obsoleteOnly'] === undefined && filters['notObsoleteOnly'] === undefined
                            ? 'primary'
                            : 'inherit'
                        }
                      >
                        Oboje
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Sleva</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => toggleFilter('discountOnly', ['noDiscountOnly'], myFilters.discountOnly)}
                        color={filters['discountOnly'] === undefined ? 'inherit' : 'primary'}
                      >
                        Vyplněna
                      </Button>
                      <Button
                        onClick={() => toggleFilter('noDiscountOnly', ['discountOnly'], myFilters.noDiscountOnly)}
                        color={filters['noDiscountOnly'] === undefined ? 'inherit' : 'primary'}
                      >
                        Nevyplněna
                      </Button>
                      <Button
                        onClick={() => toggleFilter('', ['discountOnly', 'noDiscountOnly'])}
                        color={
                          filters['noDiscountOnly'] === undefined && filters['discountOnly'] === undefined
                            ? 'primary'
                            : 'inherit'
                        }
                      >
                        Oboje
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <FormControlLabel
                        control={<Checkbox color="primary" checked={filters['noFilledSizesOnly'] !== undefined} />}
                        label="Zobrazit pouze produkty s nevyplněnou informací o rozměrech"
                        onClick={() =>
                          filters['noFilledSizesOnly'] === undefined
                            ? toggleFilter('noFilledSizesOnly', [''], myFilters.noFilledSizesOnly)
                            : toggleFilter('', ['noFilledSizesOnly'])
                        }
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <FormControlLabel
                        control={
                          <Checkbox color="primary" checked={filters['centralDistributionOnly'] !== undefined} />
                        }
                        label="Zobrazit pouze produkty s centrální distribucí"
                        onClick={() =>
                          filters['centralDistributionOnly'] === undefined
                            ? toggleFilter('centralDistributionOnly', [''], myFilters.centralDistributionOnly)
                            : toggleFilter('', ['centralDistributionOnly'])
                        }
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <FormControlLabel
                        control={<Checkbox color="primary" checked={filters['individualPriceOnly'] !== undefined} />}
                        label="Zobrazit pouze produkty s individuální cenou"
                        onClick={() =>
                          filters['individualPriceOnly'] === undefined
                            ? toggleFilter('individualPriceOnly', [''], myFilters.individualPriceOnly)
                            : toggleFilter('', ['individualPriceOnly'])
                        }
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <FormControlLabel
                        control={<Checkbox color="primary" checked={filters['monthNonScanned'] !== undefined} />}
                        label="Zobrazit pouze produkty které nebyly přes měsíc načteny z Pharmosu"
                        onClick={() =>
                          filters['monthNonScanned'] === undefined
                            ? toggleFilter('monthNonScanned', [''], myFilters.monthNonScanned)
                            : toggleFilter('', ['monthNonScanned'])
                        }
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <FormControlLabel
                        control={<Checkbox color="primary" checked={filters['customHeurekaPrice'] !== undefined} />}
                        label="Zobrazit pouze produkty s ruční cenou pro Heuréku"
                        onClick={() =>
                          filters['customHeurekaPrice'] === undefined
                            ? toggleFilter('customHeurekaPrice', [''], myFilters.customHeurekaPrice)
                            : toggleFilter('', ['customHeurekaPrice'])
                        }
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} md={6}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Publikovaný na webu</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => toggleFilter('publishedOnly', ['notPublishedOnly'], myFilters.publishedOnly)}
                        color={filters['publishedOnly'] === undefined ? 'inherit' : 'primary'}
                      >
                        Publikovaný
                      </Button>
                      <Button
                        onClick={() => toggleFilter('notPublishedOnly', ['publishedOnly'], myFilters.notPublishedOnly)}
                        color={filters['notPublishedOnly'] === undefined ? 'inherit' : 'primary'}
                      >
                        Nepublikovaný
                      </Button>
                      <Button
                        onClick={() => toggleFilter('', ['publishedOnly', 'notPublishedOnly'])}
                        color={
                          filters['publishedOnly'] === undefined && filters['notPublishedOnly'] === undefined
                            ? 'primary'
                            : 'inherit'
                        }
                      >
                        Oboje
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Hlavní/vedlejší</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => toggleFilter('mainOnly', ['noMainOnly'], myFilters.mainOnly)}
                        color={filters['mainOnly'] === undefined ? 'inherit' : 'primary'}
                      >
                        Pouze hlavní
                      </Button>
                      <Button
                        onClick={() => toggleFilter('noMainOnly', ['mainOnly'], myFilters.noMainOnly)}
                        color={filters['noMainOnly'] === undefined ? 'inherit' : 'primary'}
                      >
                        Pouze vedlejší
                      </Button>
                      <Button
                        onClick={() => toggleFilter('', ['mainOnly', 'noMainOnly'])}
                        color={
                          filters['noMainOnly'] === undefined && filters['mainOnly'] === undefined
                            ? 'primary'
                            : 'inherit'
                        }
                      >
                        Oboje
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Spárování s heurekou</TableCell>
                    <TableCell>
                      <Button
                        onClick={() =>
                          toggleFilter('heurekaBest', ['heurekaPaired', 'heurekaUnpaired'], myFilters.heurekaBest)
                        }
                        color={filters['heurekaBest'] === undefined ? 'inherit' : 'primary'}
                      >
                        Nejlevnější
                      </Button>
                      <Button
                        onClick={() =>
                          toggleFilter('heurekaPaired', ['heurekaBest', 'heurekaUnpaired'], myFilters.heurekaPaired)
                        }
                        color={filters['heurekaPaired'] === undefined ? 'inherit' : 'primary'}
                      >
                        Dražší
                      </Button>
                      <Button
                        onClick={() =>
                          toggleFilter('heurekaUnpaired', ['heurekaBest', 'heurekaPaired'], myFilters.heurekaUnpaired)
                        }
                        color={filters['heurekaUnpaired'] === undefined ? 'inherit' : 'primary'}
                      >
                        Nespárováno
                      </Button>
                      <Button
                        onClick={() => toggleFilter('', ['heurekaBest', 'heurekaPaired', 'heurekaUnpaired'])}
                        color={
                          filters['heurekaBest'] === undefined &&
                          filters['heurekaPaired'] === undefined &&
                          filters['heurekaUnpaired'] === undefined
                            ? 'primary'
                            : 'inherit'
                        }
                      >
                        Vše
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Výpadek</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => toggleFilter('unavailableOnly', ['availableOnly'], myFilters.unavailableOnly)}
                        color={filters['unavailableOnly'] === undefined ? 'inherit' : 'primary'}
                      >
                        Ano
                      </Button>
                      <Button
                        onClick={() => toggleFilter('availableOnly', ['unavailableOnly'], myFilters.availableOnly)}
                        color={filters['availableOnly'] === undefined ? 'inherit' : 'primary'}
                      >
                        Ne
                      </Button>
                      <Button
                        onClick={() => toggleFilter('', ['unavailableOnly', 'availableOnly'])}
                        color={
                          filters['availableOnly'] === undefined && filters['unavailableOnly'] === undefined
                            ? 'primary'
                            : 'inherit'
                        }
                      >
                        Oboje
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <FormControlLabel
                        control={<Checkbox color="primary" checked={filters['notConnectedOnly'] !== undefined} />}
                        label="Zobrazit pouze produkty, které nejsou napojeny na lekis"
                        onClick={() =>
                          filters['notConnectedOnly'] === undefined
                            ? toggleFilter('notConnectedOnly', [''], myFilters.notConnectedOnly)
                            : toggleFilter('', ['notConnectedOnly'])
                        }
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <FormControlLabel
                        control={<Checkbox color="primary" checked={filters['regulatedPriceOnly'] !== undefined} />}
                        label="Zobrazit pouze produkty s regulovanou cenou"
                        onClick={() =>
                          filters['regulatedPriceOnly'] === undefined
                            ? toggleFilter('regulatedPriceOnly', [''], myFilters.regulatedPriceOnly)
                            : toggleFilter('', ['regulatedPriceOnly'])
                        }
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <FormControlLabel
                        control={<Checkbox color="primary" checked={filters['packageChangedOnly'] !== undefined} />}
                        label="Zobrazit pouze produkty, u kterých došlo ke změně obalu"
                        onClick={() =>
                          filters['packageChangedOnly'] === undefined
                            ? toggleFilter('packageChangedOnly', [''], myFilters.packageChangedOnly)
                            : toggleFilter('', ['packageChangedOnly'])
                        }
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <FormControlLabel
                        control={<Checkbox color="primary" checked={filters['emptyStockOnly'] !== undefined} />}
                        label="Zobrazit pouze produkty s 0 kusy na skladě"
                        onClick={() =>
                          filters['emptyStockOnly'] === undefined
                            ? toggleFilter('emptyStockOnly', [''], myFilters.emptyStockOnly)
                            : toggleFilter('', ['emptyStockOnly'])
                        }
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpen(false)}>
            Zavřít
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
