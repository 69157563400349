import React, { useState } from 'react';
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { List as ListIcon } from '@mui/icons-material';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';

interface IProps {
  products: IProduct[];
}

export default function ProductsList({ products }: IProps) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton onClick={() => setOpen(true)} aria-label="products" size="large">
        <ListIcon />
      </IconButton>
      <Dialog maxWidth="lg" open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Seznam produktů</DialogTitle>
        <DialogContent>
          <List>
            {products.map((p) => (
              <ListItem key={p.id}>
                <ListItemText primary={p.title} secondary={p.subtitle} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setOpen(false)}>
            Zvařít
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
