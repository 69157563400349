import React, { useEffect, useState } from 'react';
import { BonusContoRecordState, BonusContoRecordType, IBonusContoRecord } from '../Types/base';
import { catalogService } from '../Utils/ApiService';
import { Chip, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { separateThousands } from '../Utils/Utils';
import { Cancel, Check, QuestionMark, TrendingDown, TrendingUpSharp } from '@mui/icons-material';

function TypeBadge({ type }: { type: BonusContoRecordType }) {
  return (
    <Chip
      label={type === 'Write-off' ? 'Odpis' : 'Přípis'}
      color={type === 'Write-off' ? 'error' : 'success'}
      icon={type === 'Write-off' ? <TrendingDown /> : <TrendingUpSharp />}
    />
  );
}

function StatusBadge({ status }: { status: BonusContoRecordState }) {
  return (
    <Chip
      label={status === 'correct' ? 'Správně' : status === 'incorrect' ? 'Nesprávně' : 'Nekontrolováno'}
      color={status === 'correct' ? 'success' : status === 'incorrect' ? 'error' : 'default'}
      icon={status === 'correct' ? <Check /> : status === 'incorrect' ? <Cancel /> : <QuestionMark />}
    />
  );
}

export default function BonusesControl() {
  const [data, setData] = useState<IBonusContoRecord[]>([]);

  useEffect(() => {
    catalogService.get(
      '/bonusConto/list',
      {},
      {
        success: setData,
        error: console.log,
      }
    );
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <br />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Datum</TableCell>
            <TableCell>Typ</TableCell>
            <TableCell>Název</TableCell>
            <TableCell>Procenta</TableCell>
            <TableCell>Hodnota</TableCell>
            <TableCell>Předpokládaná hodnota</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((d) => (
            <TableRow key={d.id}>
              <TableCell>{new Date(d.date).toLocaleDateString()}</TableCell>
              <TableCell>
                <TypeBadge type={d.type} />
              </TableCell>
              <TableCell>{d.title}</TableCell>
              <TableCell>{100 * d.percentage} %</TableCell>
              <TableCell>{separateThousands(d.price)} Kč</TableCell>
              <TableCell>{d.expectedValue > 0 ? separateThousands(d.expectedValue) + ' Kč' : ''}</TableCell>
              <TableCell>
                <StatusBadge status={d.state} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
