import {
  Table,
  TableCell,
  TableRow,
  TextField,
  TableBody,
  FormControlLabel,
  Checkbox,
  Grid,
  Theme,
  Typography,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useLayout } from '../Layout/LayoutContext';
import EditRabatsModal from '../Pages/Modals/EditRabatsModal';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import { catalogService } from '../Utils/ApiService';
import {
  finalPrice,
  getRelativeSellout,
  priceAfterBonification,
  priceAfterInvoiceBonus,
  priceAfterRabat,
  priceAfterTradeDiscount,
  rabatPercentage,
  round,
  totalBonification,
  totalInvoiceBonus,
} from '../Utils/Utils';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemRoot: {
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  })
);

interface IProps {
  product: IProduct;
  onChange: (newProduct: IProduct) => void;
  commonTradeSurcharge: number;
}

export default function ProductsChildRowEditor({ product, onChange, commonTradeSurcharge }: IProps) {
  const classes = useStyles();
  const [p, setP] = useState({ ...product });

  useEffect(() => {
    setP({ ...product });
  }, [product]);

  const changeProduct = (attr: keyof IProduct, val: any) => {
    setP({ ...p, [attr]: val });
  };

  const layout = useLayout();

  return (
    <TableRow>
      <TableCell colSpan={16}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ verticalAlign: 'top' }}>
                <TextField
                  variant="standard"
                  fullWidth
                  label="Nákupní cena"
                  value={p?.buyingPrice || '0.00'}
                  onChange={(e) => changeProduct('buyingPrice', e.target.value.replace(/,/g, '.'))}
                />
                <br />
                <TextField
                  variant="standard"
                  fullWidth
                  label="Obchodní přirážka (%)"
                  value={p?.tradeSurchage || '0.00'}
                  onChange={(e) => changeProduct('tradeSurchage', e.target.value)}
                />
                <FormControlLabel
                  control={<Checkbox color="primary" checked={p.doNotScann} />}
                  label="Individuální cena"
                  onClick={() =>
                    onChange({
                      ...p,
                      doNotScann: !p.doNotScann,
                      tradeSurchage: p.doNotScann ? round(100 * commonTradeSurcharge, 2) : 0,
                    })
                  }
                />
              </TableCell>
              <TableCell style={{ verticalAlign: 'top' }}>
                <TextField
                  variant="standard"
                  fullWidth
                  label="Sleva (%)"
                  value={p.tradeDiscount || '0'}
                  onChange={(e) => changeProduct('tradeDiscount', parseFloat(e.target.value))}
                />
                <br />
                <br />
                Po slevě <b>{priceAfterTradeDiscount(p)} Kč</b> <br />
                (- {round((p.buyingPrice || 0) - priceAfterTradeDiscount(p), 2)} Kč)
              </TableCell>
              <TableCell style={{ verticalAlign: 'top' }}>
                <Grid container>
                  <Grid className={classes.itemRoot} item xs={12} md={6}>
                    <TextField
                      variant="standard"
                      fullWidth
                      label="Rabat základ"
                      type="number"
                      disabled
                      value={p.buyingRabatBase || '0'}
                      onChange={(e) => changeProduct('buyingRabatBase', parseInt(e.target.value))}
                    />
                  </Grid>
                  <Grid className={classes.itemRoot} item xs={12} md={6}>
                    <TextField
                      variant="standard"
                      fullWidth
                      label="Rabat zdarma"
                      type="number"
                      disabled
                      value={p.buyingRabatFree || '0'}
                      onChange={(e) => changeProduct('buyingRabatFree', parseInt(e.target.value))}
                    />
                  </Grid>
                </Grid>
                Po slevě <b>{priceAfterRabat(p)} Kč</b> <br />
                (- {round(priceAfterTradeDiscount(p) - priceAfterRabat(p), 2)} Kč, {rabatPercentage(p)} %)
                <EditRabatsModal
                  productId={p.id}
                  onBestRabatChange={(base, free) => setP({ ...p, buyingRabatBase: base, buyingRabatFree: free })}
                />
              </TableCell>
              <TableCell style={{ verticalAlign: 'top' }}>
                <TextField
                  variant="standard"
                  fullWidth
                  label="Bonus fakt. (%)"
                  value={p.invoiceBonus || '0'}
                  onChange={(e) => changeProduct('invoiceBonus', parseFloat(e.target.value))}
                />
                <br />
                <br />
                Po slevě <b>{priceAfterInvoiceBonus(p)} Kč</b> <br />
                (- {totalInvoiceBonus(p)} Kč)
              </TableCell>
              <TableCell style={{ verticalAlign: 'top' }}>
                <TextField
                  variant="standard"
                  fullWidth
                  label="Bonifikace (%)"
                  value={p.bonification || '0'}
                  onChange={(e) => changeProduct('bonification', parseFloat(e.target.value))}
                />
                <br />
                <br />
                Po slevě <b>{priceAfterBonification(p)} Kč</b> <br />
                <span style={{ color: product.doNotScann && product.bonification ? 'red' : undefined }}>
                  (- {totalBonification({ ...p, doNotScann: false })} Kč)
                </span>
              </TableCell>
              <TableCell style={{ verticalAlign: 'top' }}>
                <TextField
                  variant="standard"
                  fullWidth
                  label="Sell-out (Kč)"
                  value={p.sellout || '0'}
                  onChange={(e) => changeProduct('sellout', parseFloat(e.target.value))}
                />
                <br />
                <br />
                Po slevě <b>{finalPrice(p)} Kč</b> <br />
                (- {getRelativeSellout(p)} %)
              </TableCell>
              <TableCell style={{ verticalAlign: 'top' }}>
                Finální&nbsp;cena:&nbsp;<b>{finalPrice(p)}&nbsp;Kč</b>
                <br />
                Sleva&nbsp;celkem:&nbsp;{round((p.buyingPrice || 0) - finalPrice(p), 2)}&nbsp;Kč,&nbsp;
                {round(100 - 100 * (finalPrice(p) / (p.buyingPrice || 1)), 0)}&nbsp;%
                <br />
                <br />
                <FormControl variant="standard" fullWidth>
                  <InputLabel>Preferovaný dodavatel</InputLabel>
                  <Select
                    value={p.preferredSupplier || ''}
                    label="Preferovaný dodavatel"
                    onChange={(e) => changeProduct('preferredSupplier', e.target.value)}
                  >
                    <MenuItem value="Pharmos">Pharmos</MenuItem>
                    <MenuItem value="Phoenix">Phoenix</MenuItem>
                    <MenuItem value="Alliance">Alliance</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={6}>
                <TextField
                  variant="standard"
                  label="Osobní poznámka"
                  fullWidth
                  value={p.personalRemark || ''}
                  onChange={(e) => changeProduct('personalRemark', e.target.value)}
                />
                <br />
                {(p.lastPharmosScann && (
                  <Typography variant="caption">Naposledy scannováno: {p.lastPharmosScann}</Typography>
                )) || (
                  <Typography variant="caption" color="secondary">
                    Tento produkt nebyl nahrán z Pharmosu
                  </Typography>
                )}
              </TableCell>
              <TableCell>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    catalogService.post(
                      'products/buyingPriceInfo',
                      {
                        productId: p.id,
                        buyingPrice: p.buyingPrice && !isNaN(p.buyingPrice) ? p.buyingPrice : 0,
                        surchage: p.tradeSurchage && !isNaN(p.tradeSurchage) ? p.tradeSurchage : 0,
                        discount: p.tradeDiscount && !isNaN(p.tradeDiscount) ? p.tradeDiscount : 0,
                        bonification: p.bonification && !isNaN(p.bonification) ? p.bonification : 0,
                        invoiceBonus: p.invoiceBonus && !isNaN(p.invoiceBonus) ? p.invoiceBonus : 0,
                        sellout: p.sellout && !isNaN(p.sellout) ? p.sellout : 0,
                        doNotScan: p.doNotScann || false,
                        personalRemark: p.personalRemark || '',
                        productMass: p.productMass && !isNaN(p.productMass) ? p.productMass : 0,
                        productHeight: p.productHeight && !isNaN(p.productHeight) ? p.productHeight : 0,
                        productWidth: p.productWidth && !isNaN(p.productWidth) ? p.productWidth : 0,
                        productDepth: p.productDepth && !isNaN(p.productDepth) ? p.productDepth : 0,
                        groupPackageAmmount:
                          p.groupPackageAmmount && !isNaN(p.groupPackageAmmount) ? p.groupPackageAmmount : 0,
                        higherGroupPackageAmmount:
                          p.higherGroupPackageAmmount && !isNaN(p.higherGroupPackageAmmount)
                            ? p.higherGroupPackageAmmount
                            : 0,
                        maxExpirationLength:
                          p.maxExpirationLength && !isNaN(p.maxExpirationLength) ? p.maxExpirationLength : 0,
                        ean: p.ean || '',
                        preferredSupplier: p.preferredSupplier || '',
                      },
                      null,
                      {
                        success: () => {
                          layout.success('Údaje úspěšně změněny');
                          onChange({ ...p });
                        },
                        error: () => layout.error('Při změně došlo k chybě'),
                      }
                    )
                  }
                >
                  Uložit
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={7}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Edit
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              window.open(
                                `https://www.pharmdata.cz/pdk_new/#f=active%3Dtrue%7Cfulltext%3D${p.id}`,
                                '_blank'
                              )
                            }
                          />
                          &nbsp;&nbsp;
                          <TextField
                            variant="standard"
                            fullWidth
                            label="Hmotnost bal. (g)"
                            value={p.productMass || '0'}
                            onChange={(e) => changeProduct('productMass', parseInt(e.target.value))}
                          />
                        </div>
                      </TableCell>
                      <TableCell>
                        <TextField
                          variant="standard"
                          fullWidth
                          label="Šířka bal. (mm)"
                          value={p.productWidth || '0'}
                          onChange={(e) => changeProduct('productWidth', parseInt(e.target.value))}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          variant="standard"
                          fullWidth
                          label="Výška bal. (mm)"
                          value={p.productHeight || '0'}
                          onChange={(e) => changeProduct('productHeight', parseInt(e.target.value))}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          variant="standard"
                          fullWidth
                          label="Hloubka bal. (mm)"
                          value={p.productDepth || '0'}
                          onChange={(e) => changeProduct('productDepth', parseInt(e.target.value))}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          variant="standard"
                          fullWidth
                          label="Počet - folie (ks)"
                          value={p.groupPackageAmmount || '0'}
                          onChange={(e) => changeProduct('groupPackageAmmount', parseInt(e.target.value))}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          variant="standard"
                          fullWidth
                          label="Počet - karton (ks)"
                          value={p.higherGroupPackageAmmount || '0'}
                          onChange={(e) => changeProduct('higherGroupPackageAmmount', parseInt(e.target.value))}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          variant="standard"
                          fullWidth
                          label="Délka EXP (měs.)"
                          value={p.maxExpirationLength || '0'}
                          onChange={(e) => changeProduct('maxExpirationLength', parseInt(e.target.value))}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          variant="standard"
                          fullWidth
                          label="Kód EAN"
                          value={p.ean || ''}
                          onChange={(e) => changeProduct('ean', e.target.value)}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableCell>
    </TableRow>
  );
}
