import React, { useEffect, useState } from 'react';
import { Autocomplete, Chip, TextField, Typography } from '@mui/material';
import { catalogService } from '../Utils/ApiService';
import { useLayout } from '../Layout/LayoutContext';
import { ISubstance } from '@magistrmartin/eshop-frontend-shared';
import { getKeyExtractorComparer } from '../Utils/Comparers';
import HelpSubstanceGroupAdd from '../Pages/Modals/HelpSubstanceGroupAdd';

interface IProps {
  onChange: (newSubstances: ISubstance[]) => void;
  substances: ISubstance[];
  productId: number;
}

export default function SubstancesSelect({ substances, onChange, productId }: IProps) {
  const [allSubstances, setAllSubstances] = useState<ISubstance[]>([]);
  const layout = useLayout();

  useEffect(() => {
    catalogService.get(
      '/products/substances',
      {},
      {
        success: setAllSubstances,
        error: layout.error,
      }
    );
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Typography variant="caption" color="textSecondary">
        Pomocné látky
      </Typography>
      <br />
      <br />

      {substances.map((value, i) => (
        <Chip
          key={value.id}
          label={`${value.name}${(value.otherNames && value.otherNames.length > 0 && ` (${value.otherNames})`) || ''}`}
          onDelete={() =>
            catalogService.post(
              '/products/linkSubstanceToProduct',
              { productId: productId, substanceId: value.id, link: false },
              null,
              {
                success: () => onChange(substances.filter((_, ind) => ind !== i)),
                error: console.log,
              }
            )
          }
          color="primary"
        />
      ))}

      <Autocomplete
        value={{ id: -1, label: '' }}
        onChange={(e, val) =>
          val?.id &&
          catalogService.post('/products/linkSubstanceToProduct', { productId: productId, substanceId: val.id }, null, {
            success: () => onChange([...substances, allSubstances.find((s) => s.id === val.id) as ISubstance]),
            error: console.log,
          })
        }
        options={[
          { id: -1, label: '' },
          ...allSubstances.sort(getKeyExtractorComparer('name')).map((s) => ({
            id: s.id,
            label: `${s.name} ${(s.otherNames && s.otherNames.length > 0 && ` (${s.otherNames})`) || ''}`,
          })),
        ]}
        fullWidth
        isOptionEqualToValue={(o, v) => o.id === v.id}
        renderInput={(p) => <TextField label="Přidat látku" {...p} />}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id || -1}>
              {option.label}
            </li>
          );
        }}
      />
      <HelpSubstanceGroupAdd
        addNewSubstances={(newSubstances) => setAllSubstances([...allSubstances, ...newSubstances])}
        allSubstances={allSubstances}
        onSave={(ss) =>
          catalogService.post(
            '/products/linkSubstancesToProduct',
            { productId: productId },
            ss.map((s) => s.id),
            {
              success: () => onChange([...substances, ...ss]),
              error: console.log,
            }
          )
        }
      />
    </>
  );
}
