import React, { useEffect, useState } from 'react';
import { Table, TableHead, TableBody, TableCell, TableRow, Button, Typography, IconButton } from '@mui/material';
import { useLayout } from '../Layout/LayoutContext';
import { ExpectedFreeItemsDelivery } from '../Types/base';
import { ordersService } from '../Utils/ApiService';
import AddFreItemDeliveryModal from './Modals/AddFreItemDeliveryModal';
import { Delete } from '@mui/icons-material';
import AddFreItemDeliveryMessageModal from './Modals/AddFreItemDeliveryMessageModal';
import { toCzechTime } from '../Utils/Utils';

export default function FreeItemsDeliveryCheck() {
  const [history, setHistory] = useState<ExpectedFreeItemsDelivery[]>([]);

  const layout = useLayout();

  useEffect(() => {
    layout.setIsLoading(true);
    ordersService.get(
      '/stats/expectedFreeItemsDelivery',
      {},
      {
        success: setHistory,
        error: layout.error,
        finally: () => layout.setIsLoading(false),
      }
    );
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <AddFreItemDeliveryModal onAdd={(newItem) => setHistory([...history, { ...newItem }])} />
      <AddFreItemDeliveryMessageModal onAdd={(newItem) => setHistory([...history, { ...newItem }])} />

      <Table>
        {['waiting', 'received', 'stockReceived'].map((stage) => (
          <React.Fragment key={stage}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={7}>
                  <Typography variant="h6">
                    {stage === 'waiting'
                      ? 'Produkty čekající na dodání'
                      : stage === 'received'
                        ? 'Převzaté produkty čekající na dodání na sklad'
                        : 'Produkty převzaté na skladě'}
                  </Typography>
                  <br />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>PDK</TableCell>
                <TableCell>Název produktu</TableCell>
                <TableCell>Dodavatel</TableCell>
                <TableCell>Množství ks zdarma</TableCell>
                <TableCell>{stage !== 'waiting' && 'Čas převzetí'}</TableCell>
                <TableCell>{stage === 'stockReceived' && 'Čas převzetí na sklad'}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history
                .filter(
                  (p) =>
                    (stage === 'waiting' && p.receivedDate === undefined) ||
                    (stage === 'received' && p.receivedDate !== undefined && p.stockReceivedDate === undefined) ||
                    (stage === 'stockReceived' && p.stockReceivedDate !== undefined)
                )
                .map((p) => (
                  <TableRow key={p.id}>
                    {p.isFromCatalog ? (
                      <>
                        <TableCell>{p.productId}</TableCell>
                        <TableCell>{p.productFullName}</TableCell>
                        <TableCell>{p.supplier}</TableCell>
                        <TableCell>{p.amount}</TableCell>
                      </>
                    ) : (
                      <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                        {p.productFullName}
                      </TableCell>
                    )}

                    <TableCell>
                      {stage !== 'waiting' ? toCzechTime(new Date(p.receivedDate || '')).toLocaleString() : <></>}
                    </TableCell>
                    <TableCell>
                      {stage === 'stockReceived' ? (
                        toCzechTime(new Date(p.stockReceivedDate || '')).toLocaleString()
                      ) : stage === 'received' ? (
                        <Button
                          color="primary"
                          onClick={() =>
                            layout.confirm(
                              'Potvrdit převzetí na sklad',
                              `Potvzuji převzetí ${p.amount} kusů zdarma produktu ${p.productFullName} na sklad.`,
                              () =>
                                ordersService.post(
                                  '/stats/expectedFreeItemsDelivery',
                                  {},
                                  { ...p, stockReceivedDate: new Date() } as ExpectedFreeItemsDelivery,
                                  {
                                    success: (updated: ExpectedFreeItemsDelivery) =>
                                      setHistory(history.map((h) => (h.id === p.id ? { ...updated } : h))),
                                    error: () => layout.error('Při ukládání došlo k chybě'),
                                  }
                                )
                            )
                          }
                        >
                          Potvrdit převzetí na&nbsp;sklad
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          onClick={() =>
                            layout.confirm(
                              'Potvrdit převzetí',
                              `Potvzuji převzetí ${p.amount} kusů zdarma produktu ${p.productFullName}.`,
                              () =>
                                ordersService.post(
                                  '/stats/expectedFreeItemsDelivery',
                                  {},
                                  { ...p, receivedDate: new Date() } as ExpectedFreeItemsDelivery,
                                  {
                                    success: (updated: ExpectedFreeItemsDelivery) =>
                                      setHistory(history.map((h) => (h.id === p.id ? { ...updated } : h))),
                                    error: () => layout.error('Při ukládání došlo k chybě'),
                                  }
                                )
                            )
                          }
                        >
                          Potvrdit převzetí
                        </Button>
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() =>
                          layout.deleteConfirm(
                            'Smazat tuto položku',
                            'Informace o dodání zdarma budou nenávratně smazány',
                            () =>
                              ordersService.delete('/stats/expectedFreeItemsDelivery', { id: p.id }, null, {
                                success: () => setHistory(history.filter((h) => h.id !== p.id)),
                                error: () => layout.error('Při mazání došlo k chybě'),
                              })
                          )
                        }
                        size="large"
                      >
                        <Delete color="error" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell style={{ border: 'none' }} colSpan={7}>
                  <br />
                </TableCell>
              </TableRow>
            </TableBody>
          </React.Fragment>
        ))}
      </Table>

      <AddFreItemDeliveryModal onAdd={(newItem) => setHistory([...history, { ...newItem }])} />
      <AddFreItemDeliveryMessageModal onAdd={(newItem) => setHistory([...history, { ...newItem }])} />
    </>
  );
}
