import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { FilterList } from '@mui/icons-material';
import React, { useState } from 'react';
import { OrderPreview } from '../../Types/order';
import { OrderState } from '@magistrmartin/eshop-frontend-shared';
import { produce } from 'immer';

export interface IFilter {
  paid: boolean | undefined;
  status: Set<OrderState>;
}

interface IProps {
  filter: IFilter;
  setFilter: (filterToSet: IFilter) => void;
}

export const filterOrders = (orders: OrderPreview[], filter: IFilter) => {
  return orders.filter((order) => {
    let statusMatching = false;
    if (filter.status.size === 0 || order.status === undefined) {
      statusMatching = true;
    } else {
      statusMatching = filter.status.has(order.status);
    }
    let paidMatching = filter.paid === undefined ? true : filter.paid === order.paid;
    return statusMatching && paidMatching;
  });
};

export const emptyFilter: IFilter = { paid: undefined, status: new Set() };

export default function OrdersFilters({ filter, setFilter }: IProps) {
  const [open, setOpen] = useState(false);

  const toggleStatusFilter = (status: OrderState) => {
    setFilter(
      produce(filter, (draftFilterState) => {
        if (isStatusSet(status)) {
          draftFilterState.status.delete(status);
        } else {
          draftFilterState.status.add(status);
        }
      })
    );
  };

  const togglePaidFilter = (paid: boolean | undefined) => {
    setFilter(
      produce(filter, (draftFilterState) => {
        draftFilterState.paid = paid;
      })
    );
  };

  const isStatusSet = (status: OrderState) => {
    return filter.status.has(status);
  };

  return (
    <>
      <IconButton aria-label="filters" onClick={() => setOpen(true)} size="large">
        <FilterList />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle>Nastavit filtry</DialogTitle>
        <DialogContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Platba</TableCell>
                <TableCell colSpan={2}>
                  <Button onClick={() => togglePaidFilter(true)} color={filter.paid === true ? 'inherit' : 'primary'}>
                    Zaplaceno
                  </Button>
                  <Button onClick={() => togglePaidFilter(false)} color={filter.paid === false ? 'inherit' : 'primary'}>
                    Nezaplaceno
                  </Button>
                  <Button
                    onClick={() => togglePaidFilter(undefined)}
                    color={filter.paid === undefined ? 'inherit' : 'primary'}
                  >
                    Oboje
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>
                  <FormControlLabel
                    control={<Checkbox color="primary" checked={isStatusSet('Waiting')} />}
                    label="Čekání"
                    onClick={() => toggleStatusFilter('Waiting')}
                  />
                  <br />
                  <FormControlLabel
                    control={<Checkbox color="primary" checked={isStatusSet('InProgress')} />}
                    label="Zabaleno"
                    onClick={() => toggleStatusFilter('InProgress')}
                  />
                  <br />
                  <FormControlLabel
                    control={<Checkbox color="primary" checked={isStatusSet('Shipped')} />}
                    label="Odesláno"
                    onClick={() => toggleStatusFilter('Shipped')}
                  />
                  <br />
                  <FormControlLabel
                    control={<Checkbox color="primary" checked={isStatusSet('Prepared')} />}
                    label="Připraveno"
                    onClick={() => toggleStatusFilter('Prepared')}
                  />
                  <br />
                </TableCell>
                <TableCell>
                  <FormControlLabel
                    control={<Checkbox color="primary" checked={isStatusSet('Finished')} />}
                    label="Uzavřeno"
                    onClick={() => toggleStatusFilter('Finished')}
                  />
                  <br />
                  <FormControlLabel
                    control={<Checkbox color="primary" checked={isStatusSet('Rejected')} />}
                    label="Zamítnuto"
                    onClick={() => toggleStatusFilter('Rejected')}
                  />
                  <br />
                  <FormControlLabel
                    control={<Checkbox color="primary" checked={isStatusSet('Canceled')} />}
                    label="Stornováno"
                    onClick={() => toggleStatusFilter('Canceled')}
                  />
                  <br />
                  <FormControlLabel
                    control={<Checkbox color="primary" checked={isStatusSet('Refunded')} />}
                    label="Vratka"
                    onClick={() => toggleStatusFilter('Refunded')}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpen(false)}>
            Zavřít
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
