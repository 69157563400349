import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { InternalOrder } from '../Types/order';
import { catalogService, ordersService } from '../Utils/ApiService';
import { useLayout } from '../Layout/LayoutContext';
import OrderStatusLabel from '../Components/OrderStatusLabel';
import { Pagination } from '@mui/material';
import { getKeyExtractorComparer, getKeyExtractorReversedComparer } from '../Utils/Comparers';
import { manufacturePrice, priceAfterTradeDiscount, round, separateThousands, toCzechTime } from '../Utils/Utils';
import { SupplierDetails } from '../Types/base';
import SupplierDetailsModal from './Modals/SupplierDetailsModal';
import { IProduct, InternalOrderState, StatusChangeHistory } from '@magistrmartin/eshop-frontend-shared';
import { Comment, Edit, Inbox } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles } from '@mui/styles';
import { reducers } from '../Utils/Reducers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pointer: { cursor: 'pointer' },
    right: { float: 'right' },
    search: {
      flexGrow: 1,
      marginLeft: 16,
      marginRight: 16,
    },
  })
);

export default function AAOredersHistory() {
  const classes = useStyles();

  const [orders, setOrders] = useState<InternalOrder[]>([]);
  const [childrenOpen, setChildrenOpen] = useState<number[]>([]);
  const [filters] = useState<{
    [index: string]: (prd: InternalOrder) => boolean;
  }>({});
  //   const [filters, setFilters] = useState<{ [index: string]: (prd: InternalOrder) => boolean }>({});
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [allDetails, setAllDetails] = useState<SupplierDetails[]>([]);
  const [allProducts, setAllProducts] = useState<IProduct[]>([]);

  const layout = useLayout();
  const history = useHistory();

  useEffect(() => {
    ordersService.get(
      '/internal/orders/all',
      {},
      {
        success: (data: InternalOrder[]) => {
          setOrders(data);
          const prdIds = Array.from(new Set(data.flatMap((d) => d.orderedProductsList.map((p) => p.productId))));
          catalogService.get(
            '/products/noauth/list',
            { ids: prdIds, mapped: true },
            {
              success: setAllProducts,
              error: console.log,
            }
          );
        },
        error: () => layout.error('Při načítání objednávek došlo k chybě'),
      }
    );
    // eslint-disable-next-line
  }, []);

  useEffect(
    () => {
      catalogService.get(
        'settings/allSuppliersDetails',
        {},
        {
          success: setAllDetails,
          error: console.log,
        }
      );
    },
    //eslint-disable-next-line
    []
  );

  const rowsPerPage = 25;

  const searchFilter = (o: InternalOrder) => {
    const s = search.toLowerCase();
    const affectedCols = [o.id?.toString() || ''];
    return affectedCols.reduce((acc, act) => acc || act.toLowerCase().includes(s), false);
  };

  let filteredOrders = orders || [];
  Object.keys(filters).forEach((k) => (filteredOrders = filteredOrders.filter(filters[k])));
  if (search.length > 0) filteredOrders = filteredOrders.filter(searchFilter);

  filteredOrders = filteredOrders.sort(getKeyExtractorReversedComparer('id'));

  const setReceived = (order: InternalOrder) =>
    layout.simpleTextForm(
      'Nastavit objednávku na přijato',
      'Objednávka bude nastavena na jako přijata',
      'Komentář',
      (comment) =>
        ordersService.post(
          '/internal/orders/changeStatus',
          { orderId: order.id, newStatus: 'received', remark: comment },
          null,
          {
            success: (newRecord: StatusChangeHistory) =>
              setOrders(
                orders.map((o) =>
                  o.id === order.id
                    ? {
                        ...order,
                        status: 'received',
                        statusChangeHistoryList: [...(order.statusChangeHistoryList || []), newRecord],
                      }
                    : o
                )
              ),
            error: () =>
              layout.error(
                'Při ukládání změn došlo k chybě. Obnovte stránku a zkuste to prosím znovu, případně nás prosím kontaktujte.'
              ),
          }
        )
    );

  const precalculatePrice = (price: number, action: string) => {
    const rabatStart = action.split('Rabat')[1].replace(/[ ]*/g, '');
    const amounts = rabatStart.split('+').map((a) => parseInt(a));
    return (price * amounts[0]) / amounts.reduce(reducers.sum);
  };

  return (
    <>
      <Toolbar>
        <Button disabled>Objednávky z centrálního objednávání</Button>
        <Button color="primary" onClick={() => history.push('/orders/distro')}>
          Objednávky z MM Distribuce
        </Button>
      </Toolbar>
      <Toolbar>
        {/* <OrdersFilters filters={filters} setFilters={setFilters} /> */}
        <TextField
          variant="standard"
          margin="dense"
          className={classes.search}
          label="vyhledat"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Pagination
          count={Math.ceil(filteredOrders.length / rowsPerPage)}
          showFirstButton
          showLastButton
          page={page}
          onChange={(_, val) => setPage(val)}
          color="primary"
        />
      </Toolbar>
      <br />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Datum vytvoření</TableCell>
            <TableCell>Lékárna</TableCell>
            <TableCell>Distributor</TableCell>
            <TableCell>Dodavatel na trh</TableCell>
            <TableCell>Cena</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredOrders
            .slice((page - 1) * rowsPerPage, Math.min(rowsPerPage * page, filteredOrders.length))
            .map((o) => (
              <React.Fragment key={o.id || -1}>
                <TableRow
                  className={classes.pointer}
                  onClick={() =>
                    childrenOpen.includes(o.id || 0)
                      ? setChildrenOpen(childrenOpen.filter((ch) => ch !== o.id))
                      : setChildrenOpen([...childrenOpen, o.id || 0])
                  }
                >
                  <TableCell>{o.id}</TableCell>
                  <TableCell>
                    {toCzechTime(new Date(o.createdDate || '')).toLocaleString('cs-CZ')}{' '}
                    {toCzechTime(new Date(o.createdDate || ''))
                      .toLocaleString('cs-CZ', { weekday: 'short' })
                      .toLocaleUpperCase()}
                  </TableCell>
                  <TableCell>{o.pharma}</TableCell>
                  <TableCell>{o.dristributor}</TableCell>
                  <TableCell>
                    {allDetails.filter((d) => d.id === o.supplierId)[0]?.supplierTag}{' '}
                    {allDetails.filter((d) => d.id === o.supplierId)[0] && (
                      <SupplierDetailsModal supplier={allDetails.filter((d) => d.id === o.supplierId)[0]} />
                    )}
                  </TableCell>
                  <TableCell>{separateThousands(round(o.totalPrice || 0, 2))} Kč</TableCell>
                  <TableCell>
                    <OrderStatusLabel orderStatus={o.status || 'new'} />
                    {o.newComment && (
                      <Tooltip title="U této objednávky byl přidán nový komentář. Kliknutím příznak smažete.">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            ordersService.post('/internal/orders/removeNewCommentFlag', { orderId: o.id }, null, {
                              success: () =>
                                setOrders(orders.map((oo) => (oo.id === o.id ? { ...o, newComment: false } : oo))),
                              error: () => layout.error('Při ukláání změn došlo k chybě'),
                            });
                          }}
                          size="large"
                        >
                          <Comment color="error" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
                {childrenOpen.includes(o.id || 0) && (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                          <Typography variant="h6">Detailní informace</Typography>
                          <br />
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <b>Lékárna:</b>
                                </td>
                                <td>{o.pharma}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Distributor:</b>
                                </td>
                                <td>{o.dristributor}</td>
                              </tr>
                              <tr>
                                <td colSpan={2}>&nbsp;</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Připravil:</b>
                                </td>
                                <td>{o.author}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Kontakt:</b>&nbsp;&nbsp;&nbsp;&nbsp;
                                </td>
                                <td>
                                  {o.authorPhone}, {o.authorMail}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography variant="h6">Historie vývoje objednávky</Typography>
                          <br />
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <OrderStatusLabel orderStatus="Waiting" />
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </td>
                                <td>{toCzechTime(new Date(o.createdDate || '')).toLocaleString('cs-CZ')}</td>
                              </tr>
                              {
                                // @ts-ignore
                                o.statusChangeHistoryList.map((h) => (
                                  <React.Fragment key={h.id}>
                                    <tr>
                                      <td>
                                        <OrderStatusLabel orderStatus={h.newState as InternalOrderState} />
                                      </td>
                                      <td>{toCzechTime(new Date(h.date || '')).toLocaleString('cs-CZ')}</td>
                                    </tr>
                                    {h.remark && (
                                      <tr>
                                        <td colSpan={2}>Komentář: {h.remark}</td>
                                      </tr>
                                    )}
                                  </React.Fragment>
                                ))
                              }
                            </tbody>
                          </table>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h6">Objednané produkty</Typography>
                          <br />
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Produkt</TableCell>
                                <TableCell>Množství</TableCell>
                                <TableCell>Výrobní cena</TableCell>
                                <TableCell>NC s DPH/ks - v akci</TableCell>
                                <TableCell>Akce</TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {o.orderedProductsList
                                .slice()
                                .sort(getKeyExtractorComparer('productTitle'))
                                .map((p) => {
                                  const prd = allProducts.filter((pp) => pp.id === p.productId)[0] as
                                    | IProduct
                                    | undefined;
                                  return (
                                    <TableRow
                                      key={p.productId}
                                      style={p.unfulfilled ? { backgroundColor: '#dc3545' } : {}}
                                    >
                                      <TableCell>{p.productId}</TableCell>
                                      <TableCell>
                                        {p.productTitle} {p.productSubtitle}
                                      </TableCell>
                                      <TableCell>{p.amount}</TableCell>
                                      <TableCell>
                                        {(prd && separateThousands(round(manufacturePrice(prd), 2))) || '-'} Kč
                                        <br />
                                        <Typography variant="caption" color="textSecondary">
                                          Počet folie: {prd?.groupPackageAmmount}
                                        </Typography>{' '}
                                      </TableCell>
                                      <TableCell>
                                        {/* {(prd &&
                                    [getBestRabat(prd, p.amount || 0)].map((r) =>
                                      r === undefined
                                        ? separateThousands(round(prd.buyingPrice || 0, 2))
                                        : separateThousands(round(((prd.buyingPrice || 0) * r.rabatBase) / (r.rabatBase + r.rabatExtra + r.rabatFree), 2))
                                    )[0]) ||
                                    "-"}{" "} */}
                                        {prd && separateThousands(round(priceAfterTradeDiscount(prd), 2))}
                                        Kč
                                        <br />
                                        <Typography variant="caption" color="textSecondary">
                                          Počet karton: {prd?.higherGroupPackageAmmount}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        {p.action}
                                        {p.action?.includes('Rabat') && prd && (
                                          <>
                                            <br />
                                            <Typography variant="caption" color="textSecondary">
                                              Rozpočítaná cena:{' '}
                                              {separateThousands(
                                                round(precalculatePrice(priceAfterTradeDiscount(prd), p.action), 2)
                                              )}{' '}
                                              Kč
                                            </Typography>
                                          </>
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        <Tooltip title="Upozornit na změnu obalu produktu">
                                          <IconButton
                                            onClick={() =>
                                              catalogService.post(
                                                '/products/changePackageChanged',
                                                {
                                                  id: p.productId,
                                                  newValue: true,
                                                },
                                                null,
                                                {
                                                  success: () => layout.success('Změna obalu byla úspěšně nastavena'),
                                                  error: () => layout.error('Při změně obalu došlo k chybě'),
                                                }
                                              )
                                            }
                                            size="large"
                                          >
                                            <Inbox />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Otevřít nastavení produktu">
                                          <IconButton
                                            onClick={() => {
                                              localStorage.setItem('productsSearch', p.productId.toString());
                                              localStorage.removeItem('productsFilters');
                                              localStorage.removeItem('productsPage');
                                              window.open(`/products`, '_blank');
                                            }}
                                            size="large"
                                          >
                                            <Edit />
                                          </IconButton>
                                        </Tooltip>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </Grid>
                        <Grid item xs={12}>
                          <Toolbar className={classes.right}>
                            <Button
                              onClick={() => window.open(`/orders/internal/distributor/${o.securityCode}`)}
                              color="secondary"
                            >
                              Zobrazit objednávku z pohledu distributora
                            </Button>
                            {o.status !== 'rejected' && o.status !== 'received' && (
                              <Button onClick={() => setReceived(o)} color="primary" variant="contained">
                                Nastavit jako přijato
                              </Button>
                            )}
                          </Toolbar>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
        </TableBody>
      </Table>
    </>
  );
}
