import React, { useState, useEffect } from 'react';
import { Typography, Grid, IconButton, Button, Tabs, Tab, Pagination } from '@mui/material';
import { useLayout } from '../Layout/LayoutContext';
import { catalogService } from '../Utils/ApiService';
import { Delete } from '@mui/icons-material';
import { domains } from '../Utils/Mutations';

interface IHistoryRecord {
  id: number;
  searchText: string;
  domain: string;
}

export default function SearchHistory() {
  const [searchHistory, setSearchHistory] = useState<IHistoryRecord[]>([]);
  const [domainTab, setDomainTab] = useState(0);
  const [currentPage, setPage] = useState(1);
  const layout = useLayout();
  const itemsPerPage = 48;
  const totalPages = Math.ceil(searchHistory.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems= searchHistory.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    catalogService.get('/products/searchHistory',
      { domain: domains[domainTab] },
      {
        success: (data) => {
          setSearchHistory(data);
          setPage(1);
        },
        error: () => layout.error('Při načítání historie došlo k chybě'),
      }
    );
  }, [domainTab, layout]);

  

  const handleDelete = (id: number) => {
    catalogService.delete('/products/searchHistory',
      { id }, null, 
      {
        success: () => setSearchHistory(searchHistory.filter((hh) => hh.id !== id)),
        error: () => layout.error('Při mazání došlo k chybě'),
    });
  };

  const handleItemClick = (h: IHistoryRecord) => {
    window.open(`https://${h.domain}/offer/search/${h.searchText}#notrack`);
    handleDelete(h.id);
  };

  const handleDeleteAll = () => {
    layout.deleteConfirm('Opravdu chcete vymazat historii vyhledávání pro danou doménu?', '', () => {
      catalogService.delete('/products/searchHistory/domain', 
        { domain: domains[domainTab] }, null, 
        {
          success: () => {
            setSearchHistory([]);
            layout.success('Historie vyhledávání byla úspěšně smazána');
        },
        error: () => layout.error('Při mazání historie došlo k chybě'),
      });
    });
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Typography variant="h4">Historie vyhledávání</Typography>
        <span style={{ flexGrow: 1 }}></span>
        <Button color="secondary" onClick={handleDeleteAll}>
          Smazat vše
        </Button>
      </div>
      <br />
      <Tabs color="primary" value={domainTab} onChange={(_, val) => setDomainTab(val)}>
        {domains.map((d) => (
          <Tab key={d} label={d} />
        ))}
      </Tabs>
      <br />
      <Grid container spacing={2}>
        {currentItems.map((h) => (
          <Grid item xs={2} key={h.id}>
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <IconButton
                  onClick={() => handleDelete(h.id)}
                  aria-label="delete"
                  size="large"
                >
                  <Delete color="error" />
                </IconButton>
              </Grid>
              <Grid
                item
                xs={10}
                style={{ cursor: 'pointer' }}
                onClick={() => handleItemClick(h)}
              >
                <Typography noWrap>{h.searchText}</Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={(_, value) => setPage(value)}
        color="primary"
        sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
      />
    </>
  );
}