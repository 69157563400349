import React, { useEffect, useState } from 'react';
import { Autocomplete, Chip, TextField, Tooltip, Typography } from '@mui/material';
import { catalogService } from '../Utils/ApiService';
import { useLayout } from '../Layout/LayoutContext';
import { ICategory } from '@magistrmartin/eshop-frontend-shared';

interface IProps {
  onChange: (newCats: string[]) => void;
  categories: string[];
}

export default function CategoriesSelect({ categories, onChange }: IProps) {
  const [allCategories, setAllCategories] = useState<ICategory[]>([]);
  const [categorisFullNames, setCategoriesFullNames] = useState<{
    [index: string]: string;
  }>({});
  const layout = useLayout();

  useEffect(() => {
    catalogService.get('categories/all', [], {
      success: onCategoriesLoad,
      error: layout.error,
    });
    //eslint-disable-next-line
  }, []);

  const onCategoriesLoad = (cats: ICategory[]) => {
    console.log(cats);
    const catNames: { [index: string]: string } = {};
    cats.forEach((c) => (catNames[c.id] = getCategoryFullName(c, cats)));
    setCategoriesFullNames(catNames);
    setAllCategories(cats);
  };

  const getCategoryParent = (allCats: ICategory[], cat?: ICategory) => {
    if (cat === undefined || !cat.id.includes('.')) return undefined;
    return allCats.filter((c) => c.id === cat.id.substring(0, cat.id.lastIndexOf('.')))[0];
  };

  const getCategoryFullName: (cat: ICategory, allCats: ICategory[]) => string = (cat, allCats) => {
    const parent = getCategoryParent(allCats, cat);
    if (parent === undefined) return cat?.description || '';
    else return getCategoryFullName(parent, allCats) + ' > ' + cat?.description;
  };

  return (
    <>
      <Typography variant="caption" color="textSecondary">
        Kategorie
      </Typography>
      <br />
      <br />

      {(categories as string[]).map((value, i) => (
        <Tooltip
          key={value}
          title={`${value.replace('*', '')} ${
            categorisFullNames[allCategories?.filter((c) => c.id === value.replace('*', ''))[0]?.id || ''] || ''
          }`}
        >
          <Chip
            label={`${value.replace('*', '')} ${
              categorisFullNames[allCategories?.filter((c) => c.id === value.replace('*', ''))[0]?.id || '']
                ?.split('>')
                .reverse()[0] || ''
            }`}
            onDelete={() => onChange(categories.filter((_, ind) => ind !== i))}
            clickable
            onClick={() =>
              onChange(
                categories.map((cat, ind) => (ind === i ? (cat.endsWith('*') ? cat.replace('*', '') : `${cat}*`) : cat))
              )
            }
            color={value.includes('*') ? 'default' : 'primary'}
          />
        </Tooltip>
      ))}

      <Autocomplete
        value={{ id: '', label: '' }}
        onChange={(e, val) => val && onChange([...categories, val.id])}
        options={[
          { id: '', label: '' },
          ...allCategories.map((c) => ({
            id: c.id,
            label: `${c.id} - ${categorisFullNames[c.id] || ''}`,
          })),
        ]}
        isOptionEqualToValue={(o, v) => o.id === v.id}
        fullWidth
        renderInput={(p) => <TextField label="Přidat kategorii" {...p} />}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id || -1}>
              {option.label}
            </li>
          );
        }}
      />
    </>
  );
}
