import React from 'react';
import { Card, CardContent, CardHeader, IconButton, Theme, Typography } from '@mui/material';
import { IBaseDiscount } from '@magistrmartin/eshop-frontend-shared';
import EditDiscount from '../Pages/Modals/EditDiscount';
import { Delete, LinkOff } from '@mui/icons-material';
import { useLayout } from '../Layout/LayoutContext';
import { catalogService } from '../Utils/ApiService';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import { getAllDiscounts } from '../Utils/Utils';
import { reducers } from '../Utils/Reducers';
import { useHistory } from 'react-router-dom';
import LinkProduct from '../Pages/Modals/LinkProduct';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pointer: {
      cursor: 'pointer',
    },
  })
);

interface IProps {
  discount: IBaseDiscount;
  onDelete: () => void;
  onChange: (newDiscount: IBaseDiscount) => void;
  onUnlink: (unlinked: IProduct) => void;
  onLink: (linked: IProduct) => void;
  products: IProduct[];
}

function VariablePart({ discount }: { discount: IBaseDiscount }) {
  return discount.type === 'FREE_DELIVERY' ? (
    <Typography>
      <b>Minimální nákup: </b>
      {discount.minimum?.value} {discount.minimum?.factor === 'items' ? 'ks' : 'Kč'}
    </Typography>
  ) : discount.type === 'PRESENT' ? (
    <Typography>
      <b>Minimální nákup: </b>
      {discount.minimum?.value} {discount.minimum?.factor === 'items' ? 'ks' : 'Kč'}
      <br />
      <b>Id dárku: </b>
      {discount.details}
      <br />
      <b>Typ slevy: </b> {discount.single ? 'Jednorázová' : 'Opakovací'}
    </Typography>
  ) : discount.type === 'CENTRAL_PRESENT' ? (
    <Typography>
      <b>Minimální nákup: </b>
      {discount.minimum?.value} {discount.minimum?.factor === 'items' ? 'ks' : 'Kč'}
      <br />
      <b>Id dárku: </b>
      {discount.details?.split(';')[0]}
      <br />
      <b>Typ slevy: </b> {discount.single ? 'Jednorázová' : 'Opakovací'}
      <b>Určeno pro: </b>
      {discount.details?.split(';')[1] === 'all'
        ? 'Všechny'
        : discount.details?.split(';')[1] === 'registered'
          ? 'Přihlášené'
          : 'Nepřihlášené'}
      <br />
    </Typography>
  ) : discount.type === 'MIX' ? (
    <Typography>
      <b>Množství: </b> {discount.minimum?.value} + {discount.details} zdarma
    </Typography>
  ) : discount.type === 'SPECIAL' ? (
    <Typography>
      <b>Minimální nákup: </b>
      {discount.minimum?.value} {discount.minimum?.factor === 'items' ? 'ks' : 'Kč'}
      <br />
      <b>Sleva: {}</b>
      {discount.discount?.value} Kč
    </Typography>
  ) : discount.type === 'SECOND_ITEM' ? (
    <Typography>
      <b>Sleva: </b> {discount.discount?.value} {discount.discount?.factor === 'relative' ? '%' : 'kč'}
    </Typography>
  ) : discount.type === 'CROSS' ? (
    <Typography>
      <b>Minimální nákup ve skupině: </b>
      {discount.minimum?.value} ks
      <br />
      <b>Sleva: {}</b>
      {discount.discount?.value} Kč
      <br />
      <b>ID propojené slevové skupiny: {}</b>
      {discount.details}
    </Typography>
  ) : (
    <></>
  );
}

export default function DiscountDetailsCard({ discount, onChange, onDelete, products, onUnlink, onLink }: IProps) {
  const classes = useStyles();
  const layout = useLayout();
  const history = useHistory();

  const deleteDiscout = () =>
    layout.deleteConfirm(
      'Smazat tuto slevu',
      'Tato sleva bude trvale smazána. V případě, že je použita u více produktů, bude smazána ode všech.',
      () => {
        catalogService.delete('/discounts/', { id: discount.id }, null, {
          success: onDelete,
          error: layout.error,
        });
      }
    );

  const unlinkProduct = (product: IProduct) =>
    layout.deleteConfirm(
      'Smazat odkaz na tento produkt',
      `Sleva se již dále nebude vstahovat na ${product.title} ${product.subtitle}`,
      () => {
        catalogService.post('/discounts/unlink', { discountId: discount.id, productId: product.id }, null, {
          success: () => onUnlink(product),
          error: layout.error,
        });
      }
    );

  const linkedProducts = products.filter((prod) =>
    getAllDiscounts(prod)
      .map((d) => d.id === discount.id)
      .reduce(reducers.or, false)
  );

  const productsToLink = products.filter(
    (prod) => getAllDiscounts(prod).filter((d) => d.type === discount.type).length === 0
  );

  return (
    <Card>
      <CardHeader
        title="Detaily slevy"
        action={
          <>
            <EditDiscount editedDiscount={discount} onSave={onChange} />
            <IconButton onClick={deleteDiscout} aria-label="Delter" size="large">
              <Delete color="error" />
            </IconButton>
          </>
        }
      />
      <CardContent>
        <Typography>
          <b>ID slevy: </b>
          {discount.id}
        </Typography>
        <VariablePart discount={discount} />
        {discount.validity && discount.validity.from && discount.validity.to && (
          <Typography>
            <b>Platnost:</b> {discount.validity.from} -&gt; {discount.validity.to}
          </Typography>
        )}
        {discount.remark && discount.remark.length > 0 && (
          <Typography>
            <b>Poznámka: </b> {discount.remark}
          </Typography>
        )}
        <br />
        {discount.type !== 'CENTRAL_PRESENT' && (
          <>
            <Typography>
              <b>Napojené produkty:</b>
            </Typography>
            <br />
            {linkedProducts.map((p) => (
              <div key={p.id} className={classes.pointer} onClick={() => history.push(`/products/edit/${p.id}`)}>
                <Typography noWrap display="inline">
                  [{p.id}] {p.title} {p.subtitle}
                </Typography>
                <IconButton
                  aria-label="unlink"
                  onClick={(e) => {
                    e.stopPropagation();
                    unlinkProduct(p);
                  }}
                  size="large"
                >
                  <LinkOff color="error" />
                </IconButton>
              </div>
            ))}
            <LinkProduct discount={discount} products={productsToLink} onSave={onLink} />
          </>
        )}
      </CardContent>
    </Card>
  );
}
