import React from 'react';
import { BrowserRouter, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { LayoutProvider } from './Layout/LayoutContext';
import CatalogConfig from './Pages/CatalogConfig';
import Categories from './Pages/Categories';
import Discounts from './Pages/Discounts';
import EditProduct from './Pages/EditProduct/EditProduct';
import ImportProducts from './Pages/ImportProducts';
import Products from './Pages/Products';
import { Auth0Provider } from '@auth0/auth0-react';
import NewProduct from './Pages/EditProduct/NewProduct';
import StaticSites from './Pages/StaticSites';
import FooterSettings from './Pages/FooterSettings';
import Notifications from './Pages/Notifications';
import OrdersHistory from './Pages/OrdersHistory';
import ShippingConfig from './Pages/ShippingConfig';
import HomeConfig from './Pages/HomeConfig/HomeConfig';
import SearchHistory from './Pages/SearchHistory';
import Users from './Pages/Users';
import Tips from './Pages/Tips';
import Pharmas from './Pages/Pharmas';
import Reservations from './Pages/Reservations';
import EditPharma from './Pages/EditPharma';
import AutomaticOrdering from './Pages/AutomaticOrdering/AutomaticOrdering';
import SupplierDetails from './Pages/SupplierDetails';
import FreeItemsDeliveryCheck from './Pages/FreeItemsDeliveryCheck';
import PdkRepair from './Pages/PdkRepair';
import ToOrder from './Pages/ToOrder';
import AOOrdersHistory from './Pages/AOOrdersHistory';
import InternalOrderDistributorEdit from './Pages/InternalOrderDistributorEdit';
import MMDistroOrdersHistory from './Pages/MMDistroOrdersHistory';
import PharmosWatchList from './Pages/PharmosWatchList';
import ScheduledJobs from './Pages/ScheduledJobs';
import InvoiceReformatter from './Pages/InvoiceReformater';
import HeurekaPairing from './Pages/HeurekaPairing';
import { enableMapSet } from 'immer';
import CategoryManagement from './Pages/CategoryManagement/CategoryManagement';
import Bonuses from './Pages/Bonuses';
import TemperatureDocument from './Pages/temperatureDocument';

interface MatchParams {
  id: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {}

export default function App() {
  enableMapSet();
  return (
    <Auth0Provider
      domain="dev-df6cho9g.eu.auth0.com"
      clientId="FptmnH13aKxwtEFWgNiRP2R5YXxSgqr6"
      redirectUri={window.location.origin}
      cacheLocation="localstorage"
    >
      <BrowserRouter>
        <LayoutProvider>
          <Switch>
            <Route path="/products" exact>
              <Products />
            </Route>
            <Route path="/products/import" exact>
              <ImportProducts />
            </Route>
            <Route
              path="/products/edit/:id?"
              exact
              //@ts-ignore
              render={({ match }: MatchProps) => (
                <EditProduct id={match.params.id === undefined ? undefined : parseInt(match.params.id)} />
              )}
            />
            <Route path="/products/new" exact>
              <NewProduct />
            </Route>
            <Route path="/categories" exact>
              <Categories />
            </Route>
            <Route path="/discounts" exact>
              <Discounts />
            </Route>
            <Route path="/catalog/config" exact>
              <CatalogConfig />
            </Route>
            <Route path="/catalog/tips" exact>
              <Tips />
            </Route>
            <Route path="/structure/home" exact>
              <HomeConfig />
            </Route>
            <Route path="/structure/sites" exact>
              <StaticSites />
            </Route>
            <Route path="/structure/footer" exact>
              <FooterSettings />
            </Route>
            <Route path="/structure/notifications" exact>
              <Notifications />
            </Route>
            <Route path="/categoryManagement" exact>
              <CategoryManagement />
            </Route>
            <Route path="/orders/history" exact>
              <OrdersHistory />
            </Route>
            <Route path="/reservations" exact>
              <Reservations />
            </Route>
            <Route path="/orders/ao" exact>
              <AOOrdersHistory />
            </Route>
            <Route path="/orders/distro" exact>
              <MMDistroOrdersHistory />
            </Route>
            <Route path="/shippingConfig" exact>
              <ShippingConfig />
            </Route>
            <Route path="/anal/heurekaPairing" exact>
              <HeurekaPairing />
            </Route>
            <Route path="/anal/searchHistory" exact>
              <SearchHistory />
            </Route>
            <Route path="/anal/users" exact>
              <Users />
            </Route>
            <Route path="/phramas" exact>
              <Pharmas />
            </Route>
            <Route
              path="/pharmas/edit/:id"
              exact
              render={({ match }: MatchProps) => <EditPharma id={parseInt(match.params.id || '0')} />}
            />
            <Route path="/automaticOrdering" exact>
              <AutomaticOrdering />
            </Route>
            <Route path="/supplierDetails" exact>
              <SupplierDetails />
            </Route>
            <Route path="/freeItemsDelivery" exact>
              <FreeItemsDeliveryCheck />
            </Route>
            <Route path="/pdkRepair" exact>
              <PdkRepair />
            </Route>
            <Route path="/toOrder" exact>
              <ToOrder />
            </Route>
            <Route
              path="/orders/internal/distributor/:id"
              exact
              render={({ match }: MatchProps) => <InternalOrderDistributorEdit token={match.params.id || ''} />}
            />
            <Route path="/pharmosWatchList" exact>
              <PharmosWatchList />
            </Route>
            <Route path="/structure/scheduledJobs">
              <ScheduledJobs />
            </Route>
            <Route path="/invoiceReformatter">
              <InvoiceReformatter />
            </Route>
            <Route path="/selloutBonus">
              <Bonuses />
            </Route>
            <Route path="/temperatureDocument">
              <TemperatureDocument />
            </Route>
          </Switch>
        </LayoutProvider>
      </BrowserRouter>
    </Auth0Provider>
  );
}