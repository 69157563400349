import React, { useEffect, useState } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Checkbox,
  Typography,
  Slider,
  Box,
  IconButton,
  Tooltip,
  Button,
  Theme,
  Chip,
  Autocomplete,
} from '@mui/material';
import { useLayout } from '../../Layout/LayoutContext';
import { SupplierDetails } from '../../Types/base';
import { IProduct, PickupPlace } from '@magistrmartin/eshop-frontend-shared';
import { statisticsService } from '../../Utils/ApiService';
import { Pagination } from '@mui/material';
import { AllInclusive, CheckBox, HelpOutline, Warning } from '@mui/icons-material';
import { manufacturePrice, round, separateThousands } from '../../Utils/Utils';
import { reducers } from '../../Utils/Reducers';
import { deserializeSort, ISort, serializeSort, SortIcon } from '../Products';
import { getNumberComparer, getStringComparer } from '../../Utils/Comparers';
import SalesHistoryModal from '../../Components/SalesHistoryModal';
import AoTableRow from './AoTableRow';
import { recalculateAmount } from '../../Utils/OrderGenerator';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      flexGrow: 1,
      marginLeft: 16,
      marginRight: 16,
    },
    pointer: {
      cursor: 'pointer',
    },
    itemRoot: {
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    flexGrow: {
      flexGrow: 1,
    },
    flex: {
      display: 'flex',
    },
    left: {
      float: 'left',
    },
  })
);

interface IProps {
  place: PickupPlace;
  products: IProduct[];
  getProductPrice: (product: IProduct) => number;
  createOrder: (
    orderedAmounts: { [index: number]: number },
    supplier?: SupplierDetails,
    predictions?:
      | {
          [index: string]: number;
        }
      | undefined
  ) => void;
  isCentralDistribution: boolean;
  getProductStockAmount: (productId: number, placeId: number) => number;
  canBeOrdered: (product: IProduct) => boolean;
  supplierDetails: SupplierDetails[];
  cdSupplierDetails: SupplierDetails[];
  selectedSupplierId: number;
  changeSupplierSelection: (val: number) => void;
  cdPlaces: PickupPlace[];
  changeProductCD: (productId: number, newCD: number) => void;
  refreshProducts: () => void;
}

export default function CommonOrdering({
  place,
  products,
  getProductPrice,
  createOrder,
  isCentralDistribution,
  getProductStockAmount,
  canBeOrdered,
  supplierDetails,
  cdSupplierDetails,
  selectedSupplierId,
  changeSupplierSelection,
  changeProductCD,
  cdPlaces,
  refreshProducts,
}: IProps) {
  const [rawPredictions, setPredictions] = useState<{ [index: string]: { [index: string]: number } } | undefined>(
    undefined
  );
  const [weeksOrder, setWeeksOrder] = useState(1);
  const [page, setPage] = useState({ cd: 0, noncd: 0 });
  const [orderedAmounts, setOrderedAmounts] = useState<{ [index: number]: number }>({});
  const [onlyInOrder, setOnlyInOrder] = useState(false);
  const [onlyInsufficient, setOnlyInsufficient] = useState(false);
  const [sort, setSortRaw] = useState<ISort | undefined>(
    deserializeSort(localStorage.getItem('autonomousProductsSort'))
  );
  const [search, setSearch] = useState('');
  const [tagsFilter, setTagsFilter] = useState<string[]>([]);
  const [errorsFilter, setErrorsFilter] = useState<'all' | 'errorsOnly' | 'noErrors'>('all');
  const [historySelectedItem, setHistorySelectedItem] = useState<{ placeId: number; product: IProduct } | undefined>(
    undefined
  );
  const [selectedItems, setSelectedItems] = useState<{ [index: number]: boolean }>({});
  const [lastYearTotalSales, setLastYearTotalSales] = useState<{ [index: number]: { [index: number]: number } }>({});

  const classes = useStyles();
  const layout = useLayout();

  const productsPerPage = 100;

  useEffect(() => {
    setTagsFilter(getAllTags(products));
    setPredictions(undefined);
    setPage({ cd: 0, noncd: 0 });
    setOrderedAmounts({});
    setOnlyInOrder(false);
    setOnlyInsufficient(false);
    setSearch('');
    setErrorsFilter('all');
    setSelectedItems(
      products.reduce<{ [index: number]: boolean }>(
        (acc, p) => ({ ...acc, [p.id]: (p.centralDistribution || 0) === 1 || isCentralDistribution }),
        {}
      )
    );
    products.length > 0 && loadTotalSales();
    //eslint-disable-next-line
  }, [products.length, place]);

  // @ts-ignore
  const getAllTags = (products: IProduct[]) => [...new Set(products.map((p) => p.supplierTag))].sort();

  const setSort = (val?: ISort) => {
    if (val === undefined) localStorage.removeItem('autonomousProductsSort');
    else localStorage.setItem('autonomousProductsSort', serializeSort(val));
    setSortRaw(val);
  };

  const changeSort = (
    key: keyof IProduct | 'raw',
    comparerGetter: (direction: 'asc' | 'desc') => (a: any, b: any) => number
  ) => {
    if (sort === undefined || sort.key !== key) setSort({ key: key, direction: 'asc', comparerGetter: comparerGetter });
    else if (sort?.direction === 'asc') setSort({ key: key, direction: 'desc', comparerGetter: comparerGetter });
    else setSort(undefined);
  };

  const loadTotalSales = () => {
    setLastYearTotalSales({});
    const dateFrom = new Date();
    dateFrom.setMonth(dateFrom.getMonth() - 12);
    statisticsService.post(
      'totalSalesHistory',
      {
        from: dateFrom.toISOString().substring(0, dateFrom.toISOString().indexOf('T')),
        to: new Date().toISOString().substring(0, new Date().toISOString().indexOf('T')),
      },
      products.map((p) => p.id),
      {
        success: setLastYearTotalSales,
        error: console.log,
      }
    );
  };

  const loadPrediction = () => {
    layout.setIsLoading(true);
    setPredictions(undefined);
    statisticsService.post(
      'productsPredictionsForMultiplePlaces',
      {
        placeIds: place.id === 0 ? cdPlaces.map((p) => p.id).filter((i) => i && i > 0) : [place.id],
        numberOfWeeks: weeksOrder,
      },
      products.map((p) => p.id),
      {
        success: (data: { [index: string]: { [index: string]: number } }) => {
          layout.setIsLoading(false);
          fillOrderAmounts(data);
          setPredictions(data);
        },
        error: () => {
          layout.setIsLoading(false);
          layout.error('Při predikci prodejností došlo k chybě');
        },
      }
    );
  };

  const calculatePredictions = (data: { [index: string]: { [index: string]: number } }) => {
    if (place.id === 0) {
      const res = { ...data[Object.keys(data)[0]] };
      Object.keys(data[Object.keys(data)[0]]).forEach(
        (pid) =>
          (res[pid] = Object.keys(data)
            .map((plid) => Math.max(0, Math.round(data[plid][pid])))
            .reduce(reducers.sum, 0.0))
      );
      return res;
    } else return data[place.id || 0];
  };

  const fillOrderAmounts = (rawData: { [index: string]: { [index: string]: number } }) => {
    const res: { [index: number]: number } = {};
    const data = calculatePredictions(rawData);
    if (data === undefined || data === null) return;
    Object.keys(data)
      .filter((pid) => canBeOrdered(products.filter((p) => p.id.toString() === pid)[0]))
      .forEach((pid) =>
        place.id === 0
          ? (res[parseInt(pid)] = Math.max(
              0,
              cdPlaces
                .filter((p) => p.id !== 0)
                .map((p) =>
                  Math.max(0, Math.round(rawData[p.id || 0][pid]) - getProductStockAmount(parseInt(pid), p.id || 0))
                )
                .reduce(reducers.sum, 0) -
                getProductStockAmount(parseInt(pid), 0) +
                cdPlaces
                  .filter((p) => (p.id || 0) > 0)
                  .map((p) => getProductStockAmount(parseInt(pid), p.id || 0))
                  .reduce(reducers.sum, 0)
            ))
          : (res[parseInt(pid)] = Math.min(
              Math.max(Math.max(0, Math.round(data[pid])) - getProductStockAmount(parseInt(pid), place.id || 0), 0),
              getProductStockAmount(parseInt(pid), 0)
            ))
      );
    if (isCentralDistribution) {
      Object.keys(res).forEach((pid) => {
        const prd = products.find((p) => p.id.toString() === pid.toString());
        if (prd) {
          const foilRounded = prd.groupPackageAmmount
            ? prd.groupPackageAmmount * Math.ceil(res[parseInt(pid)] / prd.groupPackageAmmount)
            : 0;
          const cartonRounded = prd.higherGroupPackageAmmount
            ? prd.higherGroupPackageAmmount * Math.ceil(res[parseInt(pid)] / prd.higherGroupPackageAmmount)
            : 0;
          if (cartonRounded > 0 && cartonRounded <= data[pid.toString()]) {
            res[parseInt(pid)] = cartonRounded;
          } else if (foilRounded > 0 && foilRounded <= data[pid.toString()]) {
            res[parseInt(pid)] = foilRounded;
          }
        }
        if (res[parseInt(pid)] > 0 && res[parseInt(pid)] < 4) {
          res[parseInt(pid)] = Math.min(
            Math.floor(
              (place.id === 0
                ? Object.values(lastYearTotalSales[parseInt(pid)]).reduce(reducers.sum, 0)
                : (place.id || -1) in lastYearTotalSales[parseInt(pid)]
                  ? lastYearTotalSales[parseInt(pid)][place.id || 0]
                  : 8) / 2
            ),
            4
          );
        }
        res[parseInt(pid)] = Math.min(getProductStockAmount(parseInt(pid), 0), res[parseInt(pid)]);
      });
    }
    setOrderedAmounts(res);
  };

  const predictions = rawPredictions === undefined ? undefined : calculatePredictions(rawPredictions);

  let showedProducts = products
    .filter((p) => !onlyInOrder || (orderedAmounts[p.id] || 0) > 0)
    .filter((p) => tagsFilter.includes(p.supplierTag || ''))
    .filter((p) => (errorsFilter === 'all' ? true : errorsFilter === 'errorsOnly' ? !canBeOrdered(p) : canBeOrdered(p)))
    .filter(
      (p) =>
        !onlyInsufficient ||
        (predictions &&
          Math.round(predictions[p.id]) - (p.stockState?.filter((s) => s.placeId === place.id)[0]?.ammount || 0) >
            (p.stockState?.filter((s) => s.placeId === 0)[0]?.ammount || 0))
    );
  if (search)
    showedProducts = showedProducts.filter(
      (p) =>
        p.id.toString().includes(search) ||
        p.title?.toLocaleLowerCase().includes(search.toLowerCase()) ||
        p.subtitle?.toLocaleLowerCase().includes(search.toLowerCase()) ||
        p.supplierTag?.toLocaleLowerCase().includes(search.toLowerCase())
    );
  if (sort) {
    showedProducts = showedProducts.sort((a, b) =>
      sort.comparerGetter(sort.direction)(
        a[sort.key as keyof IProduct]?.toString() || '',
        b[sort.key as keyof IProduct]?.toString() || ''
      )
    );
  } else {
    showedProducts = showedProducts
      .sort((a, b) => ((a.title || '') > (b.title || '') ? 1 : a.title === b.title ? 0 : -1))
      .sort((a, b) => ((a.supplierTag || '') > (b.supplierTag || '') ? 1 : a.supplierTag === b.supplierTag ? 0 : -1));
  }

  const maxPage = {
    cd: Math.ceil(showedProducts.filter((p) => p.centralDistribution === 1).length / productsPerPage) - 1,
    noncd: Math.ceil(showedProducts.filter((p) => p.centralDistribution !== 1).length / productsPerPage) - 1,
  };
  const actPage = { cd: Math.min(page.cd, maxPage.cd), noncd: Math.min(page.noncd, maxPage.noncd) };

  return (
    <>
      <SalesHistoryModal
        product={historySelectedItem?.product}
        placeId={historySelectedItem?.placeId}
        onClose={() => setHistorySelectedItem(undefined)}
      />
      <Grid container spacing={3}>
        <Grid item>
          <Typography>Počet týdnů, na kolik se má objednat zboží </Typography>
        </Grid>
        <Grid item>
          <Box style={{ width: 420 }}>
            <Slider
              color="primary"
              value={weeksOrder}
              onChange={(e, val) => setWeeksOrder(val as number)}
              step={1}
              marks={[
                { value: 4, label: 'měsíc' },
                { value: 13, label: 'čtvrt roku' },
                { value: 27, label: 'půl roku' },
                { value: 53, label: 'rok' },
              ]}
              min={1}
              max={53}
              valueLabelDisplay="on"
            />
          </Box>
        </Grid>
        <Grid item>
          <Button onClick={loadPrediction} variant="contained" color="primary">
            Spočítat odhad prodejností
          </Button>
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            label="Vyhledat"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item>
          <Tooltip title="Zobrazit všechny produkty">
            <IconButton color={errorsFilter === 'all' ? 'primary' : 'default'} onClick={() => setErrorsFilter('all')}>
              <AllInclusive />
            </IconButton>
          </Tooltip>
          <Tooltip title="Zobrazit pouze produkty s chybou">
            <IconButton
              color={errorsFilter === 'errorsOnly' ? 'primary' : 'default'}
              onClick={() => setErrorsFilter('errorsOnly')}
            >
              <Warning />
            </IconButton>
          </Tooltip>
          <Tooltip title="Zobrazit pouze produkty bez chyby">
            <IconButton
              color={errorsFilter === 'noErrors' ? 'primary' : 'default'}
              onClick={() => setErrorsFilter('noErrors')}
            >
              <CheckBox />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      {predictions && (
        <>
          <br />
          <Typography variant="h6">Souhrn objednávky</Typography>
          <br />
          <Grid container alignItems="center">
            <Grid item>
              <Typography>
                Počet produktů k objednání:{' '}
                {
                  Object.keys(orderedAmounts)
                    .filter((pid) => selectedItems[parseInt(pid)])
                    .filter((pid) => orderedAmounts[parseInt(pid)] > 0).length
                }
              </Typography>
              <Typography>
                Počet kusů k objednání celkem:{' '}
                {Object.keys(orderedAmounts)
                  .filter((pid) => selectedItems[parseInt(pid)])
                  .map((pid) => orderedAmounts[parseInt(pid)])
                  .reduce(reducers.sum, 0)}
              </Typography>
              <Typography>
                Celková cena:{' '}
                {separateThousands(
                  round(
                    Object.keys(orderedAmounts)
                      .filter((pid) => selectedItems[parseInt(pid)])
                      .map(
                        (pid) =>
                          (getProductPrice(products.filter((p) => p.id.toString() === pid)[0]) || 0) *
                          (orderedAmounts[parseInt(pid)] || 0)
                      )
                      .reduce(reducers.sum, 0),
                    2
                  )
                )}{' '}
                Kč
              </Typography>
              <Typography>
                Výrobní cena:{' '}
                {separateThousands(
                  round(
                    Object.keys(orderedAmounts)
                      .map((k) => ({
                        prd: products.filter((p) => p.id.toString() === k)[0],
                        value: selectedItems[parseInt(k)] ? orderedAmounts[parseInt(k)] : 0,
                      }))
                      .reduce((x, act) => x + recalculateAmount(act.prd, act.value) * manufacturePrice(act.prd), 0),
                    2
                  )
                )}{' '}
                Kč
              </Typography>
            </Grid>
            <Grid item>
              <Button
                onClick={() =>
                  createOrder(
                    Object.keys(orderedAmounts)
                      .map((k) => ({ key: k, value: selectedItems[parseInt(k)] ? orderedAmounts[parseInt(k)] : 0 }))
                      .reduce((acc, act) => ({ ...acc, [act.key]: act.value }), {}),
                    isCentralDistribution ? undefined : supplierDetails.filter((sd) => selectedSupplierId === sd.id)[0],
                    predictions
                  )
                }
                style={{ marginLeft: 16 }}
                variant="contained"
                color="primary"
              >
                Odeslat objednávku
              </Button>
              {isCentralDistribution && (
                <Button
                  onClick={() =>
                    setOrderedAmounts(
                      Object.keys(orderedAmounts)
                        .filter((key) => showedProducts.find((p) => p.id === parseInt(key)) !== undefined)
                        .reduce((cur, key) => {
                          return Object.assign(cur, { [key]: orderedAmounts[parseInt(key)] });
                        }, {})
                    )
                  }
                  color="primary"
                >
                  Omezit na filtr
                </Button>
              )}
            </Grid>
          </Grid>
        </>
      )}
      <br />
      <br />
      <Typography variant="h6">
        Dodavatelé na trh ({isCentralDistribution ? getAllTags(products).length : supplierDetails.length})
      </Typography>
      <br />
      {(isCentralDistribution && (
        <>
          <Chip
            style={{ margin: 4 }}
            onClick={(e) => {
              if (tagsFilter.length === getAllTags(products).length) setTagsFilter([]);
              else setTagsFilter(getAllTags(products));
            }}
            color={tagsFilter.length === getAllTags(products).length ? 'primary' : 'default'}
            label="VŠE"
          />
          {getAllTags(products).map((tag) => (
            <Chip
              key={tag}
              style={{ margin: 4 }}
              onClick={(e) => {
                if (e.ctrlKey) {
                  if (tagsFilter.length === 1 && tagsFilter[0] === tag) setTagsFilter(getAllTags(products));
                  else setTagsFilter([tag]);
                } else {
                  if (tagsFilter.includes(tag)) setTagsFilter(tagsFilter.filter((t) => t !== tag));
                  else setTagsFilter([...tagsFilter, tag]);
                }
              }}
              color={tagsFilter.includes(tag) ? 'primary' : 'default'}
              label={tag}
            />
          ))}
        </>
      )) || (
        <>
          <Autocomplete
            value={{
              id: selectedSupplierId,
              label: supplierDetails.find((s) => s.id === selectedSupplierId)?.supplierTag || 'VŠE',
            }}
            onChange={(e, val) => val && changeSupplierSelection(val.id)}
            options={[{ id: -1, label: 'VŠE' }, ...supplierDetails.map((s) => ({ id: s.id, label: s.supplierTag }))]}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            renderInput={(p) => <TextField label="Dodavatel" {...p} />}
          />
          <br />
          {cdSupplierDetails.map((sd) => (
            <Chip
              key={sd.id}
              style={{ margin: 4 }}
              onClick={(e) => changeSupplierSelection(sd.id)}
              color="primary"
              label={sd.supplierTag}
            />
          ))}
        </>
      )}
      <br />
      <br />

      <Typography variant="h6">Přehled produktů ({products.length})</Typography>
      {predictions && (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox color="primary" checked={onlyInOrder} onClick={() => setOnlyInOrder(!onlyInOrder)} />{' '}
            <Typography>Zobrazit pouze produkty s nenulovým množstvím v objednávce</Typography>
          </div>
          {isCentralDistribution && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                color="primary"
                checked={onlyInsufficient}
                onClick={() => setOnlyInsufficient(!onlyInsufficient)}
              />{' '}
              <Typography>Zobrazit pouze produkty, které nebyly zcela vykryty</Typography>
            </div>
          )}
        </>
      )}
      {((isCentralDistribution ? ['cd'] : ['cd', 'noncd']) as ('cd' | 'noncd')[]).map((actProductsType) => (
        <React.Fragment key={actProductsType}>
          <div style={{ display: 'flex' }}>
            <Typography>
              {actProductsType === 'cd'
                ? `Produkty s centrální distribucí (${products.filter((p) => p.centralDistribution === 1 || isCentralDistribution).length})`
                : `Produkty mimo centrální distribuci (${products.filter((p) => p.centralDistribution !== 1).length})`}
            </Typography>
            <span style={{ flexGrow: 1 }} />
            <Button onClick={refreshProducts}>Obnovit produkty</Button>
          </div>
          <br />
          {maxPage[actProductsType] > 0 && (
            <>
              <Pagination
                page={actPage[actProductsType] + 1}
                onChange={(_, v) => setPage({ ...page, [actProductsType]: v - 1 })}
                color="primary"
                count={maxPage[actProductsType] + 1}
              />
              <br />
            </>
          )}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell className={classes.pointer} onClick={() => changeSort('id', getNumberComparer)}>
                  PDK produktu &nbsp;
                  <SortIcon sort={sort} colKey="id" />
                </TableCell>
                <TableCell className={classes.pointer} onClick={() => changeSort('title', getStringComparer)}>
                  Název produktu &nbsp;
                  <SortIcon sort={sort} colKey="title" />
                </TableCell>
                {!isCentralDistribution && <TableCell>EXP</TableCell>}
                <TableCell style={{ textAlign: 'center' }}>Stav skladu lékárna</TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  Odhadovaná prodejnost&nbsp;&nbsp;
                  <Tooltip title="Odhadovaná prodejnost vychází z prodejů daného období (zvolený počet týdnů) v předešlých letech.">
                    <HelpOutline />
                  </Tooltip>
                </TableCell>
                {isCentralDistribution && (
                  <TableCell style={{ textAlign: 'center' }}>
                    Stav skladu MM Distribuce&nbsp;&nbsp;
                    <Tooltip title="Maximální možná objednávka">
                      <HelpOutline />
                    </Tooltip>
                  </TableCell>
                )}
                <TableCell style={{ textAlign: 'center' }}>
                  Návrh objednávky&nbsp;&nbsp;
                  <Tooltip title="Výpočet: Odhadovaná prodejnost - Stav skladu lékárna, maximálně však Stav skladu MM Distribuce">
                    <HelpOutline />
                  </Tooltip>
                </TableCell>
                {!isCentralDistribution && <TableCell>CD</TableCell>}
                <TableCell
                  className={classes.pointer}
                  onClick={() => changeSort('buyingPrice', getNumberComparer)}
                  style={{ textAlign: 'center' }}
                >
                  Cena s DPH/ks &nbsp;
                  <SortIcon sort={sort} colKey="buyingPrice" />
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>Cena celkem</TableCell>
                {isCentralDistribution && <TableCell style={{ textAlign: 'center' }}>Sleva</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {showedProducts
                .filter(
                  (p) =>
                    (actProductsType === 'cd' && (p.centralDistribution === 1 || isCentralDistribution)) ||
                    (actProductsType === 'noncd' && p.centralDistribution !== 1)
                )
                .slice(actPage[actProductsType] * productsPerPage, (actPage[actProductsType] + 1) * productsPerPage)
                .map((p) => (
                  <AoTableRow
                    key={p.id}
                    product={p}
                    place={place}
                    getProductPrice={getProductPrice}
                    isCentralDistribution={isCentralDistribution}
                    getProductStockAmount={getProductStockAmount}
                    canBeOrdered={canBeOrdered}
                    cdPlaces={cdPlaces}
                    setHistorySelectedItem={setHistorySelectedItem}
                    rawPredictions={rawPredictions}
                    predictions={predictions}
                    orderedAmounts={orderedAmounts}
                    setOrderedAmounts={setOrderedAmounts}
                    selectedItems={selectedItems}
                    setSelecedItems={setSelectedItems}
                    changeCD={(newCD) => changeProductCD(p.id, newCD)}
                    lastYearTotalSales={lastYearTotalSales[p.id] || {}}
                  />
                ))}
            </TableBody>
          </Table>
          <br />
          {maxPage[actProductsType] > 0 && (
            <>
              <br />
              <Pagination
                page={actPage[actProductsType] + 1}
                onChange={(_, v) => setPage({ ...page, [actProductsType]: v - 1 })}
                color="primary"
                count={maxPage[actProductsType] + 1}
              />
              <br />
            </>
          )}
        </React.Fragment>
      ))}
    </>
  );
}
