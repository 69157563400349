import { Button } from '@mui/material';
import React from 'react';

interface IProps {
  color?: 'inherit' | 'primary' | 'secondary' | undefined;
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  onClick: () => void;
  loading: boolean;
  label: string;
  loadingLabel: string;
}

export default function LoadingButton({ loading, onClick, color, variant, label, loadingLabel }: IProps) {
  return (
    <Button color={color} variant={variant} onClick={onClick} disabled={loading}>
      {loading ? loadingLabel : label}
    </Button>
  );
}
