import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText } from '@mui/material';
import React, { useState } from 'react';
import { getProductsCountInCategory } from '../../Components/CategoryTreeItem';
import { ICategory } from '@magistrmartin/eshop-frontend-shared';
import { catalogService } from '../../Utils/ApiService';

interface IProps {
  categories: ICategory[];
  products: { id: number; categories: string[] }[];
}

export default function NonEmptyHiddenCategoriesModal({ categories, products }: IProps) {
  const [opened, setOpened] = useState(false);

  const getTargetCategoriesRec = (cat: ICategory) => {
    let result: ICategory[] = [];
    if (!cat.visible && getProductsCountInCategory(cat, products) > 0) {
      result.push(cat);
    }
    cat.subCategories.forEach((c) => (result = result.concat(getTargetCategoriesRec(c))));
    return result;
  };

  const targetCategories = categories.flatMap(getTargetCategoriesRec);

  const makeAllVisible = () => makeVisibleRec(targetCategories, () => window.location.reload());

  const makeVisibleRec: (cats: ICategory[], onDone: () => void) => void = (cats, onDone) => {
    if (cats && cats.length) {
      catalogService.post(
        'categories/add',
        {},
        { ...cats[0], visible: true },
        {
          success: () => makeVisibleRec(cats.slice(1), onDone),
          error: console.log,
        }
      );
    } else {
      onDone();
    }
  };

  return (
    <>
      <Button color="primary" onClick={() => setOpened(true)}>
        Zobrazit skryté neprázdné kategorie
      </Button>
      <Dialog open={opened} onClose={() => setOpened(false)}>
        <DialogTitle>Neprázdné skryté kategorie</DialogTitle>
        <DialogContent>
          <List>
            {targetCategories.map((cat) => (
              <ListItem key={cat.id}>
                <ListItemText primary={`${cat.description} (${cat.id})`} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => makeAllVisible()}>
            Zveřejnit vše
          </Button>
          <Button color="secondary" onClick={() => setOpened(false)}>
            Zavřít
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
