import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useLayout } from '../Layout/LayoutContext';
import { webStructureService } from '../Utils/ApiService';
import { nullOrUndefined } from '../Utils/Utils';
import { DatePicker } from '@mui/x-date-pickers';
import { domains as eshopDomains } from '../Utils/Mutations';

interface ITopNotification {
  text?: string;
  color?: string;
  validFrom?: Date;
  validTo?: Date;
  domain?: string;
}

export default function Notifications() {
  const [topNotification, setTopNotification] = useState<ITopNotification>({});
  const [domainTab, setDomainTab] = useState(0);

  const domains = [...eshopDomains, 'erecept.magistrmartin.cz'];

  const layout = useLayout();
  useEffect(
    () => {
      webStructureService.get(
        '/notifications/top',
        { validOnly: false, domain: domains[domainTab] },
        {
          success: (data: ITopNotification | null) => data && setTopNotification(data),
          error: () => layout.error('Nepodařilo se načíst oznámení, prosím obnovte stránku'),
        }
      );
    },
    //eslint-disable-next-line
    [domainTab]
  );

  const changeDomain = (newDomainIndex: number) => {
    setTopNotification({ domain: domains[newDomainIndex] });
    setDomainTab(newDomainIndex);
  };

  const saveTopNotification = () => {
    webStructureService.post(
      '/notifications/top',
      {
        ...topNotification,
        validFrom: nullOrUndefined(topNotification.validFrom)
          ? undefined
          : new Date(topNotification?.validFrom || '').toISOString().split('T')[0],
        validTo: nullOrUndefined(topNotification.validTo)
          ? undefined
          : new Date(topNotification?.validTo || '').toISOString().split('T')[0],
      },
      null,
      {
        success: () => layout.success('Oznámení byo uloženo'),
        error: () => layout.error('Při ukládání došlo k chybě'),
      }
    );
  };

  const deleteTopNotification = () => {
    layout.deleteConfirm('Smazat oznámení', 'Opravdue checte smazat toto oznámení?', () =>
      webStructureService.delete('/notifications/top', {}, null, {
        success: () => setTopNotification({}),
        error: () => layout.error('Oznámení se nepodařilo smazar'),
      })
    );
  };

  return (
    <>
      <Typography variant="h3">Oznámení v záhlaví stránky</Typography>
      <br />
      <Tabs color="primary" value={domainTab} onChange={(_, val) => changeDomain(val)}>
        {domains.map((d) => (
          <Tab key={d} label={d} />
        ))}
      </Tabs>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            fullWidth
            label="Text oznámení"
            value={topNotification.text || ''}
            onChange={(e) => setTopNotification({ ...topNotification, text: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl variant="standard" fullWidth>
            <InputLabel>Vybrat styl oznámení</InputLabel>
            <Select
              variant="standard"
              value={topNotification.color || ''}
              onChange={(e) => setTopNotification({ ...topNotification, color: e.target.value as string })}
            >
              <MenuItem value="error">Error</MenuItem>
              <MenuItem value="warning">Warning</MenuItem>
              <MenuItem value="info">Info</MenuItem>
              <MenuItem value="success">Success</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePicker
            label="Platí od"
            value={topNotification.validFrom || null}
            onChange={(date) => setTopNotification({ ...topNotification, validFrom: date || undefined })}
            slots={{ textField: TextField }}
            slotProps={{ textField: { fullWidth: true } }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePicker
            label="Platí do"
            value={topNotification.validTo || null}
            onChange={(date) => setTopNotification({ ...topNotification, validTo: date || undefined })}
            slots={{ textField: TextField }}
            slotProps={{ textField: { fullWidth: true } }}
          />
        </Grid>
      </Grid>
      <br />
      <br />
      <a href="https://material-ui.com/components/alert/#filled" target="_blank" rel="noopener noreferrer">
        Ukázka stylů oznámení
      </a>
      <br />
      <br />
      <Button variant="contained" color="primary" onClick={saveTopNotification}>
        Uložit oznámení
      </Button>
      &nbsp;
      <Button variant="contained" color="secondary" onClick={deleteTopNotification}>
        Smazat oznámení
      </Button>
    </>
  );
}
