import React, { useEffect, useState } from 'react';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useLayout } from '../../Layout/LayoutContext';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import { catalogService, ordersService } from '../../Utils/ApiService';
import { ToOrderItem } from '../../Types/order';

interface IProps {
  onAdd: (newItem: ToOrderItem) => void;
}

export default function AddToOrderItemModal({ onAdd }: IProps) {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [newItem, setNewItem] = useState<ToOrderItem>({});
  const [opened, setOpened] = useState(false);

  const layout = useLayout();

  useEffect(() => {
    const fields = ['id', 'title', 'subtitle', 'supplierTag'];
    catalogService.get(
      'products/all',
      {
        fields: fields.join(','),
        mapped: true,
      },
      {
        success: setProducts,
        error: layout.error,
      }
    );
    // eslint-disable-next-line
  }, []);

  const saveChanges = () =>
    ordersService.post('/internal/toOrderItem', { created: true }, newItem, {
      success: (res: ToOrderItem) => {
        onAdd(res);
        setOpened(false);
        setNewItem({});
      },
      error: () => layout.error('Při ukládání došlo k chybě'),
    });

  return (
    <>
      <Button color="primary" onClick={() => setOpened(true)}>
        Přidat produkt do evidence
      </Button>
      <Dialog maxWidth="lg" open={opened} onClose={() => setOpened(false)} fullWidth>
        <DialogTitle title="Přidat produkt k doobědnání" />
        <DialogContent>
          <Autocomplete
            value={{ id: 0, label: '', p: undefined }}
            onChange={(e, val) =>
              val?.p &&
              setNewItem({
                ...newItem,
                productFullName: `${val.p.title} ${val.p.subtitle}`,
                supplier: val.p.supplierTag,
                productId: val.p.id,
              })
            }
            fullWidth
            options={[
              { id: 0, label: '', p: undefined },
              ...products.map((p) => ({
                id: p.id,
                label: `${p.title} ${p.subtitle} (${p.supplierTag})`,
                p: p,
              })),
            ]}
            renderInput={(p) => <TextField label="Vybrat produkt" {...p} />}
          />
          <br />
          <TextField
            variant="standard"
            label="Poznámka"
            fullWidth
            onChange={(e) => setNewItem({ ...newItem, remark: e.target.value })}
            value={newItem.remark || ''}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setOpened(false)}>
            Zrušit
          </Button>
          <Button color="primary" disabled={newItem.productId === undefined} onClick={saveChanges}>
            Přidat
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
