import { Grid, List, ListItem, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLayout } from '../Layout/LayoutContext';
import { catalogService } from '../Utils/ApiService';
import { ICategory } from '@magistrmartin/eshop-frontend-shared';
import CategoryTreeItem from '../Components/CategoryTreeItem';
import { reducers } from '../Utils/Reducers';
import CategoryEditor from '../Components/CategoryEditor';
import NonEmptyHiddenCategoriesModal from './Modals/NonEmptyHiddenCategoriesModal';

export default function Categories() {
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [products, setProducts] = useState<{ id: number; categories: string[] }[]>([]);
  const [newSub, setNewSub] = useState('');
  const [editItem, setEditItem] = useState<ICategory | undefined>(undefined);
  const layout = useLayout();

  const getCategoryById = (id: string, root: ICategory): ICategory | undefined => {
    if (root.id === id) return root;
    for (let i = 0; i < root.subCategories.length; i++) {
      const c = getCategoryById(id, root.subCategories[i]);
      if (c !== undefined) return c;
    }
    return undefined;
  };

  const getParentCategory = (cat: ICategory) => {
    let splitId = cat.id.split('.');
    splitId.pop();
    const desiredId = splitId.join('.');
    if (desiredId.length === 0) return undefined;
    let parent = undefined;
    for (let i = 0; i < categories.length; i++) {
      parent = getCategoryById(desiredId, categories[i]);
      if (parent !== undefined) return parent;
    }
    return undefined;
  };

  const getPrevCategory = () => {
    const cats = getParentCategory(editItem as ICategory)?.subCategories || categories;
    const index =
      cats
        .map((c, i) => {
          return { id: c.id, ind: i };
        })
        .filter((x) => x.id === editItem?.id)[0]?.ind || 0;
    return index > 0 ? cats[index - 1] : undefined;
  };

  const getNextCategory = () => {
    const cats = getParentCategory(editItem as ICategory)?.subCategories || categories;
    const index = cats
      .map((c, i) => {
        return { id: c.id, ind: i };
      })
      .filter((x) => x.id === editItem?.id)[0]?.ind;
    return index !== undefined && index < cats.length - 1 ? cats[index + 1] : undefined;
  };

  const newId = (
    '0' +
    categories
      .map<number>((c) => parseInt(c.id) + 1)
      .reduce<number>(reducers.max, 1)
      .toString()
  ).slice(-2);

  useEffect(() => {
    layout.setIsLoading(true);
    catalogService.get(
      'categories/noauth/tree',
      {
        all: true,
      },
      {
        success: (cats: ICategory[]) => {
          layout.setIsLoading(false);
          setCategories(cats);
        },
        error: layout.error,
      }
    );
    catalogService.get('/products/all', { fields: 'id,categories' }, { success: setProducts, error: console.log });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} lg={4}>
          <List>
            {categories.map((c, i) => (
              <CategoryTreeItem
                key={c.id}
                root={c}
                products={products}
                changeSubcategories={(newSubs) => {
                  const newCategories = [...categories];
                  newCategories[i] = {
                    ...newCategories[i],
                    subCategories: newSubs,
                  };
                  setCategories(newCategories);
                }}
                remove={() =>
                  catalogService.delete('categories/remove', { id: c.id }, null, {
                    success: () => setCategories(categories.filter((s) => s.id !== c.id)),
                    error: layout.error,
                  })
                }
                setEditItem={setEditItem}
              />
            ))}
            <ListItem>
              <TextField
                variant="standard"
                label="Přidat kategorii"
                fullWidth
                value={newSub}
                onChange={(e) => setNewSub(e.target.value)}
                onBlur={() => {
                  if (newSub.length > 0) {
                    catalogService.post(
                      'categories/add',
                      {},
                      {
                        description: newSub,
                        id: newId,
                      },
                      {
                        success: () => {
                          setNewSub('');
                          setCategories([
                            ...categories,
                            {
                              description: newSub,
                              id: newId,
                              subCategories: [],
                              visible: true,
                            },
                          ]);
                        },
                        error: layout.error,
                      }
                    );
                  }
                }}
              />
            </ListItem>
          </List>
          <br />
          <NonEmptyHiddenCategoriesModal categories={categories} products={products} />
        </Grid>

        {editItem && (
          <Grid item xs={12} md={6} lg={8}>
            <CategoryEditor
              prevCategory={getPrevCategory()}
              nextCategory={getNextCategory()}
              category={editItem}
              onSave={(cat: ICategory) => window.location.reload()}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
