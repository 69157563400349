import React from 'react';
import { Autocomplete, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { IProduct, ISelectsConfig } from '@magistrmartin/eshop-frontend-shared';
import { IProductStamp } from './EditProduct';
import { getKeyExtractorComparer } from '../../Utils/Comparers';
import MultiSelect from '../../Components/MultiSelect';

interface IProps {
  product: IProduct;
  changeProduct: (attr: keyof IProduct, val: any) => void;
  selectsConfig?: ISelectsConfig;
  allProducts: IProductStamp[];
}

export default function DetailsEditor({ changeProduct, product, selectsConfig, allProducts }: IProps) {
  return (
    <>
      {selectsConfig && (
        <>
          <Autocomplete
            value={{
              id: product?.manufacturer || '',
              label: selectsConfig.manufacturers.find((c) => c.id?.toString() === product.manufacturer)?.value || '',
            }}
            onChange={(e, val) => val && changeProduct('manufacturer', val.id)}
            options={selectsConfig.manufacturers
              .sort(getKeyExtractorComparer('value'))
              .map((c) => ({ id: c.id?.toString(), label: c.value }))}
            fullWidth
            isOptionEqualToValue={(o, v) => o.id === v.id}
            renderInput={(p) => <TextField label="Výrobce" {...p} />}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id || -1}>
                  {option.label}
                </li>
              );
            }}
          />
          <Autocomplete
            value={{
              id: product?.supplier || '',
              label:
                selectsConfig.suppliers
                  .filter((s) => s.id?.toString() === product?.supplier)
                  .map((s) => `${s.tag}${(s.tag?.length || 0) > 0 ? ' - ' : ''}${s.name}`)[0] || '',
            }}
            onChange={(e, val) => val && changeProduct('supplier', val.id?.toString())}
            options={selectsConfig.suppliers
              .sort((a, b) =>
                `${a.tag}${(a.tag?.length || 0) > 0 ? ' - ' : ''}${a.name}` <
                `${b.tag}${(b.tag?.length || 0) > 0 ? ' - ' : ''}${b.name}`
                  ? -1
                  : 1
              )
              .map((s) => ({
                id: s.id?.toString(),
                label: `${s.tag}${(s.tag?.length || 0) > 0 ? ' - ' : ''}${s.name}`,
              }))}
            fullWidth
            isOptionEqualToValue={(o, v) => o.id === v.id}
            renderInput={(p) => <TextField label="Dodavatel na trh" {...p} />}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id || -1}>
                  {option.label}
                </li>
              );
            }}
          />
          <Autocomplete
            value={{
              id: product?.brandMark || '',
              label: selectsConfig.brandmarks.find((c) => c.id?.toString() === product.brandMark)?.value || '',
            }}
            onChange={(e, val) => val && changeProduct('brandMark', val.id)}
            options={selectsConfig.brandmarks
              .sort(getKeyExtractorComparer('value'))
              .map((c) => ({ id: c.id?.toString(), label: c.value }))}
            fullWidth
            isOptionEqualToValue={(o, v) => o.id === v.id}
            renderInput={(p) => <TextField label="Značka" {...p} />}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id || -1}>
                  {option.label}
                </li>
              );
            }}
          />
          <Autocomplete
            value={{
              id: product?.series || '',
              label: selectsConfig.productSeries.find((c) => c.id?.toString() === product.series)?.value || '',
            }}
            onChange={(e, val) => val && changeProduct('series', val.id)}
            options={selectsConfig.productSeries
              .sort(getKeyExtractorComparer('value'))
              .map((c) => ({ id: c.id?.toString(), label: c.value }))}
            fullWidth
            isOptionEqualToValue={(o, v) => o.id === v.id}
            renderInput={(p) => <TextField label="Produktová řada" {...p} />}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id || -1}>
                  {option.label}
                </li>
              );
            }}
          />
          <Autocomplete
            value={{
              id: product?.pharmaGroup || '',
              label: selectsConfig.pharmaGroups.find((c) => c.id?.toString() === product.pharmaGroup)?.value || '',
            }}
            onChange={(e, val) => val && changeProduct('pharmaGroup', val.id)}
            options={selectsConfig.pharmaGroups
              .sort(getKeyExtractorComparer('value'))
              .map((c) => ({ id: c.id?.toString(), label: c.value }))}
            fullWidth
            isOptionEqualToValue={(o, v) => o.id === v.id}
            renderInput={(p) => <TextField label="Farmakoterapeutická skupina" {...p} />}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id || -1}>
                  {option.label}
                </li>
              );
            }}
          />
        </>
      )}
      <TextField
        variant="standard"
        fullWidth
        label="Kód SÚKL"
        value={product?.sukl || ''}
        // onChange={(e) => changeProduct("sukl", e.target.value.substring(Math.max(e.target.value.length - 7, 0)).padStart(7, "0"))}
        onChange={(e) => changeProduct('sukl', e.target.value.substring(Math.max(e.target.value.length - 7, 0)))}
      />
      <TextField
        variant="standard"
        fullWidth
        type="number"
        label="Věková osa"
        value={product?.age === undefined ? '' : product.age}
        onChange={(e) => changeProduct('age', parseFloat(e.target.value))}
      />
      <TextField
        variant="standard"
        fullWidth
        type="number"
        label="Hmotnostní osa"
        value={product?.mass === undefined ? '' : product.mass}
        onChange={(e) => changeProduct('mass', parseFloat(e.target.value))}
      />
      <TextField
        variant="standard"
        fullWidth
        label="Klíčová slova"
        value={product?.searchKeywords === undefined ? '' : product.searchKeywords}
        onChange={(e) => changeProduct('searchKeywords', e.target.value)}
      />
      <TextField
        variant="standard"
        fullWidth
        label="Vyhledávání na Heuréce"
        value={product?.heurekaSearch === undefined ? '' : product.heurekaSearch}
        onChange={(e) => changeProduct('heurekaSearch', e.target.value)}
      />
      {selectsConfig && (
        <>
          <br />
          <br />
          <MultiSelect
            label="Aktivní látky"
            addLabel="Přidat aktivní látku"
            chipLabelSelector={(val, options) =>
              (options?.filter((s) => (s.id?.toString() || '') === val.toString())[0] || { value: '' }).value
            }
            keyPropFn={(s) => s.id}
            valuePropFn={(s) => s.value}
            onChange={(newVal) => changeProduct('activeSubstances', newVal)}
            options={selectsConfig.drugs.sort(getKeyExtractorComparer('value'))}
            value={product.activeSubstances || []}
          />
        </>
      )}
      <br />
      <br />
      {allProducts && (
        <>
          <MultiSelect
            label="Další velikosti"
            addLabel="Přidat další produkt"
            chipLabelSelector={(val, options) =>
              options
                ?.filter((prd) => prd.id.toString() === val?.toString())
                .map((prd) => `${prd.title} ${prd.subtitle}`)[0]
            }
            keyPropFn={(prd) => prd.id}
            valuePropFn={(prd) => `${prd.title} ${prd.subtitle}`}
            onChange={(newVal) => changeProduct('alternatives', newVal)}
            options={allProducts.filter((p) => p.id !== product.id).sort(getKeyExtractorComparer('title'))}
            value={product.alternatives || []}
            showAll={false}
          />

          <MultiSelect
            label="Další příchutě"
            addLabel="Přidat další produkt"
            chipLabelSelector={(val, options) =>
              options
                ?.filter((prd) => prd.id.toString() === val?.toString())
                .map((prd) => `${prd.title} ${prd.subtitle}`)[0]
            }
            keyPropFn={(prd) => prd.id}
            valuePropFn={(prd) => `${prd.title} ${prd.subtitle}`}
            onChange={(newVal) => changeProduct('alternativeTastes', newVal)}
            options={allProducts.filter((p) => p.id !== product.id).sort(getKeyExtractorComparer('title'))}
            value={product.alternativeTastes || []}
            showAll={false}
          />

          <MultiSelect
            label="Další lékové formy"
            addLabel="Přidat další produkt"
            chipLabelSelector={(val, options) =>
              options
                ?.filter((prd) => prd.id.toString() === val?.toString())
                .map((prd) => `${prd.title} ${prd.subtitle}`)[0]
            }
            keyPropFn={(prd) => prd.id}
            valuePropFn={(prd) => `${prd.title} ${prd.subtitle}`}
            onChange={(newVal) => changeProduct('alternativeForms', newVal)}
            options={allProducts.filter((p) => p.id !== product.id).sort(getKeyExtractorComparer('title'))}
            value={product.alternativeForms || []}
            showAll={false}
          />

          <MultiSelect
            label="Kombinujte s"
            addLabel="Přidat další produkt"
            chipLabelSelector={(val, options) =>
              options
                ?.filter((prd) => prd.id.toString() === val?.toString())
                .map((prd) => `${prd.title} ${prd.subtitle}`)[0]
            }
            keyPropFn={(prd) => prd.id}
            valuePropFn={(prd) => `${prd.title} ${prd.subtitle}`}
            onChange={(newVal) => changeProduct('combineWith', newVal)}
            options={allProducts.filter((p) => p.id !== product.id).sort(getKeyExtractorComparer('title'))}
            value={product.combineWith || []}
            showAll={false}
          />
        </>
      )}
      <FormControlLabel
        control={<Checkbox color="primary" checked={product.published} />}
        label="Zveřejněn v eshopu"
        disabled={product.isRx || product.isObsolete}
        onClick={() => !product.isRx && !product.isObsolete && changeProduct('published', !product.published)}
      />
      <FormControlLabel
        control={<Checkbox color="primary" checked={product.unavailable} />}
        label="Výpadek"
        onClick={() => changeProduct('unavailable', !product.unavailable)}
      />
      <FormControlLabel
        control={<Checkbox color="primary" checked={product.isObsolete} />}
        label="Zastaralý"
        disabled={product.published}
        onClick={() => !product.published && changeProduct('isObsolete', !product.isObsolete)}
      />
      <FormControlLabel
        control={<Checkbox color="primary" checked={product.blockOnHeureka} />}
        label="Blokováno na Heuréce"
        onClick={() => changeProduct('blockOnHeureka', !product.blockOnHeureka)}
      />
    </>
  );
}
