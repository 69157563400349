import { Button, Dialog, DialogActions, DialogContent, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import { ISubstance } from '@magistrmartin/eshop-frontend-shared';
import { useLayout } from '../../Layout/LayoutContext';
import { catalogService } from '../../Utils/ApiService';

interface IProps {
  allSubstances: ISubstance[];
  addNewSubstances: (newSubstances: ISubstance[]) => void;
  onSave: (substanceToAdd: ISubstance[]) => void;
}

export default function HelpSubstanceGroupAdd({ addNewSubstances, allSubstances, onSave }: IProps) {
  const [open, setOpen] = useState(false);
  const [rawValue, setRawValue] = useState('');
  const [delimiter, setDelimiter] = useState(',');

  const layout = useLayout();

  const saveChanges = () => {
    const rawSubstances = rawValue.split(delimiter).filter((r) => r.length > 0);
    if (rawSubstances.length === 0) return;
    const foundSubstances = rawSubstances
      .map((s) =>
        allSubstances.find(
          (ss) =>
            ss.name?.toLowerCase()?.trim() === s.trim().toLowerCase() || ss.otherNames?.includes(s.toLowerCase().trim())
        )
      )
      .filter((s) => s !== undefined) as ISubstance[];
    const notFoundSubstances = rawSubstances.filter(
      (s) =>
        allSubstances.find(
          (ss) =>
            ss.name?.toLowerCase()?.trim() === s.trim().toLowerCase() || ss.otherNames?.includes(s.toLowerCase().trim())
        ) === undefined
    );
    if (notFoundSubstances.length > 0) {
      layout.confirm('Následující látky nebyly nalezeny a budou přidány', notFoundSubstances.join(', '), () =>
        catalogService.post(
          '/products/substances',
          {},
          notFoundSubstances.map(
            (s) =>
              ({
                name: s,
                noColoring: false,
                noConservants: false,
                noGluten: false,
                noLactose: false,
                noSugar: false,
                noSweeteners: false,
                otherNames: '',
              }) as ISubstance
          ),
          {
            success: (newSubstances: ISubstance[]) => {
              addNewSubstances(newSubstances);
              onSave([...foundSubstances, ...newSubstances]);
              setOpen(false);
              setRawValue('');
            },
            error: () => layout.error('Při ukládání látek došlo k chybě'),
          }
        )
      );
    } else {
      onSave(foundSubstances);
      setOpen(false);
      setRawValue('');
    }
  };

  return (
    <>
      <Button color="primary" onClick={() => setOpen(true)}>
        Hromadné přidání pomocných látek
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={10}>
              <TextField
                label="Pomocné látky"
                fullWidth
                value={rawValue}
                onChange={(e) => setRawValue(e.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField label="Oddělovač" fullWidth value={delimiter} onChange={(e) => setDelimiter(e.target.value)} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setOpen(false)}>
            Zavřít
          </Button>
          <Button disabled={rawValue.length === 0} color="primary" onClick={saveChanges}>
            Uložit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
