import React, { useEffect, useState } from 'react';
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Toolbar,
  Tooltip,
} from '@mui/material';
import { useLayout } from '../Layout/LayoutContext';
import { catalogService } from '../Utils/ApiService';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import {
  ArrowDropDown,
  ArrowDropUp,
  Cancel,
  CheckCircle,
  Delete,
  Edit,
  Image,
  Lens,
  FileCopy,
  Warning,
  Lock,
  LockOpen,
  Circle,
  Error as ErrorIcon,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { Pagination } from '@mui/material';
import { getNumberComparer, getStringComparer } from '../Utils/Comparers';
import ProductsFilters, { deserializeActiveFilters, serializeActiveFilters } from './Modals/ProductsFilters';
import ChangePrdId from './Modals/ChangePrdId';
import { getAbsoluteMargins, getRelativeMargins, getMarginsColor, removeDiacritics } from '../Utils/Utils';
import SetCentralTradeSurchage from './Modals/SetCentralTradeSurchage';
import ProductsChildRowEditor from '../Components/ProductsChildRowEditor';
import { createStyles, makeStyles } from '@mui/styles';
import ViewProductPositionModal from './Modals/ViewProductPositionModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      flexGrow: 1,
      marginLeft: 16,
      marginRight: 16,
    },
    pointer: {
      cursor: 'pointer',
    },
    itemRoot: {
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    flexGrow: {
      flexGrow: 1,
    },
    flex: {
      display: 'flex',
    },
    left: {
      float: 'left',
    },
    smallCell: {
      padding: '6px 6px',
    },
  })
);

export interface ISort {
  key: keyof IProduct | 'raw';
  direction: 'asc' | 'desc';
  comparerGetter: (direction: 'asc' | 'desc') => (a: any, b: any) => number;
}

const getMarginsComparer = (direction: 'asc' | 'desc') =>
  direction === 'asc'
    ? (a: IProduct, b: IProduct) => getRelativeMargins(a) - getRelativeMargins(b)
    : (a: IProduct, b: IProduct) => getRelativeMargins(b) - getRelativeMargins(a);

const getFunctionFromKey = (key: keyof IProduct | 'raw') => {
  if (key === 'excelOrder') return getNumberComparer;
  if (key === 'id') return getNumberComparer;
  if (key === 'title') return getStringComparer;
  if (key === 'subtitle') return getStringComparer;
  if (key === 'supplierTag') return getStringComparer;
  if (key === 'reccomendedPrice') return getNumberComparer;
  if (key === 'actionPrice') return getNumberComparer;
  if (key === 'physicalStorePrice') return getNumberComparer;
  if (key === 'buyingPrice') return getNumberComparer;
  if (key === 'raw') return getMarginsComparer;
  return (direction: 'asc' | 'desc') => (a: any, b: any) => 1;
};

export const serializeSort = (sort: ISort) => {
  const s = { ...sort } as any;
  delete s['comparerGetter'];
  return JSON.stringify(s);
};

export const deserializeSort = (raw?: string | null) => {
  if (raw === undefined || raw === null) return undefined;
  const res = JSON.parse(raw) as ISort;
  res.comparerGetter = getFunctionFromKey(res.key);
  return res;
};

export const SortIcon = ({ sort, colKey }: { sort?: ISort; colKey: keyof IProduct | 'raw' }) => {
  if (sort === undefined || sort.key !== colKey) return <></>;
  if (sort.direction === 'asc') return <ArrowDropUp />;
  return <ArrowDropDown />;
};

export default function Products() {
  const [products, setProducts] = useState<IProduct[] | undefined>(undefined);
  const [commonTradeSurcharge, setCommonTradeSurcharge] = useState(0);
  const [filters, setFiltersRaw] = useState<{ [index: string]: (prd: IProduct) => boolean }>(
    deserializeActiveFilters(localStorage.getItem('productsFilters')?.split(';') || [])
  );
  const [search, setSearchRaw] = useState(localStorage.getItem('productsSearch') || '');
  const [sort, setSortRaw] = useState<ISort | undefined>(deserializeSort(localStorage.getItem('productsSort')));
  const [page, setPageRaw] = useState(parseInt(localStorage.getItem('productsPage') || '1'));
  const [childRows, setChildRows] = useState<number[]>([]);

  const layout = useLayout();
  const history = useHistory();
  const classes = useStyles();

  const setPage = (val: number) => {
    localStorage.setItem('productsPage', val.toString());
    setPageRaw(val);
  };

  const setSearch = (val: string) => {
    localStorage.setItem('productsSearch', val.toString());
    setSearchRaw(val);
  };

  const setSort = (val?: ISort) => {
    if (val === undefined) localStorage.removeItem('productsSort');
    else localStorage.setItem('productsSort', serializeSort(val));
    setSortRaw(val);
  };

  const setFilters = (val: { [index: string]: (prod: IProduct) => boolean }) => {
    localStorage.setItem('productsFilters', serializeActiveFilters(val).join(';'));
    setFiltersRaw(val);
  };

  const rowsPerPage = 25;

  const changeSort = (
    key: keyof IProduct | 'raw',
    comparerGetter: (direction: 'asc' | 'desc') => (a: any, b: any) => number
  ) => {
    if (sort === undefined || sort.key !== key) setSort({ key: key, direction: 'asc', comparerGetter: comparerGetter });
    else if (sort?.direction === 'asc') setSort({ key: key, direction: 'desc', comparerGetter: comparerGetter });
    else setSort(undefined);
  };

  const searchFilter = (p: IProduct) => {
    const s = search.toLowerCase();
    const affectedCols = [
      p.id.toString(),
      p.title || '',
      p.subtitle || '',
      p.actionPrice?.toString() || '0',
      p.reccomendedPrice?.toString() || '0',
      p.supplierTag || '',
    ];
    return affectedCols.reduce(
      (acc, act) => acc || removeDiacritics(act.toLowerCase()).includes(removeDiacritics(s)),
      false
    );
  };

  //eslint-disable-next-line
  let filteredProducts = products || [];
  Object.keys(filters).forEach((k) => (filteredProducts = filteredProducts.filter(filters[k])));
  if (search.length > 0) filteredProducts = filteredProducts.filter(searchFilter);

  if (sort !== undefined) {
    if (sort.key === 'raw') {
      filteredProducts = filteredProducts.sort((a, b) => sort.comparerGetter(sort.direction)(a, b));
    } else {
      filteredProducts = filteredProducts.sort((a, b) =>
        sort.comparerGetter(sort.direction)(
          a[sort.key as keyof IProduct]?.toString() || '',
          b[sort.key as keyof IProduct]?.toString() || ''
        )
      );
    }
  }
  const showProducts = filteredProducts.filter((_, i) => i >= (page - 1) * rowsPerPage && i < rowsPerPage * page);

  useEffect(() => {
    localStorage.setItem('currentProductsOrder', JSON.stringify(filteredProducts.map((p) => p.id)));
  }, [filteredProducts]);

  useEffect(() => {
    catalogService.get(
      '/products/commonTradeSurcharge',
      {},
      {
        success: setCommonTradeSurcharge,
        error: console.log,
      }
    );
  }, []);

  useEffect(() => {
    layout.setIsLoading(true);
    const fields = [
      'images',
      'id',
      'title',
      'subtitle',
      'actionPrice',
      'published',
      'reccomendedPrice',
      'supplierTag',
      'excelOrder',
      'buyingPrice',
      'tradeSurchage',
      'tradeDiscount',
      'buyingRabatBase',
      'buyingRabatFree',
      'bonification',
      'invoiceBonus',
      'sellout',
      'doNotScann',
      'lastPharmosScann',
      'centralDistribution',
      'lastScanbuyingPriceChange',
      'physicalStorePrice',
      'personalRemark',
      'stockAmmount',
      'isRegulated',
      'productMass',
      'productHeight',
      'productWidth',
      'productDepth',
      'groupPackageAmmount',
      'higherGroupPackageAmmount',
      'maxExpirationLength',
      'preferention',
      'isMain',
      'ean',
      'packageChanged',
      'isObsolete',
      'isRx',
      'heurekaStatus',
      'unavailable',
      'customHeurekaPrice',
      'ambiguousHeurekaParing',
      'preferredSupplier',
    ];
    catalogService.get(
      'products/all',
      {
        fields: fields.join(','),
        mapped: true,
      },
      {
        success: (data: IProduct[]) => {
          layout.setIsLoading(false);
          setProducts(data);
          setTimeout(() => {
            if (sessionStorage.getItem('lastClickedPdk') !== null) {
              document.getElementById(`pdk${sessionStorage.getItem('lastClickedPdk')}`)?.scrollIntoView();
            }
          }, 300);
        },
        error: layout.error,
      }
    );
    // eslint-disable-next-line
  }, []);

  return products === undefined ? (
    <></>
  ) : (
    <>
      <Toolbar>
        <ProductsFilters filters={filters} setFilters={setFilters} />
        <TextField
          variant="standard"
          margin="dense"
          className={classes.search}
          label="vyhledat"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Pagination
          count={Math.ceil(filteredProducts.length / rowsPerPage)}
          showFirstButton
          showLastButton
          page={page}
          onChange={(_, val) => setPage(val)}
          color="primary"
        />
        <TextField
          variant="standard"
          style={{ width: 64 }}
          value={page}
          type="number"
          onChange={(e) => setPage(parseInt(e.target.value))}
        />
      </Toolbar>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell
              className={`${classes.pointer} ${classes.smallCell}`}
              onClick={() => changeSort('excelOrder', getNumberComparer)}
            >
              # <SortIcon sort={sort} colKey="excelOrder" />
            </TableCell>
            <TableCell
              className={`${classes.pointer} ${classes.smallCell}`}
              onClick={() => changeSort('id', getNumberComparer)}
            >
              ID (PDK)
              <SortIcon sort={sort} colKey="id" />
            </TableCell>
            <TableCell className={classes.smallCell}>Pref</TableCell>
            <TableCell
              className={`${classes.pointer} ${classes.smallCell}`}
              onClick={() => changeSort('title', getStringComparer)}
            >
              Název <SortIcon sort={sort} colKey="title" />
            </TableCell>
            <TableCell
              className={`${classes.pointer} ${classes.smallCell}`}
              onClick={() => changeSort('subtitle', getStringComparer)}
            >
              Vedlejší název <SortIcon sort={sort} colKey="subtitle" />
            </TableCell>
            <TableCell
              className={`${classes.pointer} ${classes.smallCell}`}
              onClick={() => changeSort('supplierTag', getStringComparer)}
            >
              Dodavatel <SortIcon sort={sort} colKey="supplierTag" />
            </TableCell>
            <TableCell
              className={`${classes.pointer} ${classes.smallCell}`}
              onClick={() => changeSort('reccomendedPrice', getNumberComparer)}
            >
              DC <SortIcon sort={sort} colKey="reccomendedPrice" />
            </TableCell>
            <TableCell
              className={`${classes.pointer} ${classes.smallCell}`}
              onClick={() => changeSort('actionPrice', getNumberComparer)}
            >
              AC <SortIcon sort={sort} colKey="actionPrice" />
            </TableCell>
            <TableCell
              className={`${classes.pointer} ${classes.smallCell}`}
              onClick={() => changeSort('physicalStorePrice', getNumberComparer)}
            >
              CVL <SortIcon sort={sort} colKey="physicalStorePrice" />
            </TableCell>
            <TableCell className={classes.smallCell}>CD</TableCell>
            <TableCell className={classes.smallCell}>IC</TableCell>
            <TableCell
              className={`${classes.pointer} ${classes.smallCell}`}
              onClick={() => changeSort('raw', getMarginsComparer)}
            >
              Marže <SortIcon sort={sort} colKey="raw" />
            </TableCell>
            <TableCell className={classes.smallCell}>Zveřejněn</TableCell>
            <TableCell className={classes.smallCell}>Zastaralý</TableCell>
            <TableCell className={classes.smallCell}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {showProducts?.map((p, i) => (
            <React.Fragment key={p.id}>
              <TableRow id={`pdk${p.id}`}>
                <TableCell className={classes.smallCell} style={{ minWidth: 74 }}>
                  <IconButton
                    aria-label="childRow"
                    onClick={() => {
                      setChildRows(
                        childRows.includes(p.id) ? childRows.filter((ch) => ch !== p.id) : [...childRows, p.id]
                      );
                    }}
                    size="large"
                  >
                    {(childRows.includes(p.id) && <ArrowDropUp />) || <ArrowDropDown />}
                  </IconButton>
                  {p.packageChanged ? (
                    <Tooltip title="U tohoto produktu došlo ke změně obalu. Kliknutím tento příznak odstraníte.">
                      <IconButton
                        onClick={() =>
                          layout.confirm('Opravdu chcete zrušit příznak, že došlo ke změně obalu?', '', () =>
                            catalogService.post(
                              '/products/changePackageChanged',
                              {
                                id: p.id,
                                newValue: false,
                              },
                              null,
                              {
                                success: () =>
                                  setProducts(
                                    products.map((pp) => (pp.id === p.id ? { ...p, packageChanged: false } : pp))
                                  ),
                                error: () => layout.error('Při změně obalu došlo k chybě'),
                              }
                            )
                          )
                        }
                        size="large"
                      >
                        <Warning color="error" />
                      </IconButton>
                    </Tooltip>
                  ) : (p.images?.filter((i) => i.length > 0) || []).length > 0 ? (
                    <Image color="primary" />
                  ) : (
                    <Image color="error" />
                  )}{' '}
                  <Tooltip
                    title={
                      (p.isObsolete
                        ? 'Zastaralý produkt'
                        : p.heurekaStatus === 'best'
                        ? 'Nejlevnější na heurece'
                        : p.heurekaStatus === 'paired'
                        ? 'Spárováno s heurékou, cena není nejnižší'
                        : 'Nespárováno s heurékou') +
                      (p.ambiguousHeurekaParing ? ' (bylo nalezeno více než 1 produkt při párování)' : '')
                    }
                  >
                    {p.ambiguousHeurekaParing ? (
                      <ErrorIcon
                        color={
                          p.isObsolete
                            ? 'disabled'
                            : p.heurekaStatus === 'best'
                            ? 'success'
                            : p.heurekaStatus === 'paired'
                            ? 'warning'
                            : 'error'
                        }
                      />
                    ) : (
                      <Circle
                        color={
                          p.isObsolete
                            ? 'disabled'
                            : p.heurekaStatus === 'best'
                            ? 'success'
                            : p.heurekaStatus === 'paired'
                            ? 'warning'
                            : 'error'
                        }
                      />
                    )}
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.smallCell}>{p.excelOrder}</TableCell>
                <TableCell className={classes.smallCell}>{p.id}</TableCell>
                <TableCell className={classes.smallCell}>
                  <div style={{ display: 'flex' }}>
                    <Lens
                      onClick={() => {
                        catalogService.post(
                          'products/changePreferention',
                          { id: p.id, newPreferention: 1 + ((p.preferention || 3) % 3) },
                          null,
                          {
                            success: () => {
                              layout.success('Preference úspěšně změněna');
                              setProducts(
                                products.map((prod) =>
                                  prod.id === p.id ? { ...prod, preferention: 1 + ((p.preferention || 3) % 3) } : prod
                                )
                              );
                            },
                            error: () => layout.error('Při změně došlo k chybě'),
                          }
                        );
                      }}
                      style={{
                        cursor: 'pointer',
                        color: p.preferention === 1 ? 'green' : p.preferention === 2 ? 'orange' : 'red',
                      }}
                    />
                    <Lens style={{ cursor: 'pointer', color: p.isMain ? 'green' : 'grey' }} />
                  </div>
                </TableCell>
                <TableCell className={classes.smallCell}>{p.title}</TableCell>
                <TableCell className={classes.smallCell}>{p.subtitle}</TableCell>
                <TableCell className={classes.smallCell}>{p.supplierTag}</TableCell>
                <TableCell className={classes.smallCell}>{p.reccomendedPrice}</TableCell>
                <TableCell className={classes.smallCell}>{p.actionPrice}</TableCell>
                <TableCell className={classes.smallCell}>{p.physicalStorePrice}</TableCell>
                <TableCell className={classes.smallCell}>
                  <IconButton
                    aria-label="centralDistribution"
                    onClick={() =>
                      layout.confirm(
                        'Změnit centrální distribuci',
                        'Opravdu chcete změnit pole centrální distribuce tohoto produktu?',
                        () => {
                          catalogService.post(
                            'products/changeCentralDistribution',
                            { id: p.id, isDistributed: ((p.centralDistribution || 0) + 1) % 2 },
                            null,
                            {
                              success: () => {
                                layout.success('Centrální distribuce byla změněna');
                                setProducts(
                                  products.map((prod) =>
                                    prod.id === p.id
                                      ? { ...prod, centralDistribution: ((prod.centralDistribution || 0) + 1) % 2 }
                                      : prod
                                  )
                                );
                              },
                              error: () => layout.error('Při změně došlo k chybě'),
                            }
                          );
                        }
                      )
                    }
                    size="large"
                  >
                    {p.centralDistribution === 1 ? (
                      <CheckCircle style={{ fill: '#1ae300' }} />
                    ) : p.centralDistribution === 2 ? (
                      <CheckCircle style={{ fill: '#ff6a00' }} />
                    ) : (
                      <Cancel color="disabled" />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell className={classes.smallCell}>
                  {p.doNotScann ? <CheckCircle style={{ fill: '#FFA500' }} /> : <Cancel color="disabled" />}
                </TableCell>
                <TableCell className={classes.smallCell} style={{ color: getMarginsColor(getRelativeMargins(p)) }}>
                  <span className={classes.left}>
                    <b>
                      {getAbsoluteMargins(p)} Kč
                      <br />
                      {getRelativeMargins(p)} %
                    </b>
                  </span>
                </TableCell>
                <TableCell className={classes.smallCell}>
                  <IconButton
                    disabled={p.isObsolete || p.isRx}
                    aria-label="publish"
                    onClick={() =>
                      layout.confirm(
                        'Změnit vypublikování na webu',
                        'Opravdu chcete vypublikovat/stáhnout tento produkt?',
                        () => {
                          catalogService.post(
                            'products/publish',
                            { id: p.id, published: !(p.published === true) },
                            null,
                            {
                              success: () => {
                                layout.success('Dostupnost v eshopu úspěšně změněna');
                                setProducts(
                                  products.map((prod) =>
                                    prod.id === p.id ? { ...prod, published: !prod.published } : prod
                                  )
                                );
                              },
                              error: () => layout.error('Při změně došlo k chybě'),
                            }
                          );
                        }
                      )
                    }
                    size="large"
                  >
                    {p.published ? <CheckCircle color="primary" /> : <Cancel color="error" />}
                  </IconButton>
                </TableCell>
                <TableCell className={classes.smallCell}>
                  <IconButton
                    aria-label="isObsolete"
                    onClick={() =>
                      catalogService.post(
                        'products/changeIsObsolete',
                        { id: p.id, isObsolete: !(p.isObsolete === true) },
                        null,
                        {
                          success: () => {
                            layout.success('Změny byly úspěšně uloženy');
                            setProducts(
                              products.map((prod) =>
                                prod.id === p.id ? { ...prod, isObsolete: !prod.isObsolete, published: false } : prod
                              )
                            );
                          },
                          error: () => layout.error('Při změně došlo k chybě'),
                        }
                      )
                    }
                    size="large"
                  >
                    {p.isObsolete ? <Lock color="warning" /> : <LockOpen color="disabled" />}
                  </IconButton>
                </TableCell>
                <TableCell className={classes.smallCell} style={{ minWidth: 246 }}>
                  <IconButton
                    aria-label="edit"
                    onClick={() => {
                      sessionStorage.setItem('lastClickedPdk', p.id.toString());
                      history.push(`/products/edit/${p.id}`);
                    }}
                    size="large"
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      layout.deleteConfirm(
                        'Opravdu chcete smazat tento produkt?',
                        `Produkt [${p.id}] ${p.title} ${p.subtitle} bude trvale smazán.`,
                        () => {
                          catalogService.delete('/products/', { id: p.id }, null, {
                            success: () => setProducts(products.filter((prd) => prd.id !== p.id)),
                            error: () => layout.error('Při mazání produktu došlo k chybě'),
                          });
                        }
                      )
                    }
                    aria-label="delete"
                    size="large"
                  >
                    <Delete color="error" />
                  </IconButton>
                  <ChangePrdId prdId={p.id} />
                  <IconButton size="large">
                    <FileCopy
                      onClick={() =>
                        layout.simpleTextForm(
                          'Vytvoření kopie produktu',
                          'Vytvoří produkt s novým PDK, který bude obsahovat kopii detailu tohoto produktu.',
                          'PDK nového produktu',
                          (pdk) =>
                            catalogService.post(
                              '/products/createCopy',
                              {
                                originalId: p.id,
                                newId: parseInt(pdk),
                              },
                              null,
                              {
                                success: () => history.push(`/products/edit/${pdk}`),
                                error: () => layout.error('Při vytváření kopie produktu došlo k chybě'),
                              }
                            )
                        )
                      }
                    />
                  </IconButton>
                  {p.isMain && <ViewProductPositionModal productId={p.id} />}
                </TableCell>
              </TableRow>
              {childRows.includes(p.id) && (
                <ProductsChildRowEditor
                  product={p}
                  onChange={(newP) => setProducts(products.map((pp) => (pp.id === newP.id ? newP : pp)))}
                  commonTradeSurcharge={commonTradeSurcharge}
                />
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
      <br />
      <div className={classes.flex}>
        <Pagination
          count={Math.ceil(filteredProducts.length / rowsPerPage)}
          showFirstButton
          showLastButton
          page={page}
          onChange={(_, val) => setPage(val)}
          color="primary"
        />
        <TextField
          variant="standard"
          style={{ width: 64 }}
          value={page}
          type="number"
          onChange={(e) => setPage(parseInt(e.target.value))}
        />
        <div className={classes.flexGrow}></div>
        <SetCentralTradeSurchage /> &nbsp;&nbsp;&nbsp;
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            catalogService.post('/pharmos/scan', {}, null);
            alert('Nové ceny budou načteny, tato operace obvykle trvá kolem 120 minut, ale může trvat i déle.');
          }}
        >
          Načíst ceny z Pharmosu
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            layout.simpleTextForm('Přidat produkt', 'Vytvořit produkt s id', 'ID produktu', (id) => {
              catalogService.post('/products/createProduct', { id: id }, null, {
                success: () => history.push(`/products/edit/${id}`),
                error: () => layout.error('Při vytváření produktu došlo k chybě'),
              });
            })
          }
        >
          Přidat produkt
        </Button>
        &nbsp;&nbsp;&nbsp;
      </div>
      <br />
    </>
  );
}
