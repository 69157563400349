import React, { useEffect, useState } from 'react';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import { catalogService } from '../../../Utils/ApiService';

interface IProps {
  open: boolean;
  onClose: () => void;
  onSelect: (product: IProduct) => void;
}

export default function AddProductModal({ open, onClose, onSelect }: IProps) {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<IProduct | undefined>(undefined);

  useEffect(
    () => {
      catalogService.get(
        '/products/all',
        {
          mapped: false,
          fields:
            'id,title,subtitle,productWidth,productHeight,productDepth,physicalStorePrice,reccomendedPrice,productMass,isRx,isObsolete',
        },
        {
          success: (data: IProduct[]) => setProducts(data.filter((d) => d.isRx !== true && d.isObsolete !== true)),
          error: console.log,
        }
      );
    },
    // eslint-disable-next-line
    []
  );

  const onSave = () => {
    if (selectedProduct) {
      onSelect(selectedProduct);
    }
    setSelectedProduct(undefined);
    onClose();
  };

  return (
    <>
      <Dialog onMouseMove={(e) => e.stopPropagation()} open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogContent>
          <Autocomplete
            options={products}
            getOptionLabel={(option) => `${option.title} ${option.subtitle} - ${option.id}`}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            value={selectedProduct || { id: 0, title: '', subtitle: '' }}
            onChange={(e, v) => v && setSelectedProduct(v)}
            fullWidth
            title="Produkt"
            renderInput={(params) => <TextField {...params} />}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={onClose}>
            Zrušit
          </Button>
          <Button color="primary" disabled={!selectedProduct} onClick={onSave}>
            Vybrat
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
