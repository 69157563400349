import { Button, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import { statisticsService } from '../Utils/ApiService';
import { downloadFile, readFile } from '../Utils/XmlInvoiceReformater';
import { useLayout } from '../Layout/LayoutContext';
import { getWeek } from 'date-fns';

export default function InvoiceReformatter() {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);

  const layout = useLayout();

  const reformatInvoice = () => {
    if (file) {
      setLoading(true);
      readFile(file, (content) => {
        statisticsService.postForText('/invoices/processSingleInvoice', {}, content, {
          success: (data: string) => {
            downloadFile(
              data,
              'application/xml;charset=windows-1250',
              `${getWeek(new Date())}-${new Date().getFullYear()}.xml`,
              'win1250'
            );
            setLoading(false);
          },
          error: () => {
            layout.error('Fakturu se nepodařilo přeformátovat.');
            setLoading(false);
          },
        });
      });
    }
  };

  const reformatCandyInvoice = () => {
    if (file) {
      readFile(file, (content) => {
        downloadFile(
          content.replace(/<typ:address>/g, '<typ:address linkToAddress="true">'),
          'application/xml;charset=utf-8',
          file.name,
          'utf-8'
        );
      });
    }
  };

  return (
    <>
      <Tabs
        value={tab}
        onChange={(e, newTab) => {
          setTab(newTab);
          setFile(undefined);
        }}
      >
        <Tab label="MAGISTR MARTIN" />
        <Tab label="CANDY PHARMA" />
      </Tabs>
      {tab === 0 ? (
        <>
          <Typography variant="h4">Oprava importu faktur z Lekisu pro Pohodu</Typography>
          <br />
          <DropzoneArea
            filesLimit={1}
            maxFileSize={134217728}
            showPreviews={false}
            onChange={(files) => files.length > 0 && setFile(files[0])}
          />
          {file && (
            <>
              <br />
              <Button variant="contained" color="primary" disabled={loading} onClick={reformatInvoice}>
                {loading ? 'Zpracovávám' : 'Opravit fakturu'}
              </Button>
            </>
          )}
        </>
      ) : (
        <>
          <Typography variant="h4">Oprava importu faktur z Lekisu pro Pohodu</Typography>
          <br />
          <DropzoneArea
            filesLimit={1}
            maxFileSize={134217728}
            showPreviews={false}
            onChange={(files) => files.length > 0 && setFile(files[0])}
          />
          {file && (
            <>
              <br />
              <Button variant="contained" color="primary" disabled={loading} onClick={reformatCandyInvoice}>
                Opravit fakturu
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
}
