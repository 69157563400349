import React, { useEffect, useState } from 'react';
import { Reservation } from '../Types/order';
import { PickupPlace, OrderState } from '@magistrmartin/eshop-frontend-shared';
import { ordersService } from '../Utils/ApiService';
import { useLayout } from '../Layout/LayoutContext';
import {
  Autocomplete,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import OrderStatusLabel from '../Components/OrderStatusLabel';
import { copyToClipboard, getReservationTypeCZ, toCzechTime } from '../Utils/Utils';
import NextOrderStatusBtn from '../Components/NextOrderStatusBtn';
import { FileCopy } from '@mui/icons-material';

export default function Reservations() {
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [placeId, setPlaceId] = useState<number | null>(null);
  const [places, setPlaces] = useState<PickupPlace[]>([]);
  const [openedChildRows, setOpenedChildRows] = useState<number[]>([]);

  const layout = useLayout();

  useEffect(() => {
    ordersService.get(
      '/infrastructure/prescriptionPlaces',
      {},
      {
        success: setPlaces,
        error: () => layout.error('Při načítání lékáren došlo k chybě'),
      }
    );
    // eslint-disable-next-line
  }, []);

  const loadReservations = (placeId: number) =>
    ordersService.get(
      '/reservations/all',
      { placeId: placeId },
      {
        success: setReservations,
        error: () => layout.error('Při načítání rezervací došlo k chybě'),
      }
    );

  return (
    <>
      <Autocomplete
        value={{
          id: placeId || 0,
          label: places.find((p) => p.id === placeId)?.name || '',
        }}
        onChange={(e, val) => {
          setPlaceId(val?.id || 0);
          if (val?.id) loadReservations(val.id);
        }}
        options={places.map((p) => ({ id: p.id, label: p.name }))}
        isOptionEqualToValue={(v, o) => v.id === o.id}
        fullWidth
        renderInput={(p) => <TextField label="Lékárna" {...p} />}
      />
      {reservations.length > 0 && (
        <>
          <Typography variant="h6">Rezervace</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Jméno</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Telefon</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Datum vytvoření</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reservations
                .slice()
                .reverse()
                .map((r) => (
                  <React.Fragment key={r.id}>
                    <TableRow
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        openedChildRows.includes(r.id)
                          ? setOpenedChildRows(openedChildRows.filter((i) => r.id !== i))
                          : setOpenedChildRows([...openedChildRows, r.id])
                      }
                    >
                      <TableCell>{r.id}</TableCell>
                      <TableCell>{r.name}</TableCell>
                      <TableCell>{r.mail}</TableCell>
                      <TableCell>{r.phone}</TableCell>
                      <TableCell>
                        <OrderStatusLabel orderStatus={r.status} />
                      </TableCell>
                      <TableCell>{toCzechTime(new Date(r.createdDate || '')).toLocaleString('cs-CZ')}</TableCell>
                    </TableRow>
                    {openedChildRows.includes(r.id) && (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={8}>
                              <Typography variant="h6">Rezervované eRecepty/produkty</Typography>
                              <br />
                              <Table>
                                <TableHead>
                                  <TableCell>Typ</TableCell>
                                  <TableCell>Kód/Název</TableCell>
                                  <TableCell>Množství</TableCell>
                                </TableHead>
                                <TableBody>
                                  {r.reservedProductsList.map((p) => (
                                    <TableRow key={p.id}>
                                      <TableCell>{getReservationTypeCZ(p.type || '')}</TableCell>
                                      <TableCell>
                                        <FileCopy
                                          color="disabled"
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            p.name && copyToClipboard(p.name);
                                            layout.info('Text zkopírován');
                                          }}
                                        />
                                        &nbsp;&nbsp;&nbsp;
                                        {p.name}
                                      </TableCell>
                                      <TableCell>{p.amount}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Typography variant="h6">Historie vývoje objednávky</Typography>
                              <br />
                              <table>
                                <tbody>
                                  <tr>
                                    <td>
                                      <OrderStatusLabel orderStatus="Waiting" />
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </td>
                                    <td>{toCzechTime(new Date(r.createdDate || '')).toLocaleString('cs-CZ')}</td>
                                  </tr>
                                  {r.statusChangeHistoryList.map((h) => (
                                    <tr key={h.id}>
                                      <td>
                                        <OrderStatusLabel orderStatus={h.newState as OrderState} />
                                      </td>
                                      <td>{toCzechTime(new Date(h.date || '')).toLocaleString('cs-CZ')}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </Grid>

                            <Grid item xs={12}>
                              <Toolbar style={{ float: 'right' }}>
                                <NextOrderStatusBtn
                                  isPersonal={true}
                                  status={r.status}
                                  setStatus={(newState) =>
                                    layout.confirm('Změna statusu', 'Opravdu chcete změnit status rezervace?', () =>
                                      ordersService.post(
                                        '/changeReservationStatus/',
                                        {
                                          reservationId: r.id,
                                          newStatus: newState,
                                        },
                                        null,
                                        {
                                          success: () =>
                                            setReservations(
                                              reservations.map((res) =>
                                                res.id === r.id
                                                  ? {
                                                      ...res,
                                                      status: newState,
                                                    }
                                                  : res
                                              )
                                            ),
                                          error: () => layout.error('Při ukládání došlo k chybě'),
                                        }
                                      )
                                    )
                                  }
                                />
                                &nbsp;&nbsp;
                                {r.status === 'Waiting' && (
                                  <Button
                                    onClick={() =>
                                      layout.confirm(
                                        'Zamítnout rezervaci',
                                        'Opravdu chcete zamítnout tuto rezervaci?',
                                        () =>
                                          ordersService.post(
                                            '/changeReservationStatus/',
                                            {
                                              reservationId: r.id,
                                              newStatus: 'Rejected',
                                            },
                                            null,
                                            {
                                              success: () =>
                                                setReservations(
                                                  reservations.map((res) =>
                                                    res.id === r.id
                                                      ? {
                                                          ...res,
                                                          status: 'Rejected',
                                                        }
                                                      : res
                                                  )
                                                ),
                                              error: () => layout.error('Při ukládání došlo k chybě'),
                                            }
                                          )
                                      )
                                    }
                                    color="secondary"
                                    variant="contained"
                                  >
                                    Zamítnout objednávku
                                  </Button>
                                )}
                                {r.status === 'InProgress' && (
                                  <>
                                    <Button
                                      onClick={() =>
                                        layout.simpleTextForm(
                                          'Odeslat informační email o přichystání rezervace.',
                                          'Zadejte do textového pole, od kdy je možné rezervaci vyzvednout. Nechte prázdné, pokud je možné rezervaci vyzvednou ihned',
                                          'Vyzvednutí možné od',
                                          (readyFrom) =>
                                            ordersService.post(
                                              'sendReservationReadyEmail',
                                              {
                                                reservationId: r.id,
                                                readyFrom: readyFrom,
                                              },
                                              null,
                                              {
                                                success: () => layout.success('Email byl úspěšně odeslán'),
                                                error: () => layout.error('Při odesílání mailo došlo k chybě'),
                                              }
                                            )
                                        )
                                      }
                                      color="primary"
                                      variant="contained"
                                    >
                                      Odeslat informační email
                                    </Button>
                                    &nbsp;
                                    <Button
                                      onClick={() =>
                                        layout.confirm(
                                          'Zrušit rezervaci',
                                          'Opravdu chcete zrušit tuto rezervaci?',
                                          () =>
                                            ordersService.post(
                                              '/changeReservationStatus/',
                                              {
                                                reservationId: r.id,
                                                newStatus: 'Canceled',
                                              },
                                              null,
                                              {
                                                success: () =>
                                                  setReservations(
                                                    reservations.map((res) =>
                                                      res.id === r.id
                                                        ? {
                                                            ...res,
                                                            status: 'Canceled',
                                                          }
                                                        : res
                                                    )
                                                  ),
                                                error: () => layout.error('Při ukládání došlo k chybě'),
                                              }
                                            )
                                        )
                                      }
                                      color="secondary"
                                      variant="contained"
                                    >
                                      Zrušit rezervaci
                                    </Button>
                                  </>
                                )}
                              </Toolbar>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </>
      )}
    </>
  );
}
