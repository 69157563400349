import { Autocomplete, Button, Checkbox, FormControlLabel, TextField, Theme, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLayout } from '../Layout/LayoutContext';
import { IProductStamp } from '../Pages/EditProduct/NewProduct';
import { ICategory } from '@magistrmartin/eshop-frontend-shared';
import { catalogService, imagesService } from '../Utils/ApiService';
import { getKeyExtractorComparer } from '../Utils/Comparers';
import { openFileContext } from '../Utils/Utils';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: theme.spacing(3),
    },
    banner: {
      width: '80%',
      marginLeft: '10%',
    },
    link: {
      fontFamily: 'Roboto',
      color: theme.palette.primary.contrastText,
      textDecoration: 'none',
    },
  })
);

interface IProps {
  prevCategory?: ICategory;
  nextCategory?: ICategory;
  category: ICategory;
  onSave: (cat: ICategory) => void;
}

export default function CategoryEditor({ category, onSave, prevCategory, nextCategory }: IProps) {
  const classes = useStyles();
  const layout = useLayout();
  const [actCat, setActCat] = useState<ICategory>({ ...category });
  const [allProducts, setAllProducts] = useState<IProductStamp[]>([]);

  useEffect(() => {
    setActCat({ ...category });
  }, [category]);

  useEffect(() => {
    catalogService.get(
      'products/all',
      { fields: 'id,title,subtitle' },
      {
        success: setAllProducts,
        error: console.log,
      }
    );
  }, []);

  const saveFiles = (files?: File[]) => {
    if (files !== undefined && files.length > 0) {
      if (files[0].size >= 1048576) {
        new Compressor(files[0], {
          quality: 0.8,
          convertSize: 1048576,
          success: (result) => {
            layout.warning('Obrázek byl automaticky komprimován, může dojít ke ztrátě kvality.');
            saveFiles([new File([result], files[0].name + '.jpg')]);
          },
          error: (err) => layout.error('Při kompresi došlo k chybě: ' + err.message),
        });
      } else {
        imagesService.uploadFile('uploadImage', files[0], { location: 'images' }, null, {
          success: (file) =>
            setActCat({
              ...actCat,
              banner: `https://api.magistrmartin.cz/images/noauth/image/images/${JSON.parse(file).location}`,
            }),
          error: layout.error,
        });
      }
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4">Upravit kategorii</Typography>
      <br />
      <TextField
        variant="standard"
        fullWidth
        label="Název"
        value={actCat.description}
        onChange={(e) => setActCat({ ...actCat, description: e.target.value })}
      />
      {allProducts && (
        <Autocomplete
          value={{
            id: actCat.topProductId || 0,
            label:
              allProducts.filter((p) => p.id === actCat.topProductId).map((p) => `${p.title} ${p.subtitle}`)[0] || '',
          }}
          onChange={(e, val) => setActCat({ ...actCat, topProductId: val?.id })}
          options={allProducts
            .sort(getKeyExtractorComparer('title'))
            .map((p) => ({ id: p.id, label: `${p.title} ${p.subtitle}` }))}
          fullWidth
          isOptionEqualToValue={(o, v) => o.id === v.id}
          renderInput={(p) => <TextField label="Top produkt" {...p} />}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id || -1}>
                {option.label}
              </li>
            );
          }}
        />
      )}
      <br />
      <Typography variant="caption">Banner</Typography>
      <div
        onDrop={(e) => {
          e.preventDefault();
          // @ts-ignore
          saveFiles(e.dataTransfer?.files);
        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
      >
        <img
          className={classes.banner}
          src={actCat.banner === undefined || (actCat.banner?.length || 0) === 0 ? '/img/noBanner.png' : actCat.banner}
          alt="category banner"
        />
      </div>
      {actCat.banner && (actCat.banner.length || 0) > 0 && (
        <>
          <Typography variant="caption">{actCat.banner.split('/').reverse()[0]}</Typography>
          <br />
        </>
      )}
      <Button color="primary" onClick={() => openFileContext(saveFiles)}>
        Změnit banner
      </Button>
      <Button
        color="secondary"
        onClick={() =>
          layout.deleteConfirm('Smazat banner', 'Opravdu chcete smazat tento banner?', () =>
            setActCat({ ...actCat, banner: '' })
          )
        }
      >
        Smazat banner
      </Button>
      <br />
      <br />
      <TextField
        variant="standard"
        fullWidth
        label="Id navázané kategorie"
        value={actCat.nextCategoryId}
        onChange={(e) => setActCat({ ...actCat, nextCategoryId: e.target.value })}
      />
      <br />
      <FormControlLabel
        control={<Checkbox color="primary" checked={actCat.visible} />}
        label="Zveřejněn v eshopu"
        onClick={() => setActCat({ ...actCat, visible: !actCat.visible })}
      />
      <br /> <br />
      <br />
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          catalogService.post('categories/add', {}, actCat, {
            success: () => onSave(actCat),
            error: layout.error,
          });
        }}
      >
        Uložit změny
      </Button>
      {prevCategory && (
        <Button
          color="primary"
          onClick={() =>
            catalogService.post('/categories/reorder', { first: category.id, second: prevCategory.id }, null, {
              success: onSave,
              error: console.log,
            })
          }
        >
          Posunout nahoru
        </Button>
      )}
      {nextCategory && (
        <Button
          color="primary"
          onClick={() =>
            catalogService.post('/categories/reorder', { first: category.id, second: nextCategory.id }, null, {
              success: onSave,
              error: console.log,
            })
          }
        >
          Posunout dolů
        </Button>
      )}
      <br />
      <br />
      <a
        className={classes.link}
        href={`https://magistrmartin.cz/offer/category/${category.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Zobrazit v eshopu
      </a>
    </div>
  );
}
