import { Grid, TextField, Theme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LoadingFab from '../../Components/LoadingFab';
import { useLayout } from '../../Layout/LayoutContext';
import { IProduct, ISelectsConfig } from '@magistrmartin/eshop-frontend-shared';
import { catalogService } from '../../Utils/ApiService';
import PricesEditor from './PricesEditor';
import InfoEditor from './InfoEditor';
import DetailsEditor from './DetailsEditor';
import DescriptionEditor from './DescriptionEditor';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemRoot: {
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    saveBtn: {
      position: 'fixed',
      right: theme.spacing(2),
      bottom: theme.spacing(2),
    },
    pointer: {
      cursor: 'pointer',
    },
  })
);

export interface IProductStamp {
  id: number;
  title: string;
  subtitle: string;
}

export default function NewProduct() {
  const classes = useStyles();
  const layout = useLayout();
  const [product, setProduct] = useState<IProduct | undefined>({ id: 0 });
  const [selectsConfig, setSelectsConfig] = useState<ISelectsConfig | undefined>(undefined);
  const [allProducts, setAllProducts] = useState<IProductStamp[]>([]);
  const [uploading, setUploading] = useState(false);
  const history = useHistory();

  const changeProduct = (attr: keyof IProduct, val: any) => {
    if (product !== undefined) {
      const newProd = { ...product };
      newProd[attr] = val as never;
      setProduct(newProd);
    }
  };

  useEffect(
    () => {
      catalogService.get(
        '/settings/selects/',
        {},
        {
          success: setSelectsConfig,
          error: layout.error,
        }
      );
      catalogService.get(
        'products/all',
        { fields: 'id,title,subtitle' },
        {
          success: setAllProducts,
          error: layout.error,
        }
      );
    },
    // eslint-disable-next-line
    []
  );

  return product === undefined ? (
    <></>
  ) : (
    <>
      <Grid container>
        <Grid className={classes.itemRoot} item xs={12} md={6} lg={4}>
          <TextField
            variant="standard"
            fullWidth
            label="Id"
            type="number"
            value={product.id}
            onChange={(e) => changeProduct('id', e.target.value)}
          />
        </Grid>
      </Grid>

      <br />
      <br />
      <Grid container>
        <Grid className={classes.itemRoot} item xs={12} md={6} lg={4}>
          <InfoEditor
            changeProduct={changeProduct}
            product={product}
            setProduct={setProduct}
            selectsConfig={selectsConfig}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <PricesEditor
            setProduct={setProduct}
            allProducts={allProducts}
            changeProduct={changeProduct}
            product={product}
          />
        </Grid>
        <Grid className={classes.itemRoot} item xs={12} md={6} lg={4}>
          <DetailsEditor
            changeProduct={changeProduct}
            product={product}
            selectsConfig={selectsConfig}
            allProducts={allProducts}
          />
        </Grid>
        <Grid className={classes.itemRoot} item xs={12}>
          <DescriptionEditor changeProduct={changeProduct} product={product} />
        </Grid>
      </Grid>
      <br />
      <br />

      <LoadingFab
        color="primary"
        variant="extended"
        className={classes.saveBtn}
        label="Uložit změny"
        loadingLabel="Ukládání"
        loading={uploading}
        onClick={() => {
          setUploading(true);
          catalogService.post(
            'products/insert',
            {},
            {
              ...product,
              description: localStorage.getItem('TMP_description'),
            },
            {
              success: () => {
                changeProduct('description', localStorage.getItem('TMP_description'));
                layout.success('Změny byly úspěšně uloženy');
                setUploading(false);
                history.goBack();
              },
              error: () => {
                layout.error('Při ukládání změn došlo k chybě');
                setUploading(false);
              },
            }
          );
        }}
      />

      <br />
      <br />
    </>
  );
}
