import React from 'react';
import { Fab } from '@mui/material';

interface IProps {
  color?: 'inherit' | 'primary' | 'secondary' | undefined;
  variant?: 'extended' | 'circular' | undefined;
  className?: string;
  onClick: () => void;
  loading: boolean;
  label: string;
  loadingLabel: string;
}

export default function LoadingFab({ loading, onClick, color, variant, label, loadingLabel, className }: IProps) {
  return (
    <Fab color={color} variant={variant} onClick={onClick} disabled={loading} className={className}>
      {loading ? loadingLabel : label}
    </Fab>
  );
}
