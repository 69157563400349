import React from 'react';
import { Chip } from '@mui/material';
import { InternalOrderState, OrderState } from '@magistrmartin/eshop-frontend-shared';
import { AlarmOn, Archive, Close, KeyboardReturn, LocalShipping, Lock, Timer } from '@mui/icons-material';

interface IProps {
  orderStatus: OrderState | InternalOrderState;
}

export default function OrderStatusLabel({ orderStatus }: IProps) {
  return orderStatus === 'Waiting' ? (
    <Chip icon={<Timer />} label="Čekání" style={{ backgroundColor: '#F1C40F', color: 'white' }} />
  ) : orderStatus === 'InProgress' ? (
    <Chip icon={<Archive />} style={{ backgroundColor: '#E67E22', color: 'white' }} label="Zabaleno" />
  ) : orderStatus === 'Shipped' ? (
    <Chip icon={<LocalShipping />} color="primary" label="Odesláno" />
  ) : orderStatus === 'Finished' ? (
    <Chip icon={<Lock />} style={{ backgroundColor: '#1D8348', color: 'white' }} label="Uzavřeno" />
  ) : orderStatus === 'Rejected' ? (
    <Chip icon={<Close />} style={{ backgroundColor: '#922B21', color: 'white' }} label="Zamítnuto" />
  ) : orderStatus === 'Canceled' ? (
    <Chip icon={<Close />} style={{ backgroundColor: '#922B21', color: 'white' }} label="Stornováno" />
  ) : orderStatus === 'new' ? (
    <Chip icon={<Timer />} label="Čekání" style={{ backgroundColor: '#F1C40F', color: 'white' }} />
  ) : orderStatus === 'fullfilled' ? (
    <Chip icon={<Archive />} color="primary" label="Vykryto" />
  ) : orderStatus === 'partiallyFullfilled' ? (
    <Chip icon={<Archive />} color="primary" label="Částečně vykryto" />
  ) : orderStatus === 'rejected' ? (
    <Chip icon={<Close />} style={{ backgroundColor: '#922B21', color: 'white' }} label="Zamítnuto" />
  ) : orderStatus === 'received' ? (
    <Chip icon={<Lock />} style={{ backgroundColor: '#1D8348', color: 'white' }} label="Přijato" />
  ) : orderStatus === 'Prepared' ? (
    <Chip icon={<AlarmOn />} style={{ backgroundColor: '#10b0a0', color: 'white' }} label="Připraveno" />
  ) : orderStatus === 'Refunded' ? (
    <Chip icon={<KeyboardReturn />} style={{ backgroundColor: '#922B21', color: 'white' }} label="Vratka" />
  ) : (
    <></>
  );
}
