import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { IBaseDiscount } from '@magistrmartin/eshop-frontend-shared';
import { useLayout } from '../../Layout/LayoutContext';
import BasicDiscountEditor from '../../Components/BasicDiscountEditor';
import { catalogService } from '../../Utils/ApiService';
import { Edit } from '@mui/icons-material';
import { useEffect } from 'react';

interface IProps {
  editedDiscount: IBaseDiscount;
  onSave: (dis: IBaseDiscount) => void;
}

export default function EditDiscount({ onSave, editedDiscount }: IProps) {
  const [open, setOpen] = useState(false);
  const [discount, setDiscount] = useState<IBaseDiscount>({
    ...editedDiscount,
  });
  const layout = useLayout();

  const saveChanges = () => {
    catalogService.post('/discounts/', {}, discount, {
      success: () => {
        onSave(discount);
        setOpen(false);
      },
      error: layout.error,
    });
  };

  useEffect(() => setDiscount({ ...editedDiscount }), [editedDiscount]);

  return (
    <>
      <IconButton onClick={() => setOpen(true)} aria-label="Edit" size="large">
        <Edit />
      </IconButton>
      <Dialog maxWidth="lg" open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Upravit slevu</DialogTitle>
        <DialogContent>
          <BasicDiscountEditor discount={discount} setDiscount={setDiscount} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button color="primary" onClick={saveChanges}>
            Uložit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
