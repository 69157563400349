import React from 'react';
import { Button, Grid, TextField, Theme, Typography } from '@mui/material';
import { useLayout } from '../../Layout/LayoutContext';
import { openFileContext } from '../../Utils/Utils';
import { HomeConfigType } from './HomeConfig';
import { createStyles, makeStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: theme.palette.text.primary,
    },
  })
);

interface IProps {
  config: HomeConfigType;
  setConfig: (newConifg: HomeConfigType) => void;
  getSaveFile: (key: 'banners' | 'miniBanners' | 'leaflets') => (files?: File[]) => void;
  label: string;
  configKey: 'banners' | 'miniBanners';
}

export default function Banners({ config, getSaveFile, setConfig, label, configKey }: IProps) {
  const classes = useStyles();
  const layout = useLayout();

  return (
    <>
      <Typography variant="h6">{label}</Typography>
      <br />
      {config[configKey] &&
        (config[configKey]?.length || 0) > 0 &&
        config[configKey]?.map((b, index) => (
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography>
                <a className={classes.link} href={b.file} rel="noopener noreferrer" target="_blank">
                  {b.file.split('/').reverse()[0].substring(13)}
                </a>
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                variant="standard"
                label="název akce"
                value={b.name || ''}
                fullWidth
                onChange={(e) =>
                  setConfig({
                    ...config,
                    [configKey]: config[configKey]?.map((bb) =>
                      bb.file === b.file ? { ...bb, name: e.target.value } : bb
                    ),
                  })
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                variant="standard"
                label="odkaz"
                value={b.link || ''}
                fullWidth
                onChange={(e) =>
                  setConfig({
                    ...config,
                    [configKey]: config[configKey]?.map((bb) =>
                      bb.file === b.file ? { ...bb, link: e.target.value } : bb
                    ),
                  })
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <DatePicker
                format="yyyy-MM-dd"
                label="Platí od"
                value={b.validFrom || null}
                onChange={(date) =>
                  setConfig({
                    ...config,
                    [configKey]: config[configKey]?.map((bb) =>
                      bb.file === b.file ? { ...bb, validFrom: date || undefined } : bb
                    ),
                  })
                }
                slots={{ textField: TextField }}
                slotProps={{ textField: { fullWidth: true } }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <DatePicker
                format="yyyy-MM-dd"
                label="Platí do"
                value={b.validTo || null}
                onChange={(date) =>
                  setConfig({
                    ...config,
                    [configKey]: config[configKey]?.map((bb) =>
                      bb.file === b.file ? { ...bb, validTo: date || undefined } : bb
                    ),
                  })
                }
                slots={{ textField: TextField }}
                slotProps={{ textField: { fullWidth: true } }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                color="secondary"
                onClick={() =>
                  layout.deleteConfirm('Smazat banner', 'Opravdu chcete smazat tento banner?', () =>
                    setConfig({ ...config, [configKey]: config[configKey]?.filter((bb) => bb.file !== b.file) })
                  )
                }
              >
                Smazat
              </Button>
              {index > 0 && (
                <Button
                  color="primary"
                  onClick={() =>
                    setConfig({
                      ...config,
                      [configKey]: config[configKey]?.map((ban, banInd) =>
                        banInd === index - 1
                          ? (config[configKey] || [])[index]
                          : banInd === index
                            ? (config[configKey] || [])[index - 1]
                            : ban
                      ),
                    })
                  }
                >
                  Nahoru
                </Button>
              )}
            </Grid>
          </Grid>
        ))}
      <br />
      <Button variant="contained" color="primary" onClick={() => openFileContext(getSaveFile(configKey))}>
        Nahrát banner
      </Button>
      <br />
      <br />
      <br />
      <TextField
        variant="standard"
        label="Odkaz na dopravu a platbu"
        fullWidth
        value={config.shippingPaymentLink}
        onChange={(e) => setConfig({ ...config, shippingPaymentLink: e.target.value })}
      />
    </>
  );
}
