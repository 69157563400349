import React, { useEffect, useState } from 'react';
import { useLayout } from '../Layout/LayoutContext';
import { catalogService, ordersService } from '../Utils/ApiService';
import {
  Card,
  CardContent,
  CardHeader,
  CardActionArea,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Autocomplete,
} from '@mui/material';
import { PickupPlace } from '@magistrmartin/eshop-frontend-shared';
import { Delete } from '@mui/icons-material';

type ImportCorrrection = {
  placeId: number;
  importedId: number;
  idInEshop: number;
};

const emptyCorrection = {
  placeId: -1,
  importedId: 0,
  idInEshop: 0,
};

export default function PdkRepair() {
  const [importCorrections, setImportCorrections] = useState<ImportCorrrection[]>([]);
  const [pickupPlaces, setPickupPlaces] = useState<PickupPlace[]>([]);
  const [addCorrectionOpened, setAddCorrectionOpened] = useState(false);
  const [newCorrection, setNewCorrection] = useState<ImportCorrrection>(emptyCorrection);

  const layout = useLayout();

  useEffect(
    () => {
      layout.setIsLoading(true);
      catalogService.get(
        '/stock/importCorrections',
        {},
        {
          success: setImportCorrections,
          error: () => layout.error('Při načítání korekcí PDK došlo k chybě'),
          finally: () => layout.setIsLoading(false),
        }
      );
    },
    //eslint-disable-next-line
    []
  );

  useEffect(() => {
    ordersService.get(
      'infrastructure/admin/allPickupPlaces',
      {},
      {
        success: setPickupPlaces,
        error: console.log,
      }
    );
  }, []);

  const addCorrection = () => {
    catalogService.post('/stock/importCorrection', {}, newCorrection, {
      success: () => {
        setImportCorrections([...importCorrections, { ...newCorrection }]);
        setNewCorrection(emptyCorrection);
        setAddCorrectionOpened(false);
      },
      error: () => layout.error('Při ukládání došlo k chybě.'),
    });
  };

  const places = [{ id: -1, name: 'Všechny lékárny' } as PickupPlace, ...pickupPlaces];
  return (
    <>
      <Grid spacing={3}>
        <Grid item xs={12} md={5}>
          <Card>
            <CardHeader title="Korekce PDK při importu" />
            <CardContent>
              <List>
                {importCorrections.map((cor) => (
                  <ListItem key={`${cor.importedId}-${cor.placeId}`}>
                    <ListItemText
                      primary={`${cor.importedId} -> ${cor.idInEshop}`}
                      secondary={places.filter((p) => p.id === cor.placeId)[0]?.name || cor.placeId}
                    />
                    <ListItemIcon>
                      <IconButton
                        onClick={() =>
                          layout.deleteConfirm(
                            'Opravdu chcete smazat toto pravidlo?',
                            'Pravidlo bude trvale smazáno.',
                            () =>
                              catalogService.delete(
                                '/stock/importCorrection',
                                {
                                  placeId: cor.placeId,
                                  importedId: cor.importedId,
                                },
                                null,
                                {
                                  success: () =>
                                    setImportCorrections(
                                      importCorrections.filter(
                                        (c) => c.importedId !== cor.importedId || c.placeId !== cor.placeId
                                      )
                                    ),
                                  error: () => layout.error('Při mazání došlo k chybě'),
                                }
                              )
                          )
                        }
                        size="large"
                      >
                        <Delete color="error" />
                      </IconButton>
                    </ListItemIcon>
                  </ListItem>
                ))}
              </List>
            </CardContent>
            <CardActionArea>
              <Button color="primary" onClick={() => setAddCorrectionOpened(true)}>
                Přidat pravidlo
              </Button>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
      <Dialog open={addCorrectionOpened} onClose={() => setAddCorrectionOpened(false)} fullWidth maxWidth="lg">
        <DialogTitle title="Přidat korekci PDK" />
        <DialogContent style={{ overflowY: 'hidden' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextField
                variant="standard"
                fullWidth
                label="Importované PDK"
                type="number"
                margin="normal"
                onChange={(e) =>
                  setNewCorrection({
                    ...newCorrection,
                    importedId: parseInt(e.target.value),
                  })
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                variant="standard"
                fullWidth
                label="PDK v eshopu"
                type="number"
                onChange={(e) =>
                  setNewCorrection({
                    ...newCorrection,
                    idInEshop: parseInt(e.target.value),
                  })
                }
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                value={{
                  id: newCorrection.placeId,
                  label: places.find((p) => p.id === newCorrection.placeId)?.name || '',
                }}
                onChange={(e, val) => val?.id && setNewCorrection({ ...newCorrection, placeId: val.id })}
                options={places.map((p) => ({ id: p.id, label: p.name }))}
                isOptionEqualToValue={(v, o) => v.id === o.id}
                fullWidth
                renderInput={(p) => <TextField label="Lékárna" {...p} />}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setAddCorrectionOpened(false)}>
            Zavřít
          </Button>
          <Button
            color="primary"
            disabled={newCorrection.importedId <= 0 || newCorrection.idInEshop <= 0}
            onClick={addCorrection}
          >
            Uložit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
