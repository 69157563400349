import React, { useEffect, useState } from 'react';
import { Button, Grid, IconButton, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@mui/material';
import { apiUrl, webStructureService } from '../Utils/ApiService';
import { useLayout } from '../Layout/LayoutContext';
import { Delete, Edit } from '@mui/icons-material';
import { Editor } from '@tinymce/tinymce-react';

interface ISite {
  id?: number;
  name?: string;
  content?: string;
}

export default function StaticSites() {
  const layout = useLayout();

  const [allSites, setAllSites] = useState<ISite[]>([]);
  const [selectedSite, setSelectedSite] = useState<ISite | undefined>(undefined);

  const createSite = (name: string) => {
    if (name === null || name.length === 0) return;
    webStructureService.post('/sites/', { name: name }, null, {
      success: (id: number) => setAllSites([...allSites, { id: id, name: name, content: '' }]),
      error: layout.error,
    });
  };

  useEffect(
    () => {
      webStructureService.get('/sites/all', undefined, {
        success: setAllSites,
        error: layout.error,
      });
    },
    //eslint-disable-next-line
    []
  );

  return (
    <Grid container>
      <Grid item xs={12} lg={3}>
        <Table>
          <TableBody>
            {allSites.map((s) => (
              <TableRow key={s.id}>
                <TableCell>
                  <Typography>
                    [{s.id}]{' '}
                    <TextField
                      variant="standard"
                      value={s.name}
                      onChange={(e) =>
                        setAllSites(allSites.map((ss) => (ss.id === s.id ? { ...s, name: e.target.value } : ss)))
                      }
                      onBlur={(e) =>
                        webStructureService.post('/sites/rename', { name: s.name, id: s.id }, null, {
                          success: () => {},
                          error: () => layout.error('Při změně jména došlo k chybě'),
                        })
                      }
                    />
                  </Typography>
                </TableCell>
                <TableCell>
                  <IconButton aria-label="edit" onClick={() => setSelectedSite(s)} size="large">
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      layout.deleteConfirm(
                        'Opravdu chcete smazat tuto stránku?',
                        'Stránka bude trvale smazána i se svým obsahem',
                        () => {
                          webStructureService.delete('/sites/', { id: s.id }, null, {
                            success: () => window.location.reload(),
                            error: () => layout.error('Při mazání došlo k chybě.'),
                          });
                        }
                      );
                    }}
                    aria-label="delete"
                    size="large"
                  >
                    <Delete color="error" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <br />
        <TextField
          variant="standard"
          label="Přidat novou stránku"
          fullWidth
          onBlur={(e) => createSite(e.target.value)}
        />
        <br />
        <br />
      </Grid>
      <Grid item xs={12} lg={9}>
        {selectedSite && (
          <>
            <Editor
              apiKey="ygw8t6cqi14winm1hwe2cr6yhal1lvoygowen2ni7qen02yz"
              initialValue={selectedSite.content || ''}
              init={{
                plugins: [
                  'advlist autolink lists link image',
                  'charmap print preview anchor help',
                  'searchreplace visualblocks code',
                  'insertdatetime media table paste wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help',
                automatic_uploads: true,
                height: 720,
                images_reuse_filename: true,
                images_upload_url: `${apiUrl}images/uploadImage`,
                images_upload_base_path: 'https://api.magistrmartin.cz/images/noauth/image/images/',
              }}
              onChange={(e) => localStorage.setItem('tmpEditorText', e.target.getContent())}
            />
            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                webStructureService.post(
                  '/sites/',
                  { id: selectedSite.id, name: selectedSite.name },
                  localStorage.getItem('tmpEditorText') || selectedSite.content,
                  {
                    success: () => {
                      setAllSites(
                        allSites.map((s) =>
                          s.id === selectedSite.id
                            ? {
                                ...selectedSite,
                                content: localStorage.getItem('tmpEditorText') || selectedSite.content,
                              }
                            : s
                        )
                      );
                      layout.success('Změny byly úspěšně uloženy');
                    },
                    error: layout.error,
                  }
                )
              }
            >
              Uložit změny
            </Button>
          </>
        )}
      </Grid>
    </Grid>
  );
}
