import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { catalogService } from '../../Utils/ApiService';
import { useLayout } from '../../Layout/LayoutContext';

export default function SetCentralTradeSurchage() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [surchage, setSurchage] = useState('');

  const layout = useLayout();

  const saveChanges = () => {
    setLoading(true);
    catalogService.post('/products/centralTradeSurchage', { surchage: surchage }, null, {
      success: () => {
        window.location.reload();
      },
      error: () => {
        setLoading(false);
        layout.error('Při ukládání došlo k chybě');
      },
    });
  };

  return (
    <>
      <Button variant="contained" onClick={() => setOpen(true)} color="primary">
        Centrální změna obchodní přirážky
      </Button>
      <Dialog maxWidth="lg" open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Změnit obchodní přirážku</DialogTitle>
        <DialogContent>
          <TextField
            variant="standard"
            label="Nová přirážka"
            value={surchage}
            onChange={(e) => setSurchage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          {(loading && <Typography>Ukládám, prosím čekejte</Typography>) || (
            <>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
              <Button color="primary" onClick={saveChanges}>
                Uložit
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
