import React, { useEffect, useState } from 'react';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import { IconButton, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs } from '@mui/material';
import { useLayout } from '../Layout/LayoutContext';
import { catalogService } from '../Utils/ApiService';
import { ArrowRightAlt, Edit } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';

export default function HeurekaPairing() {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [tab, setTab] = useState(0);

  const layout = useLayout();
  const history = useHistory();

  useEffect(() => {
    layout.setIsLoading(true);
    catalogService.get(
      '/products/byHeurekaEshops',
      {
        from: 1,
        to: 10,
        fields: 'excelOrder,id,title,subtitle,supplierTag,numberOfHeurekaEshops,stockAmmount,pharmosStockState'.split(
          ','
        ),
      },
      {
        success: setProducts,
        error: layout.error,
        finally: () => layout.setIsLoading(false),
      }
    );
    // eslint-disable-next-line
  }, []);

  const tabs = [
    {
      label: 'Skladem Pharmos',
      filter: (p: IProduct) => (p.pharmosStockState || 0) > 0,
    },
    {
      label: 'Není skladem Pharmos',
      filter: (p: IProduct) => (p.pharmosStockState || 0) === 0,
    },
  ];

  return (
    <>
      <Tabs value={tab} onChange={(_, val) => setTab(val)}>
        <Tab label="Skladem Pharmos" />
        <Tab label="Není skladem Pharmos" />
      </Tabs>
      <br />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>ID (PDK)</TableCell>
            <TableCell>Název</TableCell>
            <TableCell>Vedlejší název</TableCell>
            <TableCell>Dodavatel</TableCell>
            <TableCell>Počet Heuréka</TableCell>
            <TableCell>Počet ks Selská</TableCell>
            <TableCell>Počet ks Pharmos</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.filter(tabs[tab].filter).map((p) => (
            <TableRow key={p.id}>
              <TableCell>{p.excelOrder}</TableCell>
              <TableCell>{p.id}</TableCell>
              <TableCell>{p.title}</TableCell>
              <TableCell>{p.subtitle}</TableCell>
              <TableCell>{p.supplierTag}</TableCell>
              <TableCell>{p.numberOfHeurekaEshops || 0}</TableCell>
              <TableCell>{p.stockAmmount || 0}</TableCell>
              <TableCell>{p.pharmosStockState || 0}</TableCell>
              <TableCell style={{ minWidth: 82 }}>
                <IconButton onClick={() => history.push(`/products/edit/${p.id}`)}>
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() =>
                    catalogService.get(
                      '/heureka/queryInfo',
                      { productId: p.id },
                      {
                        success: (d: any) =>
                          d?.productSlug && d?.categorySlug
                            ? window.open(`https://${d.categorySlug}.heureka.cz/${d.productSlug}`, '_blank')
                            : layout.error('Produkt nemohl být otevřen'),
                        error: () => layout.error('Produkt nemohl být otevřen'),
                      }
                    )
                  }
                >
                  <ArrowRightAlt />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
