import readXlsxFile from 'read-excel-file';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';

export interface IExcelError {
  error: string;
  row: number;
  column: string;
  value: any | null;
}

const parsePrice = (raw: string) => parseInt(raw.toString().toLowerCase().replace('kč', ''));

const excelSchema = {
  PDK: {
    prop: 'id',
    type: Number,
    required: true,
  },
  'Hlavní název': {
    prop: 'title',
    type: String,
  },
  'Vedlejší název': {
    prop: 'subtitle',
    type: String,
  },
  Charakteristika: {
    prop: 'characteristics',
    type: String,
  },
  'Kategorie ID': {
    prop: 'categories',
    parse: (raw: string) => raw.toString().split(';').filter((x) => x.length > 0),
  },
  'Doporučená cena': {
    prop: 'reccomendedPrice',
    parse: parsePrice,
  },
  'Akční cena': {
    prop: 'actionPrice',
    parse: parsePrice,
  },
  'Množství jednotek': {
    prop: 'ammount',
    type: Number,
  },

  'Krátký popis produktu': {
    prop: 'shortDescription',
    type: String,
  },
  'Popis produktu': {
    prop: 'description',
    type: String,
  },
  Výrobce: {
    prop: 'manufacturer',
    type: String,
  },
  'Dodavatel na trh': {
    prop: 'supplier',
    type: String,
  },
  'Produktová řada': {
    prop: 'series',
    type: String,
  },
  Značka: {
    prop: 'brandMark',
    type: String,
  },
  'Léková forma': {
    prop: 'form',
    type: String,
  },
  SÚKL: {
    prop: 'sukl',
    type: String,
  },
  'Věková osa': {
    prop: 'age',
    type: Number,
  },
  'Hmotnostní osa': {
    prop: 'mass',
    type: Number,
  },

  'Účinná látka': {
    prop: 'activeSubstance',
    type: String,
  },
  'PDK druhů velikostí/příchuťí produktů': {
    prop: 'alternatives',
    parse: (raw: string) =>
      raw
        .toString()
        .split(';')
        .filter((x) => x.length > 0),
  },
  Rec: {
    prop: 'reccomendedAlternative',
    type: {
      'PDK alternativ': {
        prop: 'alternativeId',
        type: String,
      },
      'Výhody oproti známému produktu': {
        prop: 'description',
        type: String,
      },
    },
  },
  'Zveřejněn v Online Lékárně': {
    prop: 'published',
    type: Boolean,
  },
  Pořadí: {
    prop: 'excelOrder',
    type: Number,
  },
  'Farmakologická skupina': {
    prop: 'pharmaGroup',
    type: String,
  },
  Přírodní: {
    prop: 'isNatural',
    type: Boolean,
  },
  Bio: {
    prop: 'isBio',
    type: Boolean,
  },
  Vegan: {
    prop: 'isVegan',
    type: Boolean,
  },
  'Bez laktózy': {
    prop: 'noLactose',
    type: Boolean,
  },
  'V těhotenství': {
    prop: 'whilePregnancy',
    type: Boolean,
  },
  'Při kojení': {
    prop: 'whileBreastFeeding',
    type: Boolean,
  },
  'Kombinujte s': {
    prop: 'combineWith',
    parse: (raw: string) =>
      raw
        .toString()
        .split(';')
        .filter((x) => x.length > 0),
  },
  'Hmotnost produktu': {
    prop: 'productMass',
    type: Number,
  },
  'Výška produktu': {
    prop: 'productHeight',
    type: Number,
  },
  'Šířka produktu': {
    prop: 'productWidth',
    type: Number,
  },
  'Hloubka produktu': {
    prop: 'productDepth',
    type: Number,
  },
  'Skupinové balení': {
    prop: 'groupPackageAmmount',
    type: Number,
  },
  'Vyšší skupinové balení': {
    prop: 'higherGroupPackageAmmount',
    type: Number,
  },
  'Délka EXP': {
    prop: 'maxExpirationLength',
    type: Number,
  },
  Preference: {
    prop: 'preferention',
    type: Number,
  },
  'Hlavní/vedlejší': {
    prop: 'isMain',
    type: Boolean,
  },
  'Nákupní cena': {
    prop: 'buyingPrice',
    type: Number,
  },
  Sleva: {
    prop: 'tradeDiscount',
    type: Number,
  },
  Bonifikace: {
    prop: 'bonification',
    type: Number,
  },
  'Sell-out': { prop: 'sellout', type: Number },
  CD: { prop: 'centralDistribution', type: Boolean },
  IC: { prop: 'doNotScann', type: Boolean },
};

export async function importFromExcel(file?: File): Promise<{ rows: IProduct[]; errors: IExcelError[] }> {
  return readXlsxFile(file, { schema: excelSchema });
}
