import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLayout } from '../Layout/LayoutContext';
import { ordersService, statisticsService } from '../Utils/ApiService';
import Plot from 'react-plotly.js';
import { reducers } from '../Utils/Reducers';
import { IProduct, PickupPlace } from '@magistrmartin/eshop-frontend-shared';

interface IProps {
  product?: IProduct;
  placeId?: number;
  onClose: () => void;
}

export default function SalesHistoryModal({ product, placeId, onClose }: IProps) {
  const [historyData, setHistoryData] = useState<{
    [index: string]: { [index: string]: number };
  }>({});
  const [monthlyHistoryData, setMonthlyHistoryData] = useState<{
    [index: string]: { [index: string]: number };
  }>({});
  const [places, setPlaces] = useState<PickupPlace[]>([]);
  const [viewMode, setViewMode] = useState<'table' | 'plot'>('table');

  const theme = useTheme();

  const layout = useLayout();

  useEffect(() => {
    setHistoryData({});
    const dateFrom = new Date();
    dateFrom.setMonth(dateFrom.getMonth() - 12);
    product?.id &&
      statisticsService.get(
        'monthlySalesHistory',
        {
          productId: product?.id,
          from: dateFrom.toISOString().substring(0, dateFrom.toISOString().indexOf('T')),
          to: new Date().toISOString().substring(0, new Date().toISOString().indexOf('T')),
        },
        {
          success: setHistoryData,
          error: () => layout.error('Při načítání historie prodejnosti došlo k chybě'),
        }
      );
    // eslint-disable-next-line
  }, [product]);

  useEffect(() => {
    setMonthlyHistoryData({});
    const dateFrom = new Date();
    dateFrom.setMonth(dateFrom.getMonth() - 12);
    product?.id &&
      statisticsService.get(
        'monthlySalesHistory',
        {
          productId: product?.id,
          from: dateFrom.toISOString().substring(0, dateFrom.toISOString().indexOf('T')),
          to: new Date().toISOString().substring(0, new Date().toISOString().indexOf('T')),
          useMonthlyData: true,
        },
        {
          success: setMonthlyHistoryData,
          error: () => layout.error('Při načítání historie prodejnosti došlo k chybě'),
        }
      );
    // eslint-disable-next-line
  }, [product]);

  useEffect(() => {
    ordersService.get('/infrastructure/admin/allPickupPlaces', {}, { success: setPlaces, error: console.log });
  }, []);

  return (
    <Dialog maxWidth="lg" open={product !== undefined} onClose={onClose}>
      <DialogTitle>
        Historie prodejností
        <br />
        <Typography color="textSecondary">
          {product?.title} {product?.subtitle}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {Object.keys(historyData).length > 0 && (
          <>
            {viewMode === 'table' && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {Object.keys(historyData[Object.keys(historyData)[0]])
                      .sort()
                      .map((x) => (
                        <TableCell
                          key={x}
                          style={
                            ['04', '08', '12'].includes(x.slice(-5, -3))
                              ? {
                                  borderRight: `1.5px solid ${theme.palette.text.primary}`,
                                }
                              : {}
                          }
                        >
                          {x.slice(0, -3)}
                        </TableCell>
                      ))}
                    <TableCell
                      style={{
                        borderLeft: `1.5px solid ${theme.palette.text.primary}`,
                      }}
                    >
                      <b>Celkem</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(historyData)
                    .filter((k) => placeId === 0 || k === placeId?.toString())
                    .map((pharma) => (
                      <TableRow key={pharma}>
                        <TableCell>
                          {places.filter((p) => p.id?.toString() === pharma.toString())[0]?.name || pharma}
                        </TableCell>
                        {Object.keys(historyData[pharma])
                          .sort()
                          .map((k) => (
                            <TableCell
                              key={k}
                              style={
                                ['04', '08', '12'].includes(k.slice(-5, -3))
                                  ? {
                                      borderRight: `1.5px solid ${theme.palette.text.primary}`,
                                    }
                                  : {}
                              }
                            >
                              {historyData[pharma][k]}
                              <br />
                              <span style={{ color: 'grey' }}>{(monthlyHistoryData[pharma] || [])[k] || 0}</span>
                            </TableCell>
                          ))}
                        <TableCell
                          style={{
                            borderLeft: `1.5px solid ${theme.palette.text.primary}`,
                          }}
                        >
                          {Object.keys(historyData[pharma])
                            .map((k) => historyData[pharma][k])
                            .reduce(reducers.sum, 0)}
                        </TableCell>
                      </TableRow>
                    ))}
                  {placeId === 0 && (
                    <TableRow
                      style={{
                        borderTop: `1.5px solid ${theme.palette.text.primary}`,
                      }}
                    >
                      <TableCell>
                        <b>Celkem</b>
                      </TableCell>
                      {Object.keys(historyData[Object.keys(historyData)[0]])
                        .sort()
                        .map((k) => (
                          <TableCell
                            key={k}
                            style={
                              ['04', '08', '12'].includes(k.slice(-5, -3))
                                ? {
                                    borderRight: `1.5px solid ${theme.palette.text.primary}`,
                                  }
                                : {}
                            }
                          >
                            {Object.keys(historyData)
                              .map((p) => historyData[p][k] || 0)
                              .reduce(reducers.sum, 0)}
                          </TableCell>
                        ))}
                      <TableCell
                        style={{
                          borderLeft: `1.5px solid ${theme.palette.text.primary}`,
                        }}
                      >
                        {Object.keys(historyData[Object.keys(historyData)[0]])
                          .map((k) =>
                            Object.keys(historyData)
                              .map((p) => historyData[p][k] || 0)
                              .reduce(reducers.sum, 0)
                          )
                          .reduce(reducers.sum, 0)}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
            {viewMode === 'plot' && (
              <Plot
                data={Object.keys(historyData).map((pharma) => ({
                  x: Object.keys(historyData[pharma]).sort(),
                  y: Object.keys(historyData[pharma])
                    .sort()
                    .map((k) => historyData[pharma][k]),
                  text: Object.keys(historyData[pharma])
                    .sort()
                    .map((k) => historyData[pharma][k].toString()),
                  textposition: 'top right',
                  type: 'scatter',
                  mode: 'text+markers',
                  name: places.filter((p) => p.id?.toString() === pharma.toString())[0]?.name || pharma.toString(),
                }))}
                layout={{
                  width: 900,
                  height: 600,
                  legend: {
                    orientation: 'h',
                  },
                }}
              />
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => setViewMode(viewMode === 'table' ? 'plot' : 'table')}>
          {viewMode === 'table' ? 'Grafické zobrazení' : 'Tabulka'}
        </Button>
        <Button color="primary" onClick={onClose}>
          Zavřít
        </Button>
      </DialogActions>
    </Dialog>
  );
}
