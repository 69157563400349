import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Message } from '@mui/icons-material';
import { ToOrderItem } from '../../Types/order';
import { ordersService } from '../../Utils/ApiService';
import { useLayout } from '../../Layout/LayoutContext';
import { DatePicker } from '@mui/x-date-pickers';

interface IProps {
  item: ToOrderItem;
  onSave: (newItem: ToOrderItem) => void;
}

export default function ToOrderItemRespond({ item, onSave }: IProps) {
  const [opened, setOpened] = useState(false);
  const [response, setResponse] = useState('');

  const layout = useLayout();

  const save = () =>
    ordersService.post(
      '/internal/toOrderItem',
      {},
      { ...item, response: response },
      {
        success: (data) => {
          onSave(data);
          setOpened(false);
          setResponse('');
        },
        error: () => layout.error('Při ukládání došlo k chybě'),
      }
    );

  return (
    <>
      <IconButton onClick={() => setOpened(true)} size="large">
        <Message color="primary" />
      </IconButton>
      <Dialog open={opened} onClose={() => setOpened(false)} fullWidth>
        <DialogTitle>Upravit odpověď na požadavek k objednávce</DialogTitle>
        <DialogContent>
          <TextField
            variant="standard"
            label="Odpověď"
            fullWidth
            value={response}
            onChange={(e) => setResponse(e.target.value)}
          />
          <br />
          <br />
          <Typography variant="h6">Předdefinované odpovědi</Typography>
          <Button color="primary" onClick={() => setResponse('objednáno')}>
            objednáno
          </Button>
          <Button color="primary" onClick={() => setResponse('obratem objednám')}>
            obratem objednám
          </Button>
          <Button color="primary" onClick={() => setResponse('krátká EXP na distribuci')}>
            krátká EXP na distribuci
          </Button>
          <Button color="primary" onClick={() => setResponse('výpadek')}>
            výpadek
          </Button>
          <br />
          <DatePicker
            format="yyyy-MM-dd"
            label="Objednám..."
            value={null}
            onChange={(date: Date | null) => setResponse(`objednám ${date?.toLocaleDateString()}`)}
            slots={{ textField: TextField }}
            slotProps={{ textField: { fullWidth: true } }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              setOpened(false);
              setResponse('');
            }}
          >
            Cancel
          </Button>
          <Button color="primary" onClick={save}>
            Uložit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
