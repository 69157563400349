import React from 'react';
import { Autocomplete, Button, Checkbox, FormControlLabel, Grid, TextField, Theme, Typography } from '@mui/material';
import { IProduct, ISelectsConfig } from '@magistrmartin/eshop-frontend-shared';
import { openFileContext, copyToClipboard } from '../../Utils/Utils';
import { imagesService } from '../../Utils/ApiService';
import { useLayout } from '../../Layout/LayoutContext';
import Compressor from 'compressorjs';
import CategoriesSelect from '../../Components/CategoriesSelect';
import SubstancesSelect from '../../Components/SubstancesSelect';
import ProductImagesSettings from '../Modals/ProductImagesSettings';
import { getKeyExtractorComparer } from '../../Utils/Comparers';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemRoot: {
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    productImage: {
      width: '80%',
      marginLeft: '10%',
    },
  })
);

interface IProps {
  product: IProduct;
  changeProduct: (attr: keyof IProduct, val: any) => void;
  selectsConfig?: ISelectsConfig;
  setProduct: (product: IProduct) => void;
}

export default function InfoEditor({ changeProduct, product, selectsConfig, setProduct }: IProps) {
  const layout = useLayout();

  const saveFiles = (files?: File[]) => {
    if (files !== undefined && files.length > 0) {
      if (files[0].size >= 1048576) {
        new Compressor(files[0], {
          quality: 0.8,
          convertSize: 1048576,
          success: (result) => {
            layout.warning('Obrázek byl automaticky komprimován, může dojít ke ztrátě kvality.');
            saveFiles([new File([result], files[0].name + '.jpg')]);
          },
          error: (err) => layout.error('Při kompresi došlo k chybě: ' + err.message),
        });
      } else {
        imagesService.uploadFile('uploadAndAdjust', files[0], { location: 'images' }, null, {
          success: (file) =>
            changeProduct('images', [`https://api.magistrmartin.cz/images/noauth/image/images/${file}`]),
          error: layout.error,
        });
      }
    }
  };

  const classes = useStyles();

  return (
    <>
      <div
        onDrop={(e) => {
          e.preventDefault();
          // @ts-ignore
          saveFiles(e.dataTransfer?.files);
        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
      >
        <img
          className={classes.productImage}
          src={
            product.images === undefined || product.images.filter((i) => i.length > 0).length === 0
              ? '/img/noImage.png'
              : product.images[0]
          }
          alt="product preview"
        />
      </div>
      {product.images && product.images.filter((i) => i.length > 0).length > 0 && (
        <>
          <Typography variant="caption">{product.images[0].split('/').reverse()[0]}</Typography>
          <br />
        </>
      )}
      <Button color="primary" onClick={() => openFileContext(saveFiles)}>
        Změnit obrázek
      </Button>
      <Button
        color="secondary"
        onClick={() =>
          layout.deleteConfirm('Smazat obrázek', 'Opravdu chcete tento obrázek smazán?', () =>
            changeProduct('images', [])
          )
        }
      >
        Smazat obrázek
      </Button>
      <ProductImagesSettings
        images={product.images || []}
        setImages={(newImages: string[]) => changeProduct('images', newImages)}
      />
      <Button
        color="primary"
        onClick={() => {
          copyToClipboard(product.title + ' ' + product.subtitle);
          layout.info('Text byl zkopírován to clipboardu.');
        }}
      >
        Kopírovat název
      </Button>
      <br />
      <TextField
        variant="standard"
        fullWidth
        label="Hlavní název"
        value={product.title || ''}
        onChange={(e) => changeProduct('title', e.target.value)}
      />
      <TextField
        variant="standard"
        fullWidth
        label="Vedlejší název"
        value={product.subtitle || ''}
        onChange={(e) => changeProduct('subtitle', e.target.value)}
      />
      <TextField
        variant="standard"
        fullWidth
        label="Pořadí v excelu"
        value={product.excelOrder || ''}
        type="number"
        onChange={(e) => changeProduct('excelOrder', e.target.value)}
      />
      {selectsConfig && (
        <Autocomplete
          value={{
            id: product.characteristics || '',
            label:
              selectsConfig?.characteristics?.find((c) => c.id?.toString() === product.characteristics)?.text || '',
          }}
          onChange={(e, val) =>
            val &&
            setProduct({
              ...product,
              characteristics: val.id?.toString(),
            })
          }
          options={selectsConfig?.characteristics
            ?.sort(getKeyExtractorComparer('text'))
            ?.map((c) => ({ id: c.id?.toString(), label: c.text }))}
          fullWidth
          isOptionEqualToValue={(o, v) => o.id === v.id}
          renderInput={(p) => <TextField label="Charakteristika" {...p} />}
        />
      )}
      <Grid container>
        <Grid item className={classes.itemRoot} xs={8}>
          {selectsConfig && (
            <Autocomplete
              value={{
                id: product?.form || '',
                label:
                  selectsConfig?.units
                    ?.filter((c) => c.id?.toString() === product?.form)
                    .map((c) => `${c.first} (${c.shortForm})`)[0] || '',
              }}
              onChange={(e, val) => val && changeProduct('form', val.id)}
              options={selectsConfig?.units?.sort(getKeyExtractorComparer('first'))?.map((c) => ({
                id: c.id?.toString(),
                label: `${c.first} (${c.shortForm})`,
              }))}
              fullWidth
              isOptionEqualToValue={(o, v) => o.id === v.id}
              renderInput={(p) => <TextField label="Léková forma" {...p} />}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          <TextField
            variant="standard"
            fullWidth
            type="number"
            label="Počet jednotek"
            value={product.ammount || ''}
            onChange={(e) => changeProduct('ammount', parseFloat(e.target.value))}
          />
        </Grid>
      </Grid>
      <br />
      <CategoriesSelect
        categories={product.categories?.filter((cat) => cat.length > 0) || []}
        onChange={(newCats) => changeProduct('categories', newCats)}
      />
      <br />
      <br />
      <SubstancesSelect
        productId={product.id}
        substances={product.substances || []}
        onChange={(newSubs) => changeProduct('substances', newSubs)}
      />
      <br />
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={<Checkbox color="primary" checked={product.isNatural} />}
            label="Přírodní"
            onClick={() => changeProduct('isNatural', !product.isNatural)}
          />
          <br />
          <FormControlLabel
            control={<Checkbox color="primary" checked={product.isBio} />}
            label="BIO"
            onClick={() => changeProduct('isBio', !product.isBio)}
          />
          <br />
          <FormControlLabel
            control={<Checkbox color="primary" checked={product.isVegan} />}
            label="Vegan"
            onClick={() => changeProduct('isVegan', !product.isVegan)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={<Checkbox color="primary" checked={product.noLactose} />}
            label="Bez laktózy"
            onClick={() => changeProduct('noLactose', !product.noLactose)}
          />
          <br />
          <FormControlLabel
            control={<Checkbox color="primary" checked={product.whilePregnancy} />}
            label="V těhotenství"
            onClick={() => changeProduct('whilePregnancy', !product.whilePregnancy)}
          />
          <br />
          <FormControlLabel
            control={<Checkbox color="primary" checked={product.whileBreastFeeding} />}
            label="Při kojení"
            onClick={() => changeProduct('whileBreastFeeding', !product.whileBreastFeeding)}
          />
        </Grid>
      </Grid>
    </>
  );
}
