import React from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import { IBonificationImportRow, importBonificationsFromExcel } from '../Utils/BonificcationIE';
import { Typography } from '@mui/material';
import { useLayout } from '../Layout/LayoutContext';
import { catalogService } from '../Utils/ApiService';
import { IProductToAdd } from '../Types/base';

interface IProps {
  onImport?: (data: IProductToAdd[]) => void;
}

export default function Bonifications({ onImport }: IProps) {
  const layout = useLayout();

  const importData = (data: IBonificationImportRow[]) => {
    layout.setIsLoading(true);
    catalogService.post('/products/importBonifications', {}, data, {
      success: onImport || (() => {}),
      error: () => layout.error('Import bonifikací se nezdařil'),
      finally: () => layout.setIsLoading(false),
    });
  };

  return (
    <>
      <Typography variant="h4">Import bonifikací</Typography>
      <br />
      <DropzoneArea
        onChange={(files: File[]) =>
          files && files.length && importBonificationsFromExcel(files[0]).then(({ rows }) => importData(rows))
        }
      />
    </>
  );
}
