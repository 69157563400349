import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React, { useState } from 'react';

interface IProps {
  opened: boolean;
  close: () => void;
  save: (value: string) => void;
  message: string;
  title: string;
  label: string;
}

export default function SimpleTextFormModal({ close, save, message, opened, title, label }: IProps) {
  const [val, setVal] = useState('');

  const reset = () => {
    setVal('');
    close();
  };

  return (
    <Dialog open={opened} onClose={reset}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        <TextField variant="standard" fullWidth label={label} onChange={(e) => setVal(e.target.value)} value={val} />
      </DialogContent>
      <DialogActions>
        <Button onClick={reset}>Cancel</Button>
        <Button
          onClick={() => {
            save(val);
            reset();
          }}
          color="primary"
        >
          Uložit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
