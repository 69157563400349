import React, { useEffect, useState } from 'react';
import { Checkbox, Grid, Tab, Tabs, Theme, Typography } from '@mui/material';
import { useLayout } from '../../Layout/LayoutContext';
import { catalogService, imagesService } from '../../Utils/ApiService';
import { IProductStamp } from '../EditProduct/EditProduct';
import LoadingFab from '../../Components/LoadingFab';
import Leaflets from './Leaflets';
import Banners from './Banners';
import DailySpecials from './DailySpecials';
import ActionSeries from './ActionSeries';
import { createStyles, makeStyles } from '@mui/styles';
import { domains } from '../../Utils/Mutations';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: `calc(100% - ${theme.spacing(3)})`,
    },
    banner: {
      width: '80%',
      marginLeft: '10%',
    },
    saveBtn: {
      position: 'fixed',
      right: theme.spacing(2),
      bottom: theme.spacing(2),
      zIndex: 999,
    },
    link: {
      color: theme.palette.text.primary,
    },
  })
);

type BannerType = { file: string; name?: string; link?: string; validFrom?: Date; validTo?: Date };

export type HomeConfigType = {
  banners?: BannerType[];
  miniBanners?: BannerType[];
  leaflets?: { file: string; validFrom?: Date; validTo?: Date }[];
  shippingPaymentLink: string;
  productSeries: {
    products: number[];
    validFrom?: Date | string;
    validTo?: Date | string;
    name: string;
  }[];
  todaysSpecial: {
    productId: number;
    validAt: Date | string;
    specialPrice?: number;
  }[];
  disableCart?: boolean;
};

export default function HomeConfig() {
  const classes = useStyles();
  const [config, setConfig] = useState<HomeConfigType | undefined>(undefined);
  const [domainTab, setDomainTab] = useState(0);
  const [tab, setTab] = useState(0);
  const [allProducts, setAllProducts] = useState<IProductStamp[]>([]);
  const [loading, setLoading] = useState(false);

  const layout = useLayout();

  useEffect(() => {
    catalogService.get(
      'products/all',
      { fields: 'id,title,subtitle' },
      {
        success: setAllProducts,
        error: console.log,
      }
    );
  }, []);

  useEffect(() => {
    setConfig(undefined);
    imagesService.get(
      '/text',
      { file: `${domains[domainTab].replace(/[.]/g, '_')}_homeSite.json`, location: 'config' },
      {
        success: setConfig,
        error: () => layout.error('Při načítání dat došlo k chyně'),
      }
    );
    // eslint-disable-next-line
  }, [domainTab]);

  const getSaveFile = (key: 'banners' | 'miniBanners' | 'leaflets') => (files?: File[]) => {
    if (files !== undefined && files.length > 0) {
      if ((key === 'banners' || key === 'miniBanners') && files[0].size >= 1048576) {
        new Compressor(files[0], {
          quality: 0.8,
          convertSize: 1048576,
          success: (result) => {
            layout.warning('Obrázek byl automaticky komprimován, může dojít ke ztrátě kvality.');
            getSaveFile(key)([new File([result], files[0].name + '.jpg')]);
          },
          error: (err) => layout.error('Při kompresi došlo k chybě: ' + err.message),
        });
      } else {
        const location = key === 'banners' || key === 'miniBanners' ? 'images' : 'leaflets';
        imagesService.uploadFile(
          key === 'banners' || key === 'miniBanners' ? 'uploadImage' : 'uploadBinaryFile',
          files[0],
          { location: location },
          null,
          {
            success: (file: string) => {
              const newConfig = (config && { ...config }) || {
                banners: [],
                leaflets: [],
                productSeries: [],
                shippingPaymentLink: '',
                todaysSpecial: [],
              };
              // @ts-ignore
              newConfig[key] = [
                // @ts-ignore
                ...newConfig[key],
                {
                  file: `https://api.magistrmartin.cz/images/noauth/image/${location}/${
                    key === 'banners' || key === 'miniBanners' ? JSON.parse(file).location : file
                  }`,
                },
              ];
              setConfig(newConfig);
            },
            error: layout.error,
          }
        );
      }
    }
  };

  const saveChanges = () => {
    setLoading(true);
    imagesService.post(
      'uploadText',
      { file: `${domains[domainTab].replace(/[.]/g, '_')}_homeSite.json`, location: 'config' },
      config,
      {
        success: (c) => {
          layout.success('Změny byly uloženy');
          setLoading(false);
        },
        error: () => layout.error('Při ukládání došlo k chybě'),
      }
    );
  };

  return (
    (config && (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={10}>
            <Tabs color="primary" value={domainTab} onChange={(_, val) => setDomainTab(val)}>
              {domains.map((d) => (
                <Tab key={d} label={d} />
              ))}
            </Tabs>
          </Grid>
          <Grid item xs={12} md={2}>
            <Checkbox
              checked={config?.disableCart || false}
              onClick={() => config && setConfig({ ...config, disableCart: !(config?.disableCart || false) })}
            />
            <Typography variant="caption">Vypnout košík</Typography>
          </Grid>
        </Grid>
        <br />
        <Tabs color="primary" value={tab} onChange={(_, val) => setTab(val)}>
          <Tab label="Akční leták" />
          <Tab label="Úvodní bannery" />
          <Tab label="Hit týdne" />
          <Tab label="Trhák dne" />
          <Tab label="Tématické řady" />
          <Tab label="Mini bannery" />
          <Tab label="Katalogový banner" />
        </Tabs>
        <br />
        <br />
        {tab === 0 ? (
          <Leaflets config={config} getSaveFile={getSaveFile} setConfig={setConfig} />
        ) : tab === 1 ? (
          <Banners
            config={config}
            getSaveFile={getSaveFile}
            setConfig={setConfig}
            label="Úvodní bannery"
            configKey="banners"
          />
        ) : tab === 2 ? (
          <></>
        ) : tab === 3 ? (
          <DailySpecials config={config} allProducts={allProducts} setConfig={setConfig} />
        ) : tab === 4 ? (
          <ActionSeries config={config} allProducts={allProducts} setConfig={setConfig} />
        ) : tab === 5 ? (
          <>
            <Banners
              config={config}
              getSaveFile={getSaveFile}
              setConfig={setConfig}
              label="Mini bannery"
              configKey="miniBanners"
            />
          </>
        ) : (
          <></>
        )}
        <LoadingFab
          color="primary"
          variant="extended"
          className={classes.saveBtn}
          label="Uložit změny"
          loadingLabel="Ukládání"
          loading={loading}
          onClick={saveChanges}
        />
      </div>
    )) || <></>
  );
}
