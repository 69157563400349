import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Delete, Edit, Save } from '@mui/icons-material';
import { catalogService } from '../../Utils/ApiService';
import { useLayout } from '../../Layout/LayoutContext';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import { getKeyExtractorComparer } from '../../Utils/Comparers';

interface IProps {
  productId: number;
  onBestRabatChange: (rabatBase: number, rabatFree: number) => void;
}

export default function EditRabatsModal({ productId, onBestRabatChange }: IProps) {
  const emptyRabat = {
    id: 0,
    productId: productId,
    rabatBase: 0,
    rabatFree: 0,
    rabatExtra: 0,
    lastTimeUsedSale: false,
  };

  const [opened, setOpened] = useState(false);
  const [newRabat, setNewRabat] = useState(emptyRabat);
  const [product, setProduct] = useState<IProduct | undefined>(undefined);

  const layout = useLayout();

  useEffect(() => {
    if (opened && (product === undefined || product.id !== productId)) {
      catalogService.get(
        'products/noauth',
        { id: productId },
        {
          success: (prd: IProduct) => setProduct(prd),
          error: console.log,
        }
      );
    }
  }, [opened, product, productId]);

  const resetState = () => {
    setOpened(false);
    setNewRabat(emptyRabat);
  };

  const addRabat = () =>
    catalogService.post(
      '/products/rabat',
      {},
      { ...newRabat, product: productId },
      {
        success: (r: any) => setProduct(undefined),
        error: () => layout.error('Při vytváření rabatu došlo k chybě'),
      }
    );

  const changeProduct = (attr: keyof IProduct, val: any) => product && setProduct({ ...product, [attr]: val });
  const getDiscount = (r: { rabatBase: number; rabatFree: number; rabatExtra: number }) =>
    Math.round((100 * (r.rabatFree + r.rabatExtra)) / (r.rabatBase + r.rabatFree + r.rabatExtra));

  return (
    <>
      <Tooltip title="Upravit rabaty">
        <IconButton onClick={() => setOpened(true)} size="large">
          <Edit />
        </IconButton>
      </Tooltip>
      <Dialog open={opened} onClose={resetState} fullWidth maxWidth="md">
        <DialogTitle title="Úprava rabatů" />
        <DialogContent>
          {product === undefined ? (
            <Typography>Načítám...</Typography>
          ) : (
            <Grid container spacing={3} alignItems="flex-end">
              {product.rabats?.sort(getKeyExtractorComparer('rabatBase')).map((r) => (
                <React.Fragment key={r.id}>
                  <Grid item xs={1}>
                    <Checkbox
                      color="primary"
                      checked={r.lastTimeUsedSale}
                      onClick={() =>
                        catalogService.post('/products/setLastSaledRabat', { rabatId: r.id }, null, {
                          success: () => {
                            changeProduct(
                              'rabats',
                              product.rabats?.map((rr) => ({ ...rr, lastTimeUsedSale: rr.id === r.id }))
                            );
                            onBestRabatChange(r.rabatBase, r.rabatFree + r.rabatExtra);
                          },
                          error: () => layout.error('Při ukládání došlo k chybě'),
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      variant="standard"
                      value={r.rabatBase}
                      onChange={(e) => {
                        changeProduct(
                          'rabats',
                          product.rabats?.map((rr) =>
                            rr.id === r.id ? { ...r, rabatBase: parseInt(e.target.value) || 0 } : rr
                          )
                        );
                        if (r.lastTimeUsedSale) onBestRabatChange(r.rabatBase, r.rabatFree + r.rabatExtra);
                      }}
                      label="základ"
                      fullWidth
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      variant="standard"
                      value={r.rabatFree}
                      onChange={(e) => {
                        changeProduct(
                          'rabats',
                          product.rabats?.map((rr) =>
                            rr.id === r.id ? { ...r, rabatFree: parseInt(e.target.value) || 0 } : rr
                          )
                        );
                        if (r.lastTimeUsedSale) onBestRabatChange(r.rabatBase, r.rabatFree + r.rabatExtra);
                      }}
                      label="zdarma"
                      fullWidth
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      variant="standard"
                      value={r.rabatExtra}
                      onChange={(e) => {
                        changeProduct(
                          'rabats',
                          product.rabats?.map((rr) =>
                            rr.id === r.id ? { ...r, rabatExtra: parseInt(e.target.value) || 0 } : rr
                          )
                        );
                        if (r.lastTimeUsedSale) onBestRabatChange(r.rabatBase, r.rabatFree + r.rabatExtra);
                      }}
                      label="extra"
                      fullWidth
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Typography>{getDiscount(r)} %</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Tooltip title="Uložit změny v aktuálním rabatu">
                      <IconButton
                        onClick={() =>
                          catalogService.post('/products/rabat', {}, r, {
                            success: () => layout.success('Rabat byl úspěšně uložen'),
                            error: () => layout.error('Při ukládání rabatu došlo k chybě'),
                          })
                        }
                        size="large"
                      >
                        <Save color="primary" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Smazat aktuální rabat">
                      <IconButton
                        onClick={() =>
                          layout.deleteConfirm('Opravdu chcete smazat tento rabat?', 'Rabat bude trvale smazán', () =>
                            catalogService.delete('/products/rabat', { rabatId: r.id }, null, {
                              success: () => {
                                changeProduct(
                                  'rabats',
                                  product.rabats?.filter((rr) => rr.id !== r.id)
                                );
                                if (r.lastTimeUsedSale) onBestRabatChange(0, 0);
                              },
                              error: () => layout.error('Při mazání rabatu došlo k chybě'),
                            })
                          )
                        }
                        size="large"
                      >
                        <Delete color="error" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </React.Fragment>
              ))}
              <Grid item xs={12}>
                <Typography variant="caption">Nový rabat</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  variant="standard"
                  value={newRabat.rabatBase}
                  onChange={(e) => setNewRabat({ ...newRabat, rabatBase: parseInt(e.target.value) || 0 })}
                  label="Rabat základ"
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  variant="standard"
                  value={newRabat.rabatFree}
                  onChange={(e) => setNewRabat({ ...newRabat, rabatFree: parseInt(e.target.value) || 0 })}
                  label="Rabat zdarma"
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  variant="standard"
                  value={newRabat.rabatExtra}
                  onChange={(e) => setNewRabat({ ...newRabat, rabatExtra: parseInt(e.target.value) || 0 })}
                  label="Rabat extra"
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={newRabat.rabatBase <= 0 || newRabat.rabatFree + newRabat.rabatExtra <= 0}
                  onClick={addRabat}
                  color="primary"
                >
                  Přidat rabat
                </Button>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={resetState}>
            Zavřít
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
