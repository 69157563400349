import { Button, ButtonGroup, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

interface IProps {
  value?: string;
  data: { [index: string]: string };
  onChange: (val: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deselected: {
      color: 'lightGrey',
    },
  })
);

export default function SwitchButton({ data, onChange, value }: IProps) {
  if (!Object.keys(data).includes(value || '')) value = undefined;
  const classes = useStyles();

  return (
    <ButtonGroup variant="text">
      {Object.keys(data).map((k) => (
        <Button
          key={k}
          className={k === value ? '' : classes.deselected}
          onClick={() => {
            if (k !== value) onChange(k);
          }}
        >
          {data[k]}
        </Button>
      ))}
    </ButtonGroup>
  );
}
