import { AppBar, Button, Typography } from '@mui/material';
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    bar: {
      padding: '16px',
      display: 'flex',
      flexDirection: 'row',
    },
    flexGrow: {
      flexGrow: 1,
    },
    loginButton: {
      color: 'white',
    },
  })
);

export default function Header() {
  const classes = useStyles();
  const { loginWithRedirect, isAuthenticated, logout } = useAuth0();

  return (
    <AppBar className={classes.bar}>
      <Typography className={classes.flexGrow} variant="h4">
        Administrace | Online lékárna Magistr Martin
      </Typography>
      {(isAuthenticated && (
        <Button className={classes.loginButton} onClick={() => logout()}>
          Odhlásit se
        </Button>
      )) || (
        <Button className={classes.loginButton} onClick={() => loginWithRedirect({ ui_locales: 'cs' })}>
          Přihlásit se
        </Button>
      )}
    </AppBar>
  );
}
