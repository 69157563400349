import React, { useState } from 'react';
import { Typography, Grid, IconButton } from '@mui/material';
import { useEffect } from 'react';
import { useLayout } from '../Layout/LayoutContext';
import { catalogService } from '../Utils/ApiService';
import { Delete } from '@mui/icons-material';

interface INewProductTip {
  id: number;
  product: string;
}

export default function Tips() {
  const [newProductsTips, setNewProductsTips] = useState<INewProductTip[]>();

  const layout = useLayout();

  useEffect(
    () => {
      catalogService.get('/products/newProductsTips', undefined, {
        success: setNewProductsTips,
        error: () => layout.error('Při načítání tipů na nové produkty došlo k chybě'),
      });
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <Typography variant="h4">Tipy na nové produkty</Typography>
      <br />
      <Grid container>
        {newProductsTips?.map((p) => (
          <Grid item xs={2}>
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <IconButton
                  onClick={() =>
                    catalogService.delete('/products/newProductTip', { id: p.id }, null, {
                      success: () => setNewProductsTips(newProductsTips.filter((pp) => pp.id !== p.id)),
                      error: () => layout.error('Při mazání došlo k chybě'),
                    })
                  }
                  aria-label="delete"
                  size="large"
                >
                  <Delete color="error" />
                </IconButton>
              </Grid>
              <Grid item xs={10}>
                <Typography noWrap>{p.product}</Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
